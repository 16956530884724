import { useCallback, useEffect } from "react";
import "./bulkEditModal.css";
import { useIntl } from "react-intl";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  Message,
  Modal,
  ModalContent,
  ModalDescription,
  ModalHeader,
  Radio,
  Select,
} from "semantic-ui-react";
import { Config } from "../../config/api";
import { currencyCodeToSymbol, currencyDecimalPlaces } from "../../libs/common_utils";
import * as campaignFixtures from "../campaign/fixtures";
import * as strategyFixtures from "../strategy/fixtures";

const typeConfig = {
  strategy: {
    frequencyIntervals: strategyFixtures.frequency_intervals,
    frequencyOptions: strategyFixtures.frequency_options,
    dailyPacingOptions: strategyFixtures.daily_pacing_options,
    dailyPacingIntervals: strategyFixtures.daily_pacing_intervals,
    headingId: "HEADING_BULK_EDITING_STRATEGIES",
    itemName: "Strategies",
    buttonTextId: "BTN_UPDATE_STRATEGIES",
  },
  campaign: {
    frequencyIntervals: campaignFixtures.frequency_intervals,
    frequencyOptions: campaignFixtures.frequency_options,
    dailyPacingOptions: undefined,
    dailyPacingIntervals: undefined,
    headingId: "HEADING_BULK_EDITING_CAMPAIGNS",
    itemName: "Campaigns",
    buttonTextId: "BTN_UPDATE_CAMPAIGNS",
  },
};

export const BulkEditModalComponent = ({
  isBulkEditModalOpen,
  setIsBulkEditModalOpen,
  type,
  serverError,
  formActions,
  formStates,
  fields,
  selectedPGCampaigns = [],
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { onChange, onSwitch, onSubmit, handleCheckboxChange, handleOnClose, updateValues } = formActions;
  const { values, errors, formSending, selectedItems, checkedFields } = formStates;
  const title = formatMessage(
    {
      id: typeConfig[type].headingId,
      defaultMessage: `Edit Details for ${selectedItems.length} ${typeConfig[type].itemName}`,
    },
    {
      count: selectedItems.length,
    },
  );

  const submitButtonText = formatMessage({
    id: typeConfig[type].buttonTextId,
    defaultMessage: `Update ${typeConfig[type].itemName}`,
  });

  const disableField = useCallback((field) => !checkedFields.includes(field), [checkedFields]);

  const handleDefaultFrequencyCapBasedOnOptimization = useCallback(() => {
    const newValues =
      values.frequencyOptimization === 0
        ? { frequencyType: "asap", frequencyAmount: null, frequencyInterval: "hour" }
        : { frequencyType: null, frequencyAmount: null, frequencyInterval: null };

    updateValues(newValues);
  }, [values.frequencyOptimization, updateValues]);

  useEffect(() => {
    updateValues({ ids: selectedItems });
  }, [selectedItems, updateValues]);

  useEffect(() => {
    handleDefaultFrequencyCapBasedOnOptimization();
  }, [handleDefaultFrequencyCapBasedOnOptimization]);

  return (
    <Modal
      closeIcon
      onClose={handleOnClose}
      onOpen={() => setIsBulkEditModalOpen(true)}
      open={isBulkEditModalOpen}
      size="large"
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalContent>
        <ModalDescription>
          <Message
            content={serverError}
            error
            hidden={!serverError}
            size="tiny"
          />
          {type === "campaign" && (
            <Message
              warning
              hidden={selectedPGCampaigns.length === 0}
              content={
                <div>
                  {intl.formatMessage({
                    id: "TOOLTIP_PG_CAMPAIGN_CANNOT_EDIT_FREQUENCY_CAP",
                    defaultMessage:
                      "The following Programmatic Guaranteed Campaign(s) has been selected. Frequency Cap cannot be edited for Programmatic Guaranteed Campaigns.",
                  })}
                  <ul>
                    {selectedPGCampaigns.map((campaign) => (
                      <li key={campaign.id}>{campaign.name}</li>
                    ))}
                  </ul>
                </div>
              }
            />
          )}
          <Form
            error={Boolean(Object.keys(errors).length)}
            loading={formSending}
            method="PUT"
            onSubmit={onSubmit}
          >
            {fields.includes("status") && (
              <StatusFormField
                disableField={disableField}
                formatMessage={formatMessage}
                handleCheckboxChange={handleCheckboxChange}
                onSwitch={onSwitch}
                values={values}
              />
            )}

            {fields.includes("bid") && (
              <BidFormField
                disableField={disableField}
                errors={errors}
                formatMessage={formatMessage}
                handleCheckboxChange={handleCheckboxChange}
                onChange={onChange}
                values={values}
              />
            )}

            {fields.includes("dailyPacing") && (
              <DailyPacingFormField
                disableField={disableField}
                errors={errors}
                formatMessage={formatMessage}
                handleCheckboxChange={handleCheckboxChange}
                onChange={onChange}
                type={type}
                values={values}
              />
            )}

            {fields.includes("frequencyCap") && (
              <FrequencyCapFormField
                disableField={disableField}
                errors={errors}
                formatMessage={formatMessage}
                handleCheckboxChange={handleCheckboxChange}
                onChange={onChange}
                onSwitch={onSwitch}
                type={type}
                values={values}
                selectedPGCampaigns={selectedPGCampaigns}
              />
            )}

            <Form.Field align="right">
              <Button
                onClick={handleOnClose}
                size="tiny"
                type="button"
              >
                {formatMessage({
                  id: "BTN_CANCEL",
                  defaultMessage: "Cancel",
                })}
              </Button>
              <Button
                color="green"
                disabled={checkedFields.length <= 0}
                size="tiny"
                type="submit"
              >
                {submitButtonText}
              </Button>
            </Form.Field>
          </Form>
        </ModalDescription>
      </ModalContent>
    </Modal>
  );
};

export const StatusFormField = ({ disableField, handleCheckboxChange, values, onSwitch, formatMessage }) => {
  return (
    <Form.Field
      className="bulk-edit-form-field-row"
      inline
    >
      <Grid>
        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Checkbox
            checked={!disableField("status")}
            label={formatMessage({
              id: "LABEL_STATUS",
              defaultMessage: "Status",
            })}
            name="status"
            onChange={handleCheckboxChange}
          />
        </Grid.Row>
        <Grid.Row style={{ paddingLeft: "24px", paddingBottom: "0px" }}>
          <Radio
            checked={values.status === 1}
            disabled={disableField("status")}
            label={formatMessage({
              id: "STATUS_ACTIVE",
              defaultMessage: "Active",
            })}
            name="status"
            onChange={onSwitch}
            value={1}
          />
          <Radio
            checked={values.status === 0}
            disabled={disableField("status")}
            label={formatMessage({
              id: "STATUS_INACTIVE",
              defaultMessage: "Inactive",
            })}
            name="status"
            onChange={onSwitch}
            style={{ marginLeft: "15px" }}
            value={0}
          />
        </Grid.Row>
      </Grid>
    </Form.Field>
  );
};

export const BidFormField = ({ errors, disableField, formatMessage, handleCheckboxChange, onChange, values }) => {
  const currency = Config.defaultCurrency;

  return (
    <Form.Field
      className="bulk-edit-form-field-row"
      error={Object.hasOwn(errors, "maxBid") || Object.hasOwn(errors, "minBid")}
      inline
    >
      <Grid>
        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Checkbox
            checked={!disableField("bid")}
            label={formatMessage({
              id: "LABEL_BID",
              defaultMessage: "Bid",
            })}
            name="bid"
            onChange={handleCheckboxChange}
          />
        </Grid.Row>
        <Grid.Row style={{ paddingLeft: "24px", paddingBottom: "0px", alignItems: "center" }}>
          <span style={{ opacity: disableField("bid") ? 0.5 : 1 }}>
            {formatMessage({
              id: "LABEL_MIN_BID_CPM",
              defaultMessage: "Min Bid CPM",
            })}
          </span>
          <Input
            disabled={disableField("bid")}
            label={currencyCodeToSymbol(currency)}
            min={0}
            name="minBid"
            onChange={onChange}
            places={2}
            required
            step={currencyDecimalPlaces(currency)}
            type="number"
            value={values.minBid || ""}
          />
          <span style={{ opacity: disableField("bid") ? 0.5 : 1 }}>
            {formatMessage({
              id: "LABEL_Max_BID_CPM",
              defaultMessage: "Max Bid CPM",
            })}
          </span>
          <Input
            disabled={disableField("bid")}
            label={currencyCodeToSymbol(currency)}
            min={0}
            name="maxBid"
            onChange={onChange}
            places={2}
            required
            step={currencyDecimalPlaces(currency)}
            type="number"
            value={values.maxBid || ""}
          />
        </Grid.Row>
        <Grid.Row className="edit-field-custom-error">
          {errors.minBid || errors.maxBid || errors.bid_aggressiveness}
        </Grid.Row>
      </Grid>
    </Form.Field>
  );
};

export const DailyPacingFormField = ({
  errors,
  disableField,
  formatMessage,
  handleCheckboxChange,
  onChange,
  type,
  values,
}) => {
  const intl = useIntl();
  const dailyPacingOptions = typeConfig[type].dailyPacingOptions;
  const dailyPacingIntervals = typeConfig[type].dailyPacingIntervals;
  return (
    <Form.Field
      className="bulk-edit-form-field-row"
      error={Object.hasOwn(errors, "pacingAmount")}
      inline
    >
      <Grid>
        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Checkbox
            checked={!disableField("dailyPacing")}
            label={formatMessage({
              id: "LABEL_DAILY_PACING",
              defaultMessage: "Daily Pacing",
            })}
            name="dailyPacing"
            onChange={handleCheckboxChange}
          />
        </Grid.Row>
        <Grid.Row style={{ paddingLeft: "24px", paddingBottom: "0px" }}>
          <Select
            disabled={disableField("dailyPacing")}
            name="pacingType"
            onChange={onChange}
            options={dailyPacingOptions(intl)}
            placeholder={formatMessage({
              id: "HINT_EXPANSION_TRIGGER",
              defaultMessage: "Select one",
            })}
            value={values.pacingType || ""}
          />
          <Input
            disabled={disableField("dailyPacing")}
            label="$"
            min={0}
            name="pacingAmount"
            onChange={onChange}
            places={2}
            required
            step={0.01}
            type="number"
            value={values.pacingAmount || ""}
          />
          <Select
            disabled={disableField("dailyPacing")}
            name="pacingInterval"
            onChange={onChange}
            options={dailyPacingIntervals(intl)}
            placeholder={formatMessage({
              id: "HINT_EXPANSION_TRIGGER",
              defaultMessage: "Select one",
            })}
            value={values.pacingInterval || ""}
          />
        </Grid.Row>
        <Grid.Row className="edit-field-custom-error">{errors.pacingAmount}</Grid.Row>
      </Grid>
    </Form.Field>
  );
};

export const FrequencyCapFormField = ({
  errors,
  disableField,
  formatMessage,
  handleCheckboxChange,
  onChange,
  onSwitch,
  type,
  values,
  selectedPGCampaigns,
}) => {
  const intl = useIntl();
  const frequencyIntervals = typeConfig[type].frequencyIntervals;
  const frequencyOptions = typeConfig[type].frequencyOptions;

  return (
    <Form.Field
      className="bulk-edit-form-field-row"
      error={Object.hasOwn(errors, "frequencyAmount")}
      inline
      disabled={selectedPGCampaigns.length > 0}
    >
      <Grid>
        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Checkbox
            checked={!disableField("frequencyCap")}
            label={formatMessage({
              id: "LABEL_FREQUENCY_CAP",
              defaultMessage: "Frequency Cap",
            })}
            name="frequencyCap"
            onChange={handleCheckboxChange}
          />
        </Grid.Row>
        <Grid.Row style={{ paddingLeft: "24px", paddingBottom: "0px" }}>
          <Radio
            checked={values.frequencyOptimization === 1}
            disabled={disableField("frequencyCap")}
            label={formatMessage({
              id: "FREQUENCY_CAP_OPTIMIZED",
              defaultMessage: "Optimized",
            })}
            name="frequencyOptimization"
            onChange={onSwitch}
            value={1}
          />
          <Radio
            checked={values.frequencyOptimization === 0}
            disabled={disableField("frequencyCap")}
            label={formatMessage({
              id: "FREQUENCY_CAP_CUSTOM",
              defaultMessage: "Custom",
            })}
            name="frequencyOptimization"
            onChange={onSwitch}
            style={{ marginLeft: "15px", marginRight: "15px" }}
            value={0}
          />
          {values.frequencyOptimization === 0 && (
            <>
              <Select
                disabled={disableField("frequencyCap")}
                name="frequencyType"
                onChange={onChange}
                options={frequencyOptions(intl)}
                placeholder={formatMessage({
                  id: "HINT_EXPANSION_TRIGGER",
                  defaultMessage: "Select one",
                })}
                value={values.frequencyType || ""}
              />
              <Input
                disabled={disableField("frequencyCap") || values.frequencyType === "no-limit"}
                label="#"
                min={0}
                name="frequencyAmount"
                onChange={onChange}
                places={2}
                required
                step={1}
                type="number"
                value={values.frequencyAmount || ""}
              />
              <Select
                disabled={disableField("frequencyCap") || values.frequencyType === "no-limit"}
                name="frequencyInterval"
                onChange={onChange}
                options={frequencyIntervals(intl)}
                placeholder={formatMessage({
                  id: "HINT_EXPANSION_TRIGGER",
                  defaultMessage: "Select one",
                })}
                value={values.frequencyInterval || ""}
              />
            </>
          )}
        </Grid.Row>
        <Grid.Row className="edit-field-custom-error">{errors.frequencyAmount}</Grid.Row>
      </Grid>
    </Form.Field>
  );
};
