import AbstractService from "../abstract.service";

export default class PasswordsService extends AbstractService {
  static URI_STATES = new Map([
    ["passwords.create", "/forgotten-password"],
    ["passwords.validate", "/reset-password/{0}"],
    ["passwords.reset", "/reset-password"],
  ]);

  /**
   * Initializes password reset.
   * @param {object} data - values of the form
   * @param {string} data.email
   * @returns {object}
   */
  async create(data) {
    return await this.api_server.post(PasswordsService.URI_STATES.get("passwords.create"), data);
  }

  /**
   * Checks if password reset token is still valid and returns
   * its associated email.
   * @param {string} token - token from password reset email
   * @returns {object}
   */
  async validate(token) {
    return await this.api_server.get(PasswordsService.URI_STATES.get("passwords.validate").format(token));
  }

  /**
   * Resets the password.
   * @param {object} data - values of the form
   * @param {string} data.token
   * @param {string} data.password
   * @param {string} data.confirm
   * @returns {object}
   */
  async reset(data) {
    return await this.api_server.post(PasswordsService.URI_STATES.get("passwords.reset"), data);
  }
}
