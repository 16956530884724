export const user_roles = (intl) => ({
  ADMIN: intl.formatMessage({
    defaultMessage: "Admin",
    id: "USER_ROLE_ADMIN",
  }),
  MANAGER: intl.formatMessage({
    defaultMessage: "Manager",
    id: "USER_ROLE_MANAGER",
  }),
  REPORTER: intl.formatMessage({
    defaultMessage: "Reporter",
    id: "USER_ROLE_REPORTER",
  }),
});
