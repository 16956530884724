import DealService from "../../services/inventory/deals";
import SiteListService from "../../services/inventory/siteList";

export const loadSiteLists = (state, dispatch, page) => {
  dispatch({
    type: "SITELIST_LOAD_INDEX_IN_PROGRESS",
    data: { index: "siteList", loading: true },
  });
  const service = new SiteListService();

  state.siteList.pager.setPage(page);
  let params = state.siteList.pager.toJson();
  if (state.siteList.filter) params = Object.assign(params, state.siteList.filter.toRequestJson());

  const request = service.list(params);

  request
    .then((r) => {
      dispatch({ type: "SITELIST_LOAD_INDEX", data: { index: "siteList", ...r } });
      dispatch({
        type: "SITELIST_LOAD_ERROR",
        data: { error: false, errorMessage: null },
      });
    })
    .catch((e) => {
      dispatch({
        type: "SITELIST_LOAD_ERROR",
        data: { error: true, errorMessage: e.message },
      });
    })
    .finally(() => {
      dispatch({
        type: "SITELIST_LOAD_INDEX_IN_PROGRESS",
        data: { index: "siteList", loading: false },
      });
    });
};

export const loadDeals = async (state, dispatch, page, agency) => {
  dispatch({
    type: "DEALS_LOAD_INDEX_IN_PROGRESS",
    data: { index: "deals", loading: true },
  });

  const service = new DealService();
  state.deals.pager.setPage(page);
  let params = state.deals.pager.toJson();
  if (state.deals.filter) params = Object.assign(params, state.deals.filter.toRequestJson());

  try {
    const request = await service.list(agency, params);
    dispatch({ type: "DEALS_LOAD_INDEX", data: { index: "deals", ...request } });
    dispatch({
      type: "DEALS_LOAD_ERROR",
      data: { error: false, errorMessage: null },
    });
  } catch (e) {
    dispatch({
      type: "DEALS_LOAD_ERROR",
      data: { error: true, errorMessage: e.error.message ?? e.message },
    });
  } finally {
    dispatch({
      type: "DEALS_LOAD_INDEX_IN_PROGRESS",
      data: { index: "deals", loading: false },
    });
  }
};
