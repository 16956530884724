import ReportIndexPage from "../components/report/index";
import ReportPage from "../components/report/report/index";

export const Routes = [
  {
    component: ReportIndexPage,
    path: "/reports",
    title: ["Reports"],
  },
  {
    component: ReportPage,
    path: "/report/:report_slug",
    title: ["Report"],
  },
];
