import escapeRegExp from "lodash/escapeRegExp";
import React, { useContext, useState } from "react";
import { Button, Dropdown, Loader, Modal } from "semantic-ui-react";

import { useIntl } from "react-intl";
import { Config } from "../../config/api";
import { OnlineContext } from "../../context/online-context";
import { getReadableClientName } from "../../libs/common_utils";
import { useDebounce } from "../../libs/component_utils";
import AgenciesService from "../../services/agencies";

const AgencyPickerModal = ({ closeIcon, open, onClose, defaultAgencyId = null, defaultAgencyTitle = null }) => {
  const agenciesService = new AgenciesService();

  const { doLogout } = useContext(OnlineContext);

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [agencies, setAgencies] = useState([]);
  const [selectedAgencyId, setSelectedAgencyId] = useState(defaultAgencyId);
  const intl = useIntl();

  const doSelectAgency = (agencyId, agencyTitle) => {
    if (agencyId === defaultAgencyId) {
      return onClose();
    }
    agenciesService.selectAgency(agencyId, agencyTitle);

    // Current page have been loaded with old agency value, so we need to go to the app root.
    // TODO: can this be done without hard reload?
    window.location.assign(Config.basename);
  };

  const initialAgencies = React.useRef();

  const fetchInitialData = async () => {
    const response = await agenciesService.fetchAvailableAgencies();

    if (response.status === "Error") {
      throw new Error(response.error.message);
    }

    // User doesn't have access to any agency at all, prompt to log out
    if (response.data.length === 0) {
      setAgencies(null);
      setInitialLoading(false);
      return;
    }

    // User has access to one agency only and haven't selected an agency yet
    if (!defaultAgencyId && response.data.length === 1) {
      doSelectAgency(response.data[0].id, response.data[0].title);
      return;
    }

    initialAgencies.current = response.data;
    if (defaultAgencyId && !response.data.some(({ id }) => id === defaultAgencyId)) {
      initialAgencies.current = [{ id: defaultAgencyId, title: defaultAgencyTitle }, ...initialAgencies.current];
    }

    setAgencies(initialAgencies.current);
    setInitialLoading(false);
  };

  const performSearch = (query) => {
    (async () => {
      if (!open) return;

      if (query.trim() === "") {
        setAgencies(initialAgencies.current);
        return;
      }

      setLoading(true);
      const response = await agenciesService.fetchAvailableAgencies(query.trim());
      setLoading(false);
      if (response.status === "Error") {
        throw new Error(response.error.message);
      }
      setAgencies(response.data);
      setInitialLoading(false);
      setLoading(false);
    })();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(() => {
    if (!open) return;
    if (initialLoading) {
      fetchInitialData();
    } else {
      performSearch("");
    }
  }, [open, initialLoading]);

  const performSearchDebounced = useDebounce(Config.search_debounce_delay, performSearch);

  const handleSubmit = () => {
    doSelectAgency(selectedAgencyId, agencies.find(({ id }) => id === selectedAgencyId).title);
  };

  const trimmingSearch = (options, query) => {
    const re = new RegExp(escapeRegExp(query.trim()), "i");
    return options.filter((opt) => re.test(opt.text));
  };

  if (initialLoading) {
    return (
      <Modal
        basic
        open={open}
        onClose={onClose}
      >
        <Loader size="large" />
      </Modal>
    );
  }

  if (agencies === null) {
    return (
      <Modal
        closeIcon={closeIcon}
        size="tiny"
        open={open}
        onClose={onClose}
      >
        <Modal.Header>
          {intl.formatMessage({
            id: "HEADING_AGENCY_PICKER",
            defaultMessage: "Select an Agency",
          })}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {intl.formatMessage(
              {
                id: "BODY_NO_AGENCIES",
                defaultMessage:
                  "You do not have access to any agencies. Please contact your {client_name} representative for more information.",
              },
              {
                client_name: getReadableClientName(Config.public_client),
              },
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => doLogout()}
          >
            {intl.formatMessage({
              id: "BTN_LOGOUT",
              defaultMessage: "Log out",
            })}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  return (
    <Modal
      closeIcon={closeIcon}
      size="tiny"
      open={open}
      onClose={onClose}
    >
      <Modal.Header>
        {intl.formatMessage({
          id: "HEADING_AGENCY_PICKER",
          defaultMessage: "Select an Agency",
        })}
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Dropdown
            data-testid="agency-selector"
            placeholder={intl.formatMessage({
              id: "LABEL_AGENCY",
              defaultMessage: "Agency",
            })}
            noResultsMessage={intl.formatMessage({
              id: "EMPTY_SEARCH_RESULTS",
              defaultMessage: "No results found",
            })}
            search={trimmingSearch}
            fluid
            selection
            loading={loading}
            options={agencies.map(({ id, title }) => ({
              key: id,
              text: title,
              value: id,
            }))}
            value={selectedAgencyId}
            onChange={(_event, { value }) => {
              setSelectedAgencyId(value);
            }}
            onSearchChange={(_event, { searchQuery }) => {
              setSelectedAgencyId(null);
              performSearchDebounced(searchQuery);
            }}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          disabled={selectedAgencyId === null}
          onClick={handleSubmit}
          data-testid="select-button"
        >
          {intl.formatMessage({
            id: "BTN_SELECT",
            defaultMessage: "Select",
          })}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AgencyPickerModal;
