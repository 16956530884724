import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { Dropdown, Form, Select, Input } from "semantic-ui-react";
import { useIntl } from "react-intl";

import T1Service from "../../../services/t1";
import {campaign_statuses} from "./models/filter";
import AgenciesService from "../../../services/agencies";
import {Config} from "../../../config/api";
import DateRangeControl from "../../common/dateRangeControl";


const MIN_SEARCH_SYMBOLS = 3;

/**
 * check if we should do a search
 * @param {string} query
 * @param {string} prev_query
 * @return {boolean}
 */
const should_do_search = (query, prev_query) => {
	if(prev_query.length > 0 && query.length < 1) {
		return true;
	}

	return query.length >= MIN_SEARCH_SYMBOLS;
};

/**
 * handle the case when we had query but user clear the field
 * @param query
 * @param prev_query
 * @return {boolean}
 */
const search_query_becomes_empty = (query, prev_query) => {
	return (prev_query.length > 0 && query.length < 1);
};

const FilterControl = ({filter, onChange, advertisers_disabled}) => {
	const services = React.useRef(new Map([["t1", new T1Service()], ["agencies", new AgenciesService()]]));
	const loading = React.useRef(true),
		query = React.useRef(""),
		timer = React.useRef();
	const intl = useIntl();

	const [advertisers, setAdvertisers] = useState([]);

	React.useLayoutEffect(() => {
		if(filter.campaign_name.length > 0) {
			const el = document.getElementById("clear_campaign_search");
			el?.addEventListener("click", clearSearch, false);
		}
	}, [filter.campaign_name]);

	useEffect(() => {
		// load list of advertisers
		const agency = services.current.get("agencies").getSelectedAgency() || 0;
		const service = services.current.get("t1");
		(async () => {
			try {
				const r = await service.advertisers(agency);
				loading.current = false;
				setAdvertisers(r.data.map(i => ({"key": i.id, "text": i.title, "value": i.id})));
			} catch (e) {
				console.log(e);
			}
		})();

		return () => {
			filter.reset();
		};
	}, []);

	/**
	 * update status
	 * @param e
	 * @param option
	 */
	const handleStatus = (e, option) => {
		filter.setStatus(option.value);
		onChange(filter.toJson());
	};

	/**
	 * update advertiser
	 * @param e
	 * @param option
	 */
	const handleAdvertiser = (e, option) => {
		filter.setAdvertiser(parseInt(option.value, 10));
		onChange(filter.toJson());
	};

	/**
	 * do a search
	 */
	const handleSearch = (e, {value: searchQuery}) => {
		let prev_query = query.current || "";
		query.current = searchQuery;

		// clear time any time we hit the method
		if(timer.current) {
			clearTimeout(timer.current);
		}

		if(!should_do_search(searchQuery, prev_query)) {
			return;
		}

		if(search_query_becomes_empty(searchQuery, prev_query)) {
			filter.setCampaignName(searchQuery);
			onChange(filter.toJson());
			document.getElementById("campaign_name_id").value = "";
			return;
		}

		timer.current = setTimeout(async () => {
			filter.setCampaignName(searchQuery);
			onChange(filter.toJson());
		}, Config.search_debounce_delay);
	};

	/**
	 * clear search query
	 */
	const clearSearch = () => {
		handleSearch(null, {"value": ""});
	};

	/**
	 * generate close icon
	 * @returns {{onClick: *, name: string}}
	 */
	const getCloseIcon = () => {
		return {
			"name": "close",
			"link": true,
			"id": "clear_campaign_search"
		}
	};

	return (
		<Form autoComplete="off" noValidate>
			<Form.Group>
				<Form.Field disabled={loading.current}>
					<label>
						{intl.formatMessage({
							id: "LABEL_CAMPAIGN_STATUS",
							defaultMessage: "Campaign Status",
						})}
					</label>
					<Select
						selection
						options={campaign_statuses(intl)}
						value={filter.campaign_status}
						onChange={handleStatus}
						name="campaign_status"
            data-testid="campaign_status_filter"
					/>
				</Form.Field>
				<Form.Field disabled={loading.current}>
					<label>
						{intl.formatMessage({
							id: "LABEL_ADVERTISER",
							defaultMessage: "Advertiser",
						})}
					</label>
					<Dropdown
					  search
						selection
						disabled={advertisers_disabled}
						options={[
							{
								"text": intl.formatMessage({
									id: "VALUE_ALL_ADVERTISERS",
									defaultMessage: "All",
								}),
								"value": 0,
							},
						].concat(advertisers)}
						value={filter.advertiser_id}
						onChange={handleAdvertiser}
						name="advertiser_id"
            data-testid="advertiser_filter"
					/>
				</Form.Field>
				<Form.Field disabled={loading.current} className="field-wider">
					<label>
						{intl.formatMessage({
							id: "LABEL_CAMPAIGN",
							defaultMessage: "Campaign",
						})}
					</label>
					<Input
						defaultValue={filter.campaign_name}
						onChange={handleSearch}
						id="campaign_name_id"
						icon={filter.campaign_name ? getCloseIcon() : false}
						placeholder={intl.formatMessage({
							id: "HINT_SEARCH_CAMPAIGN_NAME",
							defaultMessage: "Search campaign by name",
						})}
						name="campaign_name"
					>
            <input data-testid="campaign_name_filter" />
          </Input>
				</Form.Field>
				<Form.Field disabled={loading.current} className="field-wider">
					<label>
						{intl.formatMessage({
							id: "LABEL_DATE_RANGE",
							defaultMessage: "Date Range",
						})}
					</label>
					<DateRangeControl filter={filter} onChange={onChange} dataTestid="date_range_filter" />
				</Form.Field>
			</Form.Group>
		</Form>
	);
};
FilterControl.propTypes = {
	"filter": PropTypes.object.isRequired,
	"onChange": PropTypes.func.isRequired
};

export default FilterControl;
