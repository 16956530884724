import { useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Divider, Header, Message, Segment } from "semantic-ui-react";
import UsersService from "../../../services/users";
import UserForm from "../form";

/**
 * User create page component
 * @param history
 * @return {*}
 * @constructor
 */
const UserCreatePage = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const _isMounted = useRef(true);
  const backUrl = "/admin/users";
  const intl = useIntl();
  const service = new UsersService();

  /**
   * navigate user back
   */
  const handleCancel = useCallback(() => {
    history.push(backUrl);
  }, [history]);

  /**
   * form submit handler
   * @param {object} values
   * @param {File} file
   */
  const handleSubmit = async (values) => {
    setLoading(true);

    const { agency_ids, advertiser_ids, ...rest } = values;
    const data = {
      ...rest,
      email: values.username,
      entities: [
        {
          entity_ids: agency_ids,
          entity_type: "agencies",
        },
        {
          entity_ids: advertiser_ids,
          entity_type: "advertisers",
        },
      ],
    };

    try {
      await service.create(data);
      history.push(backUrl, { action: "created" });
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if (_isMounted.current) {
        setLoading(false);
      }
    }
  };

  const initialData = {
    advertiser_ids: [],
    agency_ids: [],
    first_name: "",
    last_name: "",
    phone: "",
    role: "REPORTER",
    status: 1,
    title: "",
    username: "",
  };

  return (
    <Segment basic>
      <Header as="h2">
        {intl.formatMessage({
          defaultMessage: "Create User",
          id: "HEADING_CREATE_USER",
        })}
      </Header>
      <Divider />
      <Message
        content={serverError}
        error
        hidden={!serverError}
        size="tiny"
        style={{ marginTop: "10px" }}
      />
      <UserForm
        initialData={initialData}
        initialIndeterminateAgencies={[]}
        loading={loading}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        submitText={intl.formatMessage({
          defaultMessage: "Create User",
          id: "BTN_CREATE_USER",
        })}
      />
    </Segment>
  );
};

export default UserCreatePage;
