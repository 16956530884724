import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import ReactDOM from "react-dom";
import "./config.css";
import "./index.css";
import "./App.css";
import App from "./App";
import BodyFooter from "./components/bodyFooter";
import { Config } from "./config/api.js";
import { LangContext } from "./context";
import Registry from "./libs/register_storage";
import { Auth0Provider } from "./reactAuth0Wrapper";
import "semantic-ui-css/semantic.min.css"; // keep last in order to overrule global CSS

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  const url = appState?.targetUrl || window.location.pathname;
  window.history.replaceState({}, document.title, url);
};

const storage = new Registry(window.localStorage);
const redirectUrl = `${window.location.origin}${Config.basename}`;
const LANG = (client) => {
  return require(`./assets/client/${client}/lang.json`);
};

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
    user: { key: storage.getItem("organization") || 0 },
  });

  ReactDOM.render(
    <Auth0Provider
      domain={Config.auth0.domain}
      client_id={Config.auth0.clientId}
      redirect_uri={redirectUrl}
      onRedirectCallback={onRedirectCallback}
    >
      <LDProvider>
        <LangContext.Provider value={LANG(Config.public_client)}>
          <App />
          <BodyFooter />
        </LangContext.Provider>
      </LDProvider>
    </Auth0Provider>,
    document.getElementById("root"),
  );
})();
