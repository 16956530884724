import AbstractService from "../abstract.service";

export default class AuthService extends AbstractService {
  static URI_STATES = new Map([
    ["auth.login", "/login"],
    ["auth.logout", "/logout"],
    ["auth.authorized", "/authorized"],
  ]);

  /**
   * Log user in
   * @param {object} userData
   * @returns {Promise<any | never>}
   */
  login(userData) {
    return this.api_server.post(AuthService.URI_STATES.get("auth.login"), userData);
  }

  /**
   * Log user out
   */
  async logout() {
    return await this.api_server.get(AuthService.URI_STATES.get("auth.logout"), {});
  }

  /**
   * Call authorized endpoint
   * @returns {Promise<any | never>}
   */
  async authorized() {
    return await this.api_server.get(AuthService.URI_STATES.get("auth.authorized"));
  }

  getUser() {
    return this.registry.getItem("user") || {};
  }
}
