import uuid from "uuid/v4";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { NavLink } from "react-router-dom";
import {
	Button,
	Divider,
	Grid,
	Menu,
	Icon,
	Message,
	Pagination,
	Segment,
	Table,
} from "semantic-ui-react";
import { useIntl } from "react-intl";
import FilterControl from "./filter_control";
import PixelsGridContext from "./context";
import { pixelsActions, pixelGridReducer } from "./reducers";
import {isNewTabClick, onBannerDismiss} from "../../../libs/component_utils";
import Pager from "../../../models/pager";
import Filter from "./models/filter";
import PixelsService from "../../../services/pixels";
import { OnlineContext } from "../../../context/online-context";
import PixelModal from "../modal";

import "./index.css";
import {useFlags} from "launchdarkly-react-client-sdk";
import {isNil} from "../../../libs/common_utils";

const initialState = {
	"pixels": [],
	"pager": new Pager(),
	"filter": new Filter(),
	"total_pages": 0,
};

export const PixelsPage = ({ history }) => {
	const { agency } = useContext(OnlineContext);
	const [state, dispatch] = useReducer(pixelGridReducer, initialState);
	const [gridLoading, setGridLoading] = useState(true);
	let _isMounted = React.useRef(false);
	const services = React.useRef(new Map([["pixels", new PixelsService()]]));
	const [modalData, setModalData] = useState(null);
	const intl = useIntl();

	const showSuccessMessage = !isNil(history.location.state);
	const {whitelabelEventPixelCreateButton, whitelabelRoiPixelEnabled} = useFlags();

	/**
	 * initial load
	 */
	React.useEffect(() => {
		_isMounted.current = true;
		// load list of items
		getList().then(() => console.log);

		// clear cache
		return () => {
			_isMounted.current = false;
		};
	}, []);

	/**
	 * get page
	 * @param e
	 * @param activePage
	 */
	const getPage = (e, { activePage }) => {
		state.pager.setPage(activePage);
		getList(state.filter, state.pager).then(() => console.log);
	};

	const updateFilters = (json) => {
		state.pager.reset();
		state.filter.fromJson(json);

		getList(state.filter, state.pager).then(() => console.log);
	};

	/**
	 * get to create page
	 */
	const navigateToCreatePage = () => {
		history.push("/pixel/create");
	};

	const navigateToCreateRoiPage = () => {
		history.push("/pixel-roi/create");
	};

	/**
	 * navigate user to edit page
	 */
	const navigateToEditPage = useCallback(
		(id) => {
			history.push(
				`/pixel/edit/${id}`,
				state.pixels.find((pixel) => pixel.t1_id === id)
			);
		},
		[state]
	);

	/**
	 * navigate user to edit ROI pixel page
	 */
	const navigateToEditRoiPage = useCallback(
		(id) => {
			history.push(
				`/pixel-roi/edit/${id}`,
				state.pixels.find((pixel) => pixel.t1_id === id)
			);
		},
		[state]
	);

	/**
	 * get EventPixel edit page
	 */
	const getEditPageHref = useCallback((id) => `./pixel/edit/${id}`, []);

	/**
	 * get RoiPixel edit page
	 */
	const getEditRoiPageHref = useCallback((id) => `./pixel-roi/edit/${id}`, []);

	/**
	 * load pixels from API
	 * @param {object|null} filter
	 * @param {object|null} pager
	 * @return {Promise<void>}
	 */
	const getList = async (filter = null, pager = null) => {
		const pixels = services.current.get("pixels");
		try {
			setGridLoading(true);

			let params = {};
			if (filter) {
				params = Object.assign(params, filter.toRequestJson());
			}
			if (pager) {
				params = Object.assign(params, pager.toJson());
			}

			const response = await pixels.listByAgency(agency, params);
			let meta = response.meta;
			meta.page = 1;

			dispatch({
				"type": pixelsActions.INIT,
				"data": response.data,
				"pager": meta,
			});
		} catch (e) {
			// ignore error
			console.log(e);
		} finally {
			if (_isMounted.current) {
				setGridLoading(false);
			}
		}
	};

	return (
		<Segment basic style={{"padding": "0"}}>
			<PixelsGridContext.Provider
				value={{
					navigateToCreatePage,
					navigateToCreateRoiPage,
					navigateToEditPage,
					navigateToEditRoiPage,
					getEditPageHref,
					getEditRoiPageHref,
					getPage,
					"filtering": updateFilters,
					setModalData,
					whitelabelEventPixelCreateButton, whitelabelRoiPixelEnabled
				}}
			>
				{showSuccessMessage && (
					<PixelsSuccessMessage
						details={history.location.state || {}}
					/>
				)}
				<h1>
					{intl.formatMessage({
						id: "HEADING_AUDIENCES",
						defaultMessage: "Audiences",
					})}
				</h1>
				<Divider hidden />
				<PixelsGrid
					items={state.pixels}
					loading={gridLoading}
					controls={{ "pager": state.pager, "filter": state.filter }}
				/>
				<PixelModal
					open={Boolean(modalData)}
					id={modalData?.id}
					advertiser_id={modalData?.advertiser_id}
					values={modalData}
					onClose={() => setModalData(null)}
				/>
			</PixelsGridContext.Provider>
		</Segment>
	);
};

/**
 * Generate success message
 * @param {object} details
 * @return {*}
 * @constructor
 */
const PixelsSuccessMessage = ({ details }) => {
	const intl = useIntl();

	let timer;
	React.useEffect(() => {
		timer = setTimeout(onBannerDismiss, 10000);
		return () => {
			clearTimeout(timer);
		};
	}, []);

	if (details.action === "created") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
			>
				{intl.formatMessage({
					id: "MESSAGE_PIXEL_CREATED",
					defaultMessage: "Pixel successfully created",
				})}
			</Message>
		);
	} else if (details.action === "updated") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
			>
				{intl.formatMessage({
					id: "MESSAGE_PIXEL_UPDATED",
					defaultMessage: "Pixel {name} updated",
				}, {
					name: details.title
				})}
			</Message>
		);
	}

	return null;
};
PixelsSuccessMessage.propTypes = {
	"details": PropTypes.object.isRequired,
};

/**
 * Render grid
 * @param {array} items
 * @param {boolean} loading
 * @param {object} controls
 * @return {JSX.Element}
 * @constructor
 */
export const PixelsGrid = ({ items, loading, controls }) => {
	const context = React.useContext(PixelsGridContext);
	const intl = useIntl();

  useEffect(() => {
    return () => {
      controls.pager.reset();
      controls.filter.reset();
    }
  }, []);

	return (
		<>
			<Grid className="common_grid">
				<Grid.Row>
					<Grid.Column>
						<Menu pointing secondary>
							<Menu.Item
								as={NavLink}
								active={true}
								to="/pixels"
								content={intl.formatMessage({
									id: "LINK_PIXELS",
									defaultMessage: "Pixels",
								})}
							/>
							<Menu.Item
								as={NavLink}
								to="/deviceid"
								content={intl.formatMessage({
									id: "LINK_DEVICE_ID",
									defaultMessage: "Device ID",
								})}
							/>
							<Menu.Menu position="right">
								<Menu.Item>
									{context.whitelabelEventPixelCreateButton && <Button
										primary
										compact
										className="text__uppercase"
										onClick={() => context.navigateToCreatePage()}
									>
										{intl.formatMessage({
											id: "BTN_ADD_EVENT_PIXEL",
											defaultMessage: "Add Event Pixel",
										})}
									</Button>}&nbsp;
									{context.whitelabelRoiPixelEnabled && <Button
										primary
										compact
										className="text__uppercase"
										onClick={() => context.navigateToCreateRoiPage()}
									>
										{intl.formatMessage({
											id: "BTN_ADD_ROI_PIXEL",
											defaultMessage: "Add ROI Pixel",
										})}
									</Button>}
								</Menu.Item>
							</Menu.Menu>
						</Menu>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Segment loading={loading} basic style={{ "padding": "0" }}>
				<Grid className="common_grid">
					<Grid.Row>
						<Grid.Column>
							<FilterControl
								filter={controls.filter}
								onChange={context.filtering}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Table className="custom-table pixel-table">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								{intl.formatMessage({
									id: "LABEL_PIXEL_NAME",
									defaultMessage: "Pixel Name",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="left">
								{intl.formatMessage({
									id: "LABEL_STATUS",
									defaultMessage: "Status",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="left">
								{intl.formatMessage({
									id: "LABEL_PIXEL_ID",
									defaultMessage: "Pixel ID",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="left">
								{intl.formatMessage({
									id: "LABEL_ADVERTISER_ID",
									defaultMessage: "Advertiser ID",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="left">
								{intl.formatMessage({
									id: "LABEL_ADVERTISER",
									defaultMessage: "Advertiser",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center" style={{ "width": "70px" }}>
								&nbsp;
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{items.length ? (
							items.map((item) => {
								return <PixelGridItem key={uuid()} {...item} />;
							})
						) : (
							<GridEmptyRow
								eventPixelButton={context.whitelabelEventPixelCreateButton}
								roiPixelButton={context.whitelabelRoiPixelEnabled}
								filterIsEmpty={controls.filter.isEmpty()}
								onAddButtonClick={context.navigateToCreatePage}
								onAddRoiButtonClick={context.navigateToCreateRoiPage}
							/>
						)}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.Cell colSpan="12" textAlign="right">
								{controls.pager.total_pages > 1 && (
									<Pagination
										size="mini"
										activePage={controls.pager.page}
										totalPages={controls.pager.total_pages}
										firstItem={null}
										lastItem={null}
										onPageChange={context.getPage}
									/>
								)}
							</Table.Cell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</>
	);
};

/**
 * Generate grid item
 * @param {object} item
 * @return {*}
 * @constructor
 */
const PixelGridItem = (item) => {
	const context = React.useContext(PixelsGridContext);
	const intl = useIntl();
	const isRoiPixel = context.whitelabelRoiPixelEnabled && item["is_roi"];

	return (
		<Table.Row className={item.status ? "" : " inactive"}>
			<Table.Cell className="grid-item-title">{item.title}</Table.Cell>
			<Table.Cell>
				{item.status
					? intl.formatMessage({
							id: "STATUS_ACTIVE",
							defaultMessage: "Active",
					  })
					: intl.formatMessage({
							id: "STATUS_INACTIVE",
							defaultMessage: "Inactive",
					  })}
			</Table.Cell>
			<Table.Cell>{item.id}</Table.Cell>
			<Table.Cell>{item.advertiser_id}</Table.Cell>
			<Table.Cell>{item.advertiser_name}</Table.Cell>
			<Table.Cell data-clickable="0" textAlign="center">
				{!isRoiPixel && <Icon
					name="info circle"
					title={intl.formatMessage({
						id: "HINT_TAG_SETUP",
						defaultMessage: "Show tag setup instructions",
					})}
					className="control"
					onClick={() => context.setModalData({...item,
						"values": item
					})}
				/>}
				<a
					href={(isRoiPixel)? context.getEditRoiPageHref(item.id) : context.getEditPageHref(item.id)}
					className="control"
					onClick={(e) => {
						if (isNewTabClick(e)) return;
						e.preventDefault();
						e.stopPropagation();
						if(isRoiPixel) {
							context.navigateToEditRoiPage(item.id);
						} else {
							context.navigateToEditPage(item.id);
						}
					}}
				>
					<Icon name="edit" />
				</a>
			</Table.Cell>
		</Table.Row>
	);
};

const GridEmptyRow = ({ filterIsEmpty, onAddButtonClick, onAddRoiButtonClick, eventPixelButton, roiPixelButton }) => {
	const intl = useIntl();

	return (
		<Table.Row>
			<Table.Cell colSpan="6" textAlign="center">
				{filterIsEmpty ? (
					<>
						{intl.formatMessage({
							id: "EMPTY_PIXELS",
							defaultMessage: "You don’t have any pixels yet",
						})}
						<br />
						<br />
						{eventPixelButton && <Button
							className="text__uppercase"
							primary
							compact
							onClick={onAddButtonClick}
						>
							{intl.formatMessage({
								id: "BTN_ADD_EVENT_PIXEL",
								defaultMessage: "Add Event Pixel",
							})}
						</Button>}
						{roiPixelButton && <Button
							className="text__uppercase"
							primary
							compact
							onClick={onAddRoiButtonClick}
						>
							{intl.formatMessage({
								id: "BTN_ADD_ROI_PIXEL",
								defaultMessage: "Add ROI Pixel",
							})}
						</Button>}
					</>
				) : (
					intl.formatMessage({
						id: "EMPTY_SEARCH_RESULTS",
						defaultMessage: "No results found",
					})
				)}
			</Table.Cell>
		</Table.Row>
	);
};
GridEmptyRow.propTypes = {
	"filterIsEmpty": PropTypes.bool.isRequired,
	"onAddButtonClick": PropTypes.func.isRequired,
	"onAddRoiButtonClick": PropTypes.func.isRequired,
	"eventPixelButton": PropTypes.bool.isRequired,
	"roiPixelButton": PropTypes.bool.isRequired
};
