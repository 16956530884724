/* eslint-disable jsx-a11y/anchor-is-valid */
import { parse } from "date-fns";
import PropTypes from "prop-types";
import React, { useCallback, useReducer, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
	Button,
	Checkbox,
	Divider,
	Grid,
	List,
	Message,
	Modal,
	Pagination,
	Popup,
	Segment,
	Select,
	Table,
} from "semantic-ui-react";
import uuid from "uuid/v4";

import { isNil } from "../../../libs/common_utils";
import { onBannerDismiss } from "../../../libs/component_utils";
import Registry from "../../../libs/register_storage";
import Pager from "../../../models/pager";
import AgenciesService from "../../../services/agencies";
import CampaignsService from "../../../services/campaign";
import ReportService from "../../../services/reports";
import ChartDiv from "../../common/chart";
import GridEmptyRow from "../../common/gridEmptyRow";
import { CampaignGridItem } from "../common/campaign_grid_item";
import { graph_metrics } from "../fixtures";
import CampaignGridContext from "./context";
import FilterControl from "./filter_control";
import Filter from "./models/filter";
import { campaignsActions as cA, campaignsGridReducer } from "./reducers";
import { CampaignsBulkEditModal } from "../edit/campaigns_bulk_edit_modal";
import "../index.css";

const initialState = {
	campaigns: [],
	pager: new Pager(),
	filter: new Filter(),
};

const default_metrics = {
	METRIC_1: "total_spend",
	METRIC_2: "impressions",
};

const CampaignsPage = ({ history }) => {
	const [state, dispatch] = useReducer(campaignsGridReducer, initialState);
	const [graph, setGraph] = React.useState({
		data: [],
		loading: false,
	});
	const [graph_filter, setGraphFilter] = React.useState({
		metric_1: default_metrics.METRIC_1,
		metric_2: default_metrics.METRIC_2,
	});
	const [gridLoading, setGridLoading] = useState(false);
	const showSuccessMessage = !isNil(history.location.state);

	const [campaignSelected, setCampaignSelected] = React.useState({});
	const [campaignForDuplication, setCampaignForDuplication] =
		React.useState(null);
	const [duplicatedCampaigns, setDuplicatedCampaigns] = React.useState([]);
	const [duplicatedCampaignsFailures, setDuplicatedCampaignsFailures] =
		React.useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
  const [bulkEditResponseMessage, setBulkEditResponseMessage] = useState({successMessage: [], errorMessage: []});
  const [showBulkEditResponseMessage, setShowBulkEditResponseMessage] = useState(false);
	const no_campaigns_initially = React.useRef(true);
	const timestamp = React.useRef(0);
	let _isMounted = React.useRef(false);
	const services = React.useRef(
		new Map([
			["campaigns", new CampaignsService()],
			["agencies", new AgenciesService()],
			["report", new ReportService()],
		])
	);
	const intl = useIntl();

	const agency = services.current.get("agencies").getSelectedAgency() || 0;
	const isCampaignSelected = () => Object.keys(campaignSelected).length > 0;

	/**
	 * initial load campaigns
	 */
	React.useEffect(() => {
		state.filter.reset();
		state.pager.reset();

		_isMounted.current = true;
		(async () => {
			await getCampaigns(state.filter, state.pager, true);
		})();

		// clear cache
		return () => {
			_isMounted.current = false;
		};
	}, [agency]);

	/**
	 * get graph data for campaign being selected
	 */
	React.useEffect(() => {
		if (!campaignSelected.id) {
			return;
		}

		window.scrollTo(0, 0);
		(async () => {
			try {
				setGraph({
					data: [],
					loading: true,
				});

				const params = Object.assign(
					{
						id: campaignSelected.id,
						m1: graph_filter.metric_1,
						m2: graph_filter.metric_2,
					},
					state.filter.toGraphJson()
				);
				const r = await services.current.get("report").campaignGraph(params);
				let data2display = r.data.graph_data.map((x) => ({
					...x,
					date: parse(x.date, "yyyy-MM-dd", new Date()).valueOf(),
				}));

				data2display = data2display.sort((a, b) => a.date - b.date);
				timestamp.current = Date.now();
				setGraph({
					...graph,
					data: data2display,
					loading: false,
				});
			} catch (e) {
				console.error(e);
				setGraph({
					...graph,
					loading: false,
				});
			}
		})();
	}, [campaignSelected, graph_filter, state.filter.start_date]);

	const sortCampaigns = useCallback((sort_by, order) => {
		console.log(sort_by, order);
	}, []);

	/**
	 * get page
	 * @param e
	 * @param activePage
	 */
	const getPage = (e, { activePage }) => {
		state.pager.setPage(activePage);
		getCampaigns(state.filter, state.pager).then(() => console.log);
	};

	/**
	 * open chart
	 */
	const openChartAccordion = () => {
		document.getElementById("chart_swap_id").setAttribute("open", "true");
	};

	/**
	 * load campaigns from API
	 * @param {object} filter
	 * @param {object} pager
	 * @param {boolean} initial_load
	 * @return {Promise<void>}
	 */
	const getCampaigns = async (filter, pager, initial_load = false) => {
		const campaigns = services.current.get("campaigns");
		try {
			setGridLoading(true);

			let params = pager.toJson();
			if (filter) {
				params = Object.assign(params, filter.toRequestJson());
			}

			const r = await campaigns.list(agency, params);
			let meta = r.meta;
			meta.page = pager.page;
			if (initial_load) {
				no_campaigns_initially.current = !Boolean(r.data.length);
				if (Boolean(r.data.length)) {
					setCampaignSelected(r.data[0]);
				}
			}
			dispatch({ type: cA.INIT, data: r.data, pager: meta });
		} catch (e) {
			// ignore error
			console.error(e);
		} finally {
			if (_isMounted.current) {
				setGridLoading(false);
			}
		}
	};

	const duplicateCampaign = async (item) => {
		const campaigns = services.current.get("campaigns");
		campaigns
			.duplicate(item["id"])
			.then(() => {
				setDuplicatedCampaigns([...duplicatedCampaigns, item]);
				getCampaigns(state.filter, state.pager);
			})
			.catch((e) => {
				setDuplicatedCampaignsFailures([...duplicatedCampaigns, { ...item, errorMessage: e.error?.message ? e.error?.message : "" }]);
			});
	};

	/**
	 * filter campaigns
	 * @param {object} json
	 */
	const filterCampaigns = (json) => {
		state.pager.reset();
		state.filter.fromJson(json);
		getCampaigns(state.filter, state.pager).then(() => console.log);
	};

	/**
	 * navigate user to create campaign page
	 */
	const navigateToCreatePage = useCallback(() => {
		history.push("/campaign/create");
	}, []);

	/**
	 * navigate user to edit page
	 */
	const navigateToEditPage = useCallback(
		(id) => {
			history.push(
				`/campaign/edit/${id}`,
				state.campaigns.find((campaign) => id === campaign.id)
			);
		},
		[state]
	);

	/**
	 * get edit page URL by campaign id
	 */
	const getEditPageHref = useCallback((id) => {
		return `./campaign/edit/${id}`;
	}, []);

	/**
	 * navigate user to create campaign page
	 */
	const navigateToStrategiesPage = useCallback((campaign_id) => {
		history.push(`/campaign/${campaign_id}/strategies`);
	}, []);

	/**
	 * Update filter metrics
	 * @param e
	 * @param {string} name
	 * @param {string} value
	 */
	const onChangeMetric = (e, { name, value }) => {
		setGraphFilter({
			...graph_filter,
			[name]: value,
		});
	};

  const handleBannerDismiss = useCallback(() => {
    setBulkEditResponseMessage({ successMessage: [], errorMessage: [] });
  }, [setBulkEditResponseMessage]);

  useEffect(() => {
    setShowBulkEditResponseMessage(bulkEditResponseMessage['successMessage'].length > 0 || bulkEditResponseMessage['errorMessage'].length > 0);
    const timer = setTimeout(handleBannerDismiss, 10000);
    return () => clearTimeout(timer);
  }, [bulkEditResponseMessage]);

	return (
		<Segment loading={gridLoading} basic style={{ padding: "0" }}>
			<CampaignGridContext.Provider
				value={{
					sorting: sortCampaigns,
					getPage: getPage,
					filtering: filterCampaigns,
					navigateToStrategiesPage,
					navigateToCreatePage,
					navigateToEditPage,
					getEditPageHref,
					setCampaignSelected,
					openChartAccordion,
					campaignForDuplication,
					setCampaignForDuplication,
					duplicateCampaign,
          selectedCampaigns,
          setSelectedCampaigns,
          isBulkEditModalOpen,
          setIsBulkEditModalOpen,
          dispatch,
          setBulkEditResponseMessage
				}}
			>
				{showSuccessMessage && (
					<CampaignsSuccessMessage details={history.location.state || {}} />
				)}
        {showBulkEditResponseMessage &&
          <>
            <Message
                success
                hidden={bulkEditResponseMessage['successMessage'].length === 0}
                onDismiss={handleBannerDismiss}>
                  <div>
                    {intl.formatMessage({
                      id: "MESSAGE_CAMPAIGN_BULK_EDIT_UPDATED",
                      defaultMessage: "Campaigns successfully updated"
                    })}
                    <ul>
                      {bulkEditResponseMessage['successMessage'].map((campaign) => (
                        <li key={campaign.id}>{campaign.name}</li>
                      ))}
                    </ul>
                  </div>
              </Message>
              <Message
                error
                hidden={bulkEditResponseMessage['errorMessage'].length === 0}
                onDismiss={handleBannerDismiss}>
                <div>
                  {intl.formatMessage({
                    id: "MESSAGE_CAMPAIGN_BULK_EDIT_NOT_UPDATED",
                    defaultMessage: "These campaigns were not updated"
                  })}
                  <ul>
                    {bulkEditResponseMessage['errorMessage'].map((campaign) => (
                      <li key={uuid()}>{campaign.message}</li>
                    ))}
                  </ul>
                </div>
              </Message>
          </>
        }
				{duplicatedCampaigns.length > 0 && (
					<Message success onDismiss={() => setDuplicatedCampaigns([])}>
						{intl.formatMessage(
							{
								id: "SUCCESSFUL_DUPLICATION_MSG",
								defaultMessage: `Successfully duplicated: ${duplicatedCampaigns
									.map((item) => item.title)
									.join(", ")}`,
							},
							{
								title: duplicatedCampaigns.map((item) => item.title).join(", "),
							}
						)}
					</Message>
				)}
				{duplicatedCampaignsFailures.length > 0 && (
					<Message
						negative
						onDismiss={() => setDuplicatedCampaignsFailures([])}
					>
						{intl.formatMessage(
							{
								id: "FAILED_DUPLICATION_MSG",
								defaultMessage: `Failed duplicating: ${duplicatedCampaignsFailures
									.map((item) => item.title)
									.join(", ")}`,
							},
							{
								title: duplicatedCampaignsFailures
									.map((item) => item.title)
									.join(", "),
							}
						)}
						{duplicatedCampaignsFailures.map((item, index) => <span key={`message-${index}`} className="error-message-child">{`${intl.formatMessage(
							{
								id: "ERROR",
								defaultMessage: `Error`,
							}
						)}: ${item.errorMessage}`}</span>)}
					</Message>
				)}
				<h1>
					{intl.formatMessage({
						id: "HEADING_CAMPAIGNS",
						defaultMessage: "Campaigns",
					})}
				</h1>
				{isCampaignSelected() && (
					<details id="chart_swap_id">
						<summary>
							<hr />{" "}
							<div>
								{intl.formatMessage({
									id: "BUTTON_SHOW_HIDE_CHART",
									defaultMessage: "Show / hide chart",
								})}
							</div>
						</summary>
						<Grid>
							<Grid.Column verticalAlign="middle" style={{ width: "auto" }}>
								<strong>{campaignSelected.title}</strong>
							</Grid.Column>
							<Grid.Column width={2}>
								<Select
									selection
									fluid
									options={graph_metrics(intl).filter(
										(x) => x.value !== graph_filter.metric_2
									)}
									name="metric_1"
									disabled={graph.loading}
									onChange={onChangeMetric}
									value={graph_filter.metric_1}
								/>
							</Grid.Column>
							<Grid.Column
								style={{ width: "15px" }}
								verticalAlign="middle"
								textAlign="center"
							>
								{intl.formatMessage({
									id: "LABEL_CAMPAIGN_VS_METRIC",
									defaultMessage: "vs",
								})}
							</Grid.Column>
							<Grid.Column width={2}>
								<Select
									selection
									fluid
									options={graph_metrics(intl).filter(
										(x) => x.value !== graph_filter.metric_1
									)}
									name="metric_2"
									disabled={graph.loading}
									onChange={onChangeMetric}
									value={graph_filter.metric_2}
								/>
							</Grid.Column>
						</Grid>
						{graph.loading ? (
							<Segment loading />
						) : (
							<ChartDiv
								updated={timestamp.current}
								filter={graph_filter}
								data={graph.data}
							/>
						)}
					</details>
				)}
				<Divider hidden />
				<CampaignsGrid
					items={state.campaigns}
					loading={gridLoading}
					advertisers_disabled={no_campaigns_initially.current}
					controls={{ pager: state.pager, filter: state.filter }}
				/>
			</CampaignGridContext.Provider>
		</Segment>
	);
};

/**
 * Generate success message
 * @param {object} details
 * @return {*}
 * @constructor
 */
const CampaignsSuccessMessage = ({ details }) => {
	const intl = useIntl();
	let timer;
	React.useEffect(() => {
		timer = setTimeout(onBannerDismiss, 25000);
		return () => {
			clearTimeout(timer);
		};
	}, []);

	if (details.action === "created") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
			>
				{intl.formatMessage({
					id: "MESSAGE_CAMPAIGN_CREATED",
					defaultMessage: "Campaign successfully created",
				})}
			</Message>
		);
	} else if (details.action === "updated") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
			>
				{intl.formatMessage(
					{
						id: "MESSAGE_CAMPAIGN_UPDATED",
						defaultMessage: "Campaign {name} updated",
					},
					{
						name: details.name,
					}
				)}
			</Message>
		);
	}

	return <></>;
};
CampaignsSuccessMessage.propTypes = {
	details: PropTypes.object.isRequired,
};

const campaignColumns = {
	status: true,
	start_date: true,
	end_date: true,
	campaign_budget: true,
	campaign_spend: true,
	campaign_ctr: true,
	campaign_clicks: true,
	campaign_impressions: true,
	total_conversions: true,
	total_revenue: true,
  total_spend_cpa: true,
	total_spend_cpc: true,
	total_spend_cpm: true,
	goal_type: true,
	goal_value: true,
	frequency_cap: true
};
const columnNames = new Map([
	["status", "Status"],
	["start_date", "Start Date"],
	["end_date", "End Date"],
	["campaign_budget", "Budget"],
	["campaign_spend", "Spend"],
	["campaign_ctr", "CTR"],
	["campaign_clicks", "Clicks"],
	["campaign_impressions", "Impressions"],
	["total_conversions", "Total Conversions"],
	["total_revenue", "Total Revenue"],
	["total_spend_cpa", "Total Spend eCPA"],
	["total_spend_cpc", "Total Spend eCPC"],
	["total_spend_cpm", "Total Spend eCPM"],
	["goal_type", "Goal Type"],
	["goal_value", "Goal Value"],
	["frequency_cap", "Frequency Cap"],
]);
const columnMessageIds = new Map([
	["status", "LABEL_STATUS"],
	["start_date", "LABEL_START_DATE"],
	["end_date", "LABEL_END_DATE"],
	["campaign_budget", "LABEL_BUDGET"],
	["campaign_spend", "LABEL_SPEND"],
	["campaign_ctr", "LABEL_CTR"],
	["campaign_clicks", "LABEL_CLICKS"],
	["campaign_impressions", "LABEL_IMPRESSIONS"],
  ["total_conversions", "LABEL_TOTAL_CONVERSIONS"],
	["total_revenue", "LABEL_TOTAL_REVENUE"],
	["total_spend_cpa", "LABEL_TOTAL_SPEND_CPA"],
	["total_spend_cpc", "LABEL_TOTAL_SPEND_CPC"],
	["total_spend_cpm", "LABEL_TOTAL_SPEND_CPM"],
	["goal_type", "LABEL_GOAL_TYPE"],
	["goal_value", "LABEL_GOAL_VALUE"],
	["frequency_cap", "LABEL_FREQUENCY_CAP"],
]);

// set storage for campaign columns information
const storage = new Registry(window.sessionStorage);
if (!storage.getItem("campaigns_grid")) {
	storage.setItem("campaigns_grid", campaignColumns);
}

/**
 * Generate campaigns grid
 * @param {array} items
 * @param {object} control
 * @return {*}
 * @constructor
 */
const CampaignsGrid = ({
	items,
	loading,
	controls,
	advertisers_disabled = false,
}) => {
	const intl = useIntl();
	const context = React.useContext(CampaignGridContext);
	const [gridData, setGridData] = React.useState(
		storage.getItem("campaigns_grid")
	);

	/**
	 * check should we show a cell based on flag in the map
	 * @param cellName
	 * @return {string}
	 */
	const cellVisible = (cellName) => {
		return gridData.hasOwnProperty(cellName) && gridData[cellName]
			? ""
			: "invisible";
	};

	/**
	 * click on the button to hide a popup
	 */
	const listener = () => {
		document.getElementById("edit-column-button").click();
	};

	/**
	 * attach a listener for mouse leave on edit-column popup
	 */
	const attachListener = () => {
		document
			.getElementById("edit-column-id")
			.addEventListener("mouseleave", listener);
	};

	/**
	 * remove a listener for mouse leave on component unmount
	 */
	const removeListener = () => {
		document
			.getElementById("edit-column-id")
			.removeEventListener("mouseleave", listener);
	};

	/**
	 * Storing grid information data
	 */
	React.useEffect(() => {
		storage.setItem("campaigns_grid", gridData);
	}, [gridData]);

	const {
		campaignForDuplication,
		setCampaignForDuplication,
		duplicateCampaign,
    selectedCampaigns,
    setSelectedCampaigns,
    isBulkEditModalOpen,
    setIsBulkEditModalOpen,
    dispatch,
    setBulkEditResponseMessage
	} = context;

  const areAllCampaignsChecked = selectedCampaigns.length === items.length;

  const handleCheckboxChange = useCallback((e, { checked }) => {
    setSelectedCampaigns(checked ? items.map(({ id }) => id) : []);
  }, [items, setSelectedCampaigns]);

	return (
		<>
			<Modal
				open={campaignForDuplication}
				onClose={() => setCampaignForDuplication(null)}
				closeOnDimmerClick={false}
				closeOnDocumentClick={false}
			>
				<Modal.Header>
					{intl.formatMessage({
						id: "DUPLICATION_CONFIRMATION_HEADER",
						defaultMessage: "Confirm duplication",
					})}
				</Modal.Header>
				<Modal.Content>
					{campaignForDuplication &&
						intl.formatMessage(
							{
								id: "DUPLICATION_CAMPAIGN_CONFIRMATION_MSG",
								defaultMessage:
									"Are you sure you want to duplicate campaign - {title}?",
							},
							{ title: campaignForDuplication["title"] }
						)}
				</Modal.Content>
				{context.campaignForDuplication && (
					<Modal.Actions>
						<Button onClick={() => setCampaignForDuplication(null)}>
							{intl.formatMessage({
								id: "BTN_CANCEL",
								defaultMessage: "Cancel",
							})}
						</Button>
						<Button
							primary
							onClick={() => {
								duplicateCampaign({ ...campaignForDuplication });
								setCampaignForDuplication(null);
							}}
						>
							{intl.formatMessage({
								id: "BTN_OK",
								defaultMessage: "Ok",
							})}
						</Button>
					</Modal.Actions>
				)}
			</Modal>
			<Grid className="common_grid">
				<Grid.Row columns={2}>
					<Grid.Column width={12}>
						<FilterControl
							filter={controls.filter}
							advertisers_disabled={advertisers_disabled}
							onChange={context.filtering}
						/>
					</Grid.Column>
					<Grid.Column
						width={4}
						className="margin__top20"
						textAlign="right"
						verticalAlign="middle"
					>
						<div>
							<Popup
								trigger={
									<Button
										className="margin__right20 text__uppercase"
										id="edit-column-button"
										compact
                    data-testid="edit_columns_button"
									>
										{intl.formatMessage({
											id: "BTN_EDIT_COLUMNS",
											defaultMessage: "Edit Columns",
										})}
									</Button>
								}
								onMount={attachListener}
								onUnmount={removeListener}
								id="edit-column-id"
								content={
									<List>
										{Object.keys(gridData).map((column) => {
											return (
												<List.Item key={uuid()}>
                          <Checkbox
                            label={intl.formatMessage({
                              id: columnMessageIds.get(column),
                              defaultMessage: columnNames.get(column),
                            })}
                            name={column}
                            defaultChecked={gridData[column]}
                            onChange={(e, { name, checked }) => {
                              setGridData({ ...gridData, [name]: checked });
                            }}
                            as={"label"}
                            id={`column_${column}_checkbox`}
                            data-testid={`column_${column}_checkbox`}
                          />
												</List.Item>
											);
										})}
									</List>
								}
								on="click"
								position="bottom right"
							/>
              <Popup
                content={
                  intl.formatMessage({
                    id: "TOOLTIP_SELECT_CAMPAIGNS_TO_BULK_EDIT",
                    defaultMessage: "Select one or more Campaigns to Bulk Edit"
                  })
                }
                size="tiny"
                inverted
                position="top center"
                disabled={selectedCampaigns.length > 0}
                trigger={
                  <div style={{ display: 'inline-block' }}>
                    <Button
                      className="text__uppercase"
                      primary
                      compact
                      onClick={() => setIsBulkEditModalOpen(true)}
                      disabled={selectedCampaigns.length <= 0}
                    >
                      {intl.formatMessage({
                        id: "BTN_EDIT_CAMPAIGNS",
                        defaultMessage: "Bulk Edit Campaigns",
                      })}
                    </Button>
                  </div>
                }
              />
							<Button
								className="text__uppercase"
								primary
								compact
								onClick={() => context.navigateToCreatePage()}
                data-testid="add_campaign_button"
							>
								{intl.formatMessage({
									id: "BTN_ADD_CAMPAIGN",
									defaultMessage: "Add Campaign",
								})}
							</Button>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
      <div className="campaign-grid-table-container">
        <Table className="custom-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{width:"40px"}}>
                <Checkbox
                  checked={areAllCampaignsChecked}
                  onChange={handleCheckboxChange}
                />
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left">
                {intl.formatMessage({
                  id: "LABEL_CAMPAIGN_NAME",
                  defaultMessage: "Campaign Name",
                })}
              </Table.HeaderCell>
              {Array.from(columnNames.entries()).map(([key, value]) => {
                return (
                  <Table.HeaderCell
                    key={uuid()}
                    textAlign="left"
                    className={cellVisible(key)}
                  >
                    {intl.formatMessage({
                      id: columnMessageIds.get(key),
                      defaultMessage: value,
                    })}
                </Table.HeaderCell>
              )
              })}
              <Table.HeaderCell textAlign="center">&nbsp;</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              return (
                <CampaignGridItem
                  key={uuid()}
                  {...item}
                  cellVisible={cellVisible}
                />
              );
            })}
            <GridEmptyRow
              length={items.length}
              loading={loading}
              handleAddButton={context.navigateToCreatePage}
              filterTouched={!controls.filter.isEmpty()}
              addButtonLabel={intl.formatMessage({
                id: "BTN_ADD_CAMPAIGN",
                defaultMessage: "Add Campaign",
              })}
              notFoundMessage={intl.formatMessage({
                id: "EMPTY_CAMPAIGNS",
                defaultMessage: "You don’t have any campaigns yet",
              })}
            />
          </Table.Body>
          <Table.Footer>
					  <Table.Row>
						  <Table.Cell className='campaign-grid-footer'>
							  {controls.pager.total_pages > 1 && (
                  <Pagination
                    size="mini"
                    activePage={controls.pager.page}
                    totalPages={controls.pager.total_pages}
                    firstItem={null}
                    lastItem={null}
                    onPageChange={context.getPage}
								  />
							  )}
						  </Table.Cell>
					</Table.Row>
				</Table.Footer>
        </Table>
        <CampaignsBulkEditModal
          isBulkEditModalOpen={isBulkEditModalOpen}
          setIsBulkEditModalOpen={setIsBulkEditModalOpen}
          selectedCampaigns={selectedCampaigns}
          items={items}
          dispatch={dispatch}
          setBulkEditResponseMessage={setBulkEditResponseMessage}
        />
      </div>
		</>
	);
};
CampaignsGrid.propTypes = {
	items: PropTypes.array.isRequired,
	controls: PropTypes.object.isRequired,
};

export default CampaignsPage;
