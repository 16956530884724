/* eslint-disable no-unreachable */
export const audiencesActions = {
  SORTING: "SORTING",
  FILTERING: "FILTERING",
  INIT: "INIT",
};

/**
 * Init items
 * @param {array} audiences
 * @param {object} meta
 * @param {number} meta.total_count
 * @param {number} meta.total
 * @param state
 * @return {object} new state
 */
const initItems = (audiences, meta, state) => {
  // rebuild campaign request in according to UI necessity
  state.pager.setTotal(meta);

  return {
    ...state,
    audiences,
  };
};

export const audienceGridReducer = (state, action) => {
  switch (action.type) {
    case audiencesActions.INIT:
      return initItems(action.data, action.pager, state);

    default:
      throw new Error("Method is not implemented");
  }
};
