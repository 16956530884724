import AbstractService from "../abstract.service";

export default class ReportService extends AbstractService {
  static URI_STATES = new Map([
    ["reports.run", "/reports"],
    ["reports.graph", "/graph"],
  ]);

  /**
   * Run a report
   * @param {object} data - values of the report form
   * @returns {object} report result
   */
  async run(data) {
    return await this.api_server.post(ReportService.URI_STATES.get("reports.run"), data);
  }

  /**
   * Make an API call to download file
   * @param {object} data - values of the report form
   * @returns {object} report result
   */
  async runDownload(data) {
    return await this.api_server.post_download(ReportService.URI_STATES.get("reports.run"), data);
  }

  /**
   * Get campaigns graph data
   * @param {object} params
   * @returns {Promise}
   */
  async campaignGraph(params = {}) {
    return await this.__graph(Object.assign({ type: "campaign" }, params));
  }

  /**
   * Get strategy graph data
   * @param {object} params
   * @returns {Promise}
   */
  async strategyGraph(params = {}) {
    return await this.__graph(Object.assign({ type: "strategy" }, params));
  }

  /**
   * Get graph data
   * @param {object} params
   * @returns {Promise}
   */
  async __graph(params = {}) {
    return await this.api_server.get(ReportService.URI_STATES.get("reports.graph"), params);
  }
}
