import AbstractService from "../abstract.service";

export default class T1Service extends AbstractService {
  static URI_STATES = new Map([
    ["t1.advertisers", "/advertisers/{0}"],
    ["t1.agencies", "/agencies/{0}"],
    ["t1.audience", "/strategy/audiences"],
    ["t1.campaigns_data", "/campaigns-data/{0}"],
    ["t1.campaigns", "/campaigns/{0}"],
    ["t1.dmas_root", "/strategy/dmas"],
    ["t1.dmas", "/strategy/dmas/{0}"],
    ["t1.organizations", "/organizations"],
    ["t1.regions_root", "/strategy/regions"],
    ["t1.regions", "/strategy/regions/{0}"],
    ["t1.strategies", "/strategies/{0}"],
    ["t1.taxonomy", "/strategy/taxonomies/{0}"],
  ]);

  /**
   * Get audiences from t1
   * @param {number} advertiserId
   * @param {boolean} outfilterClientData
   * @returns {Promise<any | never>}
   */
  async audiences(advertiserId, outfilterClientData = false) {
    // biome-ignore lint/style/useNamingConvention: <server params>
    const params = { advertiser_id: advertiserId };
    if (outfilterClientData) {
      params.supply_type = "open";
    }
    return await this.api_server.get(T1Service.URI_STATES.get("t1.audience"), params);
  }

  /**
   * Get dmas from t1
   * @param {number} [parentId]
   * @param {string} [query]
   * @returns {Promise<any | never>}
   */
  async dmas(parentId, query = "") {
    const params = {};
    if (query) {
      params.location_name = query;
    }

    if (!parentId) {
      return await this.api_server.get(T1Service.URI_STATES.get("t1.dmas_root"), params);
    }
    return await this.api_server.get(T1Service.URI_STATES.get("t1.dmas").format(parentId), params);
  }

  /**
   * Get regions from t1
   * @param {number} [parentId]
   * @param {string} [query]
   * @returns {Promise<any | never>}
   */
  async regions(parentId, query = "") {
    const params = {};
    if (query) {
      params.location_name = query;
    }
    if (!parentId) {
      return await this.api_server.get(T1Service.URI_STATES.get("t1.regions_root"), params);
    }

    return await this.api_server.get(T1Service.URI_STATES.get("t1.regions").format(parentId), params);
  }

  /**
   * Get audiences children from t1
   * @param {number} parentId
   * @param {number} advertiserId
   * @param {string} currencyCode
   * @param {string} audienceName
   * @returns {Promise<any | never>}
   */
  async audiencesChildren(parentId, advertiserId, currencyCode, audienceName = "") {
    // biome-ignore lint/style/useNamingConvention: <server params>
    const params = { advertiser_id: advertiserId, currency_code: currencyCode };
    if (audienceName) {
      params.audience_name = audienceName;
    }

    return await this.api_server.get(T1Service.URI_STATES.get("t1.taxonomy").format(parentId), params);
  }

  /**
   * Get organizations
   * @param {string} query
   * @returns {Promise<any | never>}
   */
  async organizations(query = "") {
    const params = {};
    if (query) {
      params.organization_name = query;
    }

    return await this.api_server.get(T1Service.URI_STATES.get("t1.organizations"), params);
  }

  /**
   * Get agencies by organization
   * @param {number} organizationId
   * @param {string} query
   * @returns {Promise<any | never>}
   */
  async agencies(organizationId, query = "") {
    const params = {};
    if (query) {
      params.agency_name = query;
    }
    return await this.api_server.get(T1Service.URI_STATES.get("t1.agencies").format(organizationId), params);
  }

  /**
   * Get campaigns by advertiser
   * @param {number} advertiserId
   * @param {string} query
   * @returns {Promise<any | never>}
   */
  async campaigns(advertiserId, query = "") {
    const params = {};
    if (query) {
      params.campaign_name = query;
    }

    return await this.api_server.get(T1Service.URI_STATES.get("t1.campaigns").format(advertiserId), params);
  }

  /**
   * Get strategies by campaign
   * @param {number} campaignId
   * @param {string} query
   * @returns {Promise<any | never>}
   */
  async strategies(campaignId, query = "") {
    const params = {};
    if (query) {
      params.strategy_name = query;
    }

    return await this.api_server.get(T1Service.URI_STATES.get("t1.strategies").format(campaignId), params);
  }

  /**
   * Get advertisers by agency
   * @param {number} agencyId
   * @param {string} query
   * @returns {Promise<any | never>}
   */
  async advertisers(agencyId, query = "") {
    const params = {};
    if (query) {
      params.advertiser_name = query;
    }

    return await this.api_server.get(T1Service.URI_STATES.get("t1.advertisers").format(agencyId), params);
  }

  /**
   * Get campaigns by agency
   * @param {number} agencyId
   * @param {string} query
   * @returns {Promise<any | never>}
   */
  async campaignsData(agencyId, advertiserId = null) {
    const params = {};
    if (advertiserId) {
      params.advertiser_id = advertiserId;
    }

    return await this.api_server.get(T1Service.URI_STATES.get("t1.campaigns_data").format(agencyId), params);
  }
}
