import { addDays, addHours, isAfter, isBefore, setHours, setMinutes } from "date-fns";
import omit from "lodash/omit";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { Button, Checkbox, Divider, Form, Input, Message, Radio, Select, TextArea } from "semantic-ui-react";
import "./styles.css";
import { Config } from "../../../config/api";
import { errorToMessage, formatAPIDateTimeInUTC } from "../../../libs/common_utils";
import { useForm } from "../../../libs/component_utils";
import DealService from "../../../services/inventory/deals";
import OrganizationPicker from "../../common/organization-picker";
import useDealForm from "./hooks";

const service = new DealService();
const DEALS_API_FORM_KEY_VALUES = {
  advertiserIds: "advertiser_ids",
  agencyIds: "agency_ids",
  channel: "channels",
  currency: "currency",
  dealDescription: "description",
  dealId: "deal_id",
  dealName: "title",
  dealPrice: "price",
  dealType: "deal_type",
  endDate: "end_date",
  exchange: "supply_source_id",
  priceMethod: "price_method",
  priceType: "price_type",
  publisher: "publisher_id",
  startDate: "start_date",
  status: "status",
};

const prepareValues = (values) => {
  const permissionApiRef = {
    advertiserIds: "advertiser_ids",
    agencyIds: "agency_ids",
    organizationIds: "organization_ids",
  };
  const preparedValues = {
    [DEALS_API_FORM_KEY_VALUES.priceMethod]: "CPM",
  };

  preparedValues.permissions = {};
  if (values.permissions) {
    preparedValues.permissions = values.permissions;
  }
  preparedValues.permissions[permissionApiRef.organizationIds] = [];

  for (const [key, value] of Object.entries(DEALS_API_FORM_KEY_VALUES)) {
    if (values[key] === undefined) continue;

    switch (key) {
      case "startDate": {
        preparedValues[value] = formatAPIDateTimeInUTC(values[key]);
        break;
      }
      case "endDate": {
        preparedValues[value] = values.neverEnds
          ? setHours(setMinutes(new Date().setFullYear(2999, 11, 31), 0), 0)
          : values[key];

        preparedValues[value] = formatAPIDateTimeInUTC(preparedValues[value]);
        break;
      }
      case "dealPrice": {
        preparedValues[value] = Number(values[key]);
        break;
      }
      case "status": {
        preparedValues[value] = values[key] ? 1 : 0;
        break;
      }
      case "agencyIds": {
        preparedValues.permissions[permissionApiRef.agencyIds] = values.agencyIds;
        break;
      }
      case "advertiserIds": {
        preparedValues.permissions[permissionApiRef.advertiserIds] = values.advertiserIds;
        break;
      }
      default: {
        if (values[key] !== undefined) {
          preparedValues[value] = values[key];
        }
      }
    }
  }

  return preparedValues;
};

const handleSameDateSelection = (startDate) => {
  const todayWithoutTime = new Date().setHours(0, 0, 0, 0);
  const startDateWithoutTime = new Date(startDate).setHours(0, 0, 0, 0);

  if (todayWithoutTime === startDateWithoutTime) {
    return addHours(new Date(), 1);
  }

  return startDate.setHours(0, 0, 0, 0);
};

const getNewEndDate = (startDate) => {
  const today = new Date();
  const newEndDate = isAfter(startDate, today) ? new Date(startDate) : today;
  newEndDate.setHours(0, 0, 0, 0);

  return addDays(newEndDate, 1);
};

const DealForm = ({
  initialData,
  onSubmit,
  onCancel,
  submitButtonLabel = "Create Site List",
  formType = "create",
  initialIndeterminateAgencies = [],
}) => {
  const intl = useIntl();
  const [serverError, setServerError] = useState("");
  const [formSending, setFormSending] = useState(false);
  const { isLoading, isMounted, currencies, formRules, publishers, dealChannels, supplySources } = useDealForm({
    intl,
    onError: setServerError,
  });

  const prepareToCreate = async (values) => {
    let jsonValues = prepareValues(values);

    if (jsonValues[DEALS_API_FORM_KEY_VALUES.dealDescription] === "") {
      jsonValues = omit(jsonValues, DEALS_API_FORM_KEY_VALUES.dealDescription);
    }
    if (
      jsonValues[DEALS_API_FORM_KEY_VALUES.publisher] === null ||
      jsonValues[DEALS_API_FORM_KEY_VALUES.publisher] === ""
    ) {
      jsonValues = omit(jsonValues, DEALS_API_FORM_KEY_VALUES.publisher);
    }

    await service.create(jsonValues);
  };
  const filterStartPassedTime = (time) => {
    const selectedDate = new Date(time);
    return new Date().getTime() < selectedDate.getTime();
  };

  const filterEndPassedTime = (time) => {
    const selectedDate = new Date(time);
    return values.startDate.getTime() < selectedDate.getTime();
  };

  const prepareToEdit = async (values) => {
    let jsonValues = prepareValues(values);
    if (Boolean(jsonValues[DEALS_API_FORM_KEY_VALUES.publisher]) === false) {
      jsonValues = omit(jsonValues, DEALS_API_FORM_KEY_VALUES.publisher);
    }
    await service.update(initialData.id, jsonValues);
  };

  /**
   * Hadle Deal save data from the form
   * @return {Promise<void>}
   */
  const handleSaveForm = async () => {
    try {
      setFormSending(true);

      if (formType === "create") {
        await prepareToCreate(values);
      }
      if (formType === "edit" && initialData.id) {
        await prepareToEdit(values);
      }

      onSubmit();
    } catch (error) {
      console.error("DealForm Save Error :>> ", error);
      setServerError(error.error.message);
    } finally {
      if (isMounted) {
        setFormSending(false);
      }
    }
  };

  const postValidation = (_, fields) => {
    const newErrors = {};
    for (const field of Object.keys(formRules)) {
      const error = formRules[field](fields[field], fields);
      if (error) {
        newErrors[field] = error;
      }
    }
    return newErrors;
  };

  const {
    errors,
    onChange,
    onSubmit: handleSubmit,
    onSwitch,
    setErrors,
    updateValues,
    values,
  } = useForm(handleSaveForm, initialData, postValidation);

  return (
    <>
      <Message
        content={serverError}
        error
        hidden={!serverError}
        size="tiny"
        style={{ marginTop: "10px" }}
      />
      <Form
        autoComplete="off"
        error={Boolean(Object.keys(errors).length)}
        loading={isLoading || formSending}
        noValidate
        onSubmit={handleSubmit}
        size="small"
      >
        <Form.Field
          error={errors.status}
          inline
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_STATUS",
              defaultMessage: "Status",
            })}
          </label>
          <div className="ui input multi-fields">
            <Radio
              checked={Boolean(values.status)}
              label={intl.formatMessage({
                id: "STATUS_ACTIVE",
                defaultMessage: "Active",
              })}
              name="status"
              onChange={onChange}
              value={1}
            />
            <Radio
              checked={!values.status}
              label={intl.formatMessage({
                id: "STATUS_INACTIVE",
                defaultMessage: "Inactive",
              })}
              name="status"
              onChange={onChange}
              value={0}
            />
          </div>
        </Form.Field>

        <Form.Field
          error={errors.dealName}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_NAME",
              defaultMessage: "Deal Name",
            })}
          </label>
          <Input
            className="input-field-wider"
            name="dealName"
            onChange={onChange}
            value={values.dealName}
          />
          {errors.dealName && <div className="custom-error">{errors.dealName}</div>}
        </Form.Field>

        <Form.Field
          error={errors.dealDescription}
          inline
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_DESCRIPTION",
              defaultMessage: "Description",
            })}
          </label>
          <div className="ui input input-field-wider">
            <TextArea
              name="dealDescription"
              onChange={onChange}
              rows="2"
              value={values.dealDescription}
            />
          </div>
          {errors.dealDescription && <div className="custom-error">{errors.dealDescription}</div>}
        </Form.Field>

        <Form.Field
          error={errors.dealId}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_ID",
              defaultMessage: "Deal ID",
            })}
          </label>
          <Input
            className="input-field-wider"
            name="dealId"
            onChange={onChange}
            value={values.dealId}
          />
          <div className="custom-error">{errors.dealId}</div>
          {errors.dealId && <div className="custom-error">{errors.dealId}</div>}
        </Form.Field>

        <Form.Field
          error={errors.dealType}
          inline
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_TYPE",
              defaultMessage: "Deal Type",
            })}
          </label>
          <div className="ui input multi-fields">
            <Radio
              checked={values.dealType === "PMP"}
              label="PMP"
              name="dealType"
              onChange={onChange}
              value="PMP"
            />
            <Radio
              checked={values.dealType === "PG"}
              label="PG"
              name="dealType"
              onChange={(e, input) => {
                if (input.checked) {
                  updateValues({
                    ...values,
                    dealType: "PG",
                    neverEnds: false,
                    endDate: getNewEndDate(values.startDate),
                    priceType: "FIXED",
                  });
                } else {
                  onChange(e, input);
                }
              }}
              value="PG"
            />
          </div>
          {errors.dealType && <div className="custom-error">{errors.dealType}</div>}
        </Form.Field>

        <Form.Field
          error={Boolean(errors.endDate || errors.startDate)}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_START_END_DATE",
              defaultMessage: "Start/End Date",
            })}
          </label>
          <div className="ui input multi-fields">
            <DatePicker
              dateFormat={Config.dateTimeFormat}
              disabled={values.id && isBefore(values.startDate, new Date())}
              filterTime={filterStartPassedTime}
              minDate={addHours(new Date(), 1)}
              onChange={(date) => {
                const modifiedStartDate = handleSameDateSelection(date);
                const toUpdate = { ...values, startDate: modifiedStartDate };
                if (isBefore(values.endDate, date) && !values.neverEnds) {
                  toUpdate.endDate = getNewEndDate(date);
                }
                updateValues(toUpdate);
              }}
              selected={values.startDate}
            />
            <DatePicker
              dateFormat={Config.dateTimeFormat}
              disabled={Boolean(values.neverEnds)}
              filterTime={filterEndPassedTime}
              minDate={values.startDate}
              onChange={(date) => {
                updateValues({ ...values, endDate: date });
              }}
              selected={values.neverEnds ? "" : values.endDate}
            />
            <Checkbox
              checked={Boolean(values.neverEnds)}
              disabled={values.dealType === "PG"}
              label="Never ends"
              name="neverEnds"
              onClick={(e, input) => {
                if (values.dealType !== "PG") {
                  if (input.checked) {
                    onSwitch(e, input);
                  } else {
                    updateValues({ ...values, neverEnds: false, endDate: getNewEndDate(values.startDate) });
                  }
                }
              }}
              value={1}
            />
          </div>
          {errors.startDate && <div className="custom-error">{errors.startDate}</div>}
          {errors.endDate && <div className="custom-error">{errors.endDate}</div>}
        </Form.Field>

        <Form.Field
          error={Boolean(errors.priceType)}
          inline
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_PRICE_TYPE",
              defaultMessage: "Price Type",
            })}
          </label>
          <div className="ui input multi-fields">
            <Radio
              checked={values.priceType === "FLOOR"}
              disabled={values.dealType === "PG"}
              label="Floor"
              name="priceType"
              onChange={onChange}
              value="FLOOR"
            />
            <Radio
              checked={values.priceType === "FIXED"}
              label="Fixed"
              name="priceType"
              onChange={onChange}
              value="FIXED"
            />
          </div>
        </Form.Field>

        <Form.Field
          error={errors.dealPrice}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_DEAL_PRICE",
              defaultMessage: "Deal Price (CPM)",
            })}
          </label>
          <div className="ui input multi-fields">
            <Select
              clearable
              loading={isLoading}
              name="currency"
              onChange={onChange}
              options={currencies}
              placeholder={intl.formatMessage({
                id: "PLACEHOLDER_SELECT_CURRENCY",
                defaultMessage: "Select Currency",
              })}
              search
              selection
              value={values.currency}
            />

            <Input
              name="dealPrice"
              onChange={onChange}
              placeholder="0.00"
              type="number"
              value={values.dealPrice}
            />
          </div>
          {errors.dealPrice && <div className="custom-error">{errors.dealPrice}</div>}
        </Form.Field>

        <Form.Field
          error={errors.exchange}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_EXCHANGE",
              defaultMessage: "Exchange",
            })}
          </label>
          <Select
            clearable
            loading={isLoading}
            name="exchange"
            onChange={onChange}
            options={supplySources}
            placeholder="Select Exchange"
            search
            selection
            value={values.exchange}
          />
          {errors.exchange && <div className="custom-error">{errors.exchange}</div>}
        </Form.Field>

        <Form.Field inline>
          <label>
            {intl.formatMessage({
              id: "LABEL_PUBLISHER",
              defaultMessage: "Publisher",
            })}
          </label>
          <Select
            clearable={(formType === "edit" && initialData.publisher === null) || formType === "create"}
            loading={isLoading}
            name="publisher"
            onChange={onChange}
            options={publishers}
            placeholder="Select Publisher"
            search
            selection
            value={values.publisher}
          />
        </Form.Field>

        <Form.Field
          error={Boolean(errors.channel)}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              id: "LABEL_CHANNEL",
              defaultMessage: "Channel",
            })}
          </label>
          <Select
            loading={isLoading}
            multiple
            name="channel"
            onChange={onChange}
            options={dealChannels}
            placeholder="Select Channel"
            value={values.channel}
          />
          {errors.channel && <div className="custom-error">{errors.channel}</div>}
        </Form.Field>

        <Form.Field inline>
          <label>
            {intl.formatMessage({
              id: "LABEL_PERMISSIONS",
              defaultMessage: "Permissions",
            })}
          </label>

          <OrganizationPicker
            onChange={updateValues}
            // these will ge passed from backend on edit page, always empty on create page:
            initialIndeterminateAgencies={initialIndeterminateAgencies}
            onError={(error) =>
              setErrors({
                ...errors,
                agencyIds: errorToMessage(error) || "Unknown error fetching data from server",
              })
            }
            preventAdvertisers
            refKeys={{
              agencyIds: "agencyIds",
              advertiserIds: "advertiserIds",
            }}
            values={values}
          />
        </Form.Field>

        <Divider hidden />
        <Form.Field
          align="right"
          className="create-controls"
        >
          <Button
            onClick={() => onCancel(values)}
            size="tiny"
            type="button"
          >
            {intl.formatMessage({
              id: "BTN_CANCEL",
              defaultMessage: "Cancel",
            })}
          </Button>
          <Button
            className="create"
            disabled={formSending}
            onClick={handleSubmit}
            size="tiny"
            type="button"
          >
            {submitButtonLabel}
          </Button>
        </Form.Field>
      </Form>
    </>
  );
};

export default DealForm;
