import React, { useCallback, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Divider, Header, Message, Segment } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";

import AudiencesService from "../../../services/audiences/index.js";
import AudienceForm from "../form";

/**
 * Audience edit page component
 * @param {object} history
 * @param {object} match
 * @param {object} match.params
 * @param {number} match.params.audience_id
 * @return {*}
 * @constructor
 */
const AudienceEditPage = ({ history, match }) => {
  const backUrl = "/audiences";
  const service = new AudiencesService();
  const audienceId = Number.parseInt(match.params.audience_id, 10) || 0;
  const _isMounted = React.useRef(false);
  const intl = useIntl();

  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);

  /**
   * navigate user back
   */
  const handleCancel = useCallback(() => {
    history.push(backUrl);
  }, [history]);

  /**
   * handle 404
   */
  const handle404 = () => {
    history.push(backUrl, { action: "error", msg: "No audience found" });
  };

  /**
   * form submit handler
   * @return {boolean}
   */
  const handleSubmit = async (values, file) => {
    setLoading(true);

    // get object that contain only updated values
    const diff = Object.keys(initialObject.current).reduce((diff, key) => {
      if (values[key] === initialObject.current[key]) return diff;
      diff[key] = values[key];
      return diff;
    }, {});

    const form = new FormData();
    for (const k of Object.keys(diff)) {
      form.append(k, diff[k]);
    }
    if (diff?.status) {
      form.set("status", values.status ? "1" : "0");
    }

    if (file) {
      form.append("segment_file", file);
    }

    try {
      await service.update(audienceId, form);
      history.push(backUrl, { action: "created" });
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if (_isMounted.current) {
        setLoading(false);
      }
    }
  };

  if (audienceId < 1) {
    handle404();
  }

  const [initialData, setInitialData] = useState(null);
  const initialObject = React.useRef(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    _isMounted.current = true;
    (async () => {
      try {
        const r = await service.get(audienceId);
        initialObject.current = { ...r.data };
        setInitialData(r.data);
      } catch (_e) {
        handle404();
      }
    })();

    return () => {
      _isMounted.current = false;
    };
  }, [audienceId]);

  if (initialData === null) {
    return (
      <Segment
        disabled
        tertiary
        textAlign="center"
        className="loading"
      >
        &nbsp;
      </Segment>
    );
  }

  return (
    <Segment basic>
      <Header as="h2">
        {intl.formatMessage({
          id: "HEADING_UPLOAD_DEVICE_ID",
          defaultMessage: "Upload Device ID",
        })}
      </Header>
      <Divider />
      <Message
        style={{ marginTop: "10px" }}
        error
        hidden={!serverError}
        size="tiny"
        content={serverError}
      />
      <AudienceForm
        initialData={initialData}
        formType="edit"
        loading={loading}
        submitText={intl.formatMessage({
          id: "BTN_SAVE_CHANGES",
          defaultMessage: "Save Changes",
        })}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Segment>
  );
};

export default AudienceEditPage;
