import { createBrowserHistory } from "history";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { Message, Modal, Transition } from "semantic-ui-react";
import AdblockWarningModal from "./components/app/AdblockWarningModal";
import Pendo from "./components/app/Pendo";
import PrivateRoute from "./components/app/PrivateRoute";
import RouteFallback from "./components/app/RouteFallback";
import LocaleProvider from "./components/locale/provider";
import UserProfilePage from "./components/user/profile";
import { Config } from "./config/api";
import { OnlineContext } from "./context/online-context";
import AuthController from "./controllers/auth";
import { isAdmin, isInternal, isNil, isReporter } from "./libs/common_utils";
import HttpConnect from "./libs/http_connect";
import Registry from "./libs/register_storage";
import { useAuth0 } from "./reactAuth0Wrapper";
import { Routes as adminRoutes } from "./routes/roles/admin";
import { Routes as userRoutes } from "./routes/roles/index";
import { Routes as reporterRoutes } from "./routes/roles/reporter";
import AgenciesService from "./services/agencies";

// TODO: refactor into utility function. Don't change native objects, EVER!
String.prototype.format = function (...args) {
  if (!this.match(/^(?:(?:(?:[^{}]|(?:{{)|(?:}}))+)|(?:{[0-9]+}))+$/)) {
    throw new Error("invalid format string.");
  }
  return this.replace(/((?:[^{}]|(?:{{)|(?:}}))+)|(?:{([0-9]+)})/g, (_, str, index) => {
    if (str) {
      return str.replace(/(?:{{)|(?:}})/g, (m) => m[0]);
    }

    if (index >= args.length) {
      throw new Error("argument index is out of range in format");
    }
    return args[index];
  });
};

// TODO: refactor into utility function. Don't change native objects, EVER!
Map.prototype.switch = function (id, item) {
  if (this.has(id)) {
    this.delete(id);
  } else {
    this.set(id, item);
  }
};

const agenciesService = new AgenciesService();
const msgStyle = { width: "350px", marginTop: 0, right: "0", position: "fixed", top: "0", zIndex: 1000 };

const App = () => {
  const storage = new Registry(window.localStorage);
  const [online, setOnline] = useState(storage.hasItem("token"));
  const [serverError, setServerError] = useState();
  const [criticalError, setCriticalError] = useState(false);
  const [agencyPickerOpen, setAgencyPickerOpen] = useState(false);
  const authController = new AuthController();
  const t1UserId = storage.getItem("user_id");
  const { loading: auth0Loading, logout: auth0Logout, isAuthenticated, signingOut, getIdTokenClaims } = useAuth0();
  const isPendoEnabled = Boolean(online && t1UserId && process.env.REACT_APP_PENDO_API_KEY);
  const isLogoutURI = () => Boolean(window.location.href.includes("/logout"));
  const user = useMemo(() => {
    return { role: storage.getItem("user_role"), type: storage.getItem("user_type") };
  }, [storage.getItem]);
  const isReport = online ? isReporter(user) : false;
  const agency = agenciesService.getSelectedAgency() || 0;
  const agencyTitle = agenciesService.getSelectedAgencyTitle();
  const currentUserIsAdmin = isAdmin(user);
  const flags = useFlags();

  const routeMap = useMemo(() => {
    if (currentUserIsAdmin) return adminRoutes(flags);
    if (isReport) return reporterRoutes();
    return userRoutes(flags);
  }, [currentUserIsAdmin, flags, isReport]);

  /**
   * register values in storage
   * @param {string} token
   * @param {string} username
   * @param {string} avatar
   */
  const doLogin = (token, username, avatar) => {
    storage.setItem("token", token);
    storage.setItem("username", username);
    storage.setItem("avatar", avatar);
    setOnline(true);
  };

  /**
   * log user out
   */
  const doLogout = useCallback(async () => {
    await auth0Logout();

    try {
      authController.cleanSession();
      await authController.signout();
    } catch (err) {
      console.error(err);
    }
  }, [authController, auth0Logout]);

  const resetError = useCallback(() => {
    setServerError(null);
  }, []);

  // intercept server errors
  HttpConnect.instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // for unauthorized requests use specific handler
      if (
        error.response &&
        [401].includes(error.response.status) &&
        !["/login", "/logout"].includes(window.location.href)
      ) {
        doLogout();
        return Promise.reject(error);
      }

      // in case we showed ads popup do not show additional message
      if (isNil(window.canRunAds)) {
        return Promise.reject(error);
      }

      if (error.message.toLowerCase().includes("network error")) {
        setCriticalError(true);
      } else if (error.message.toLowerCase().includes("timeout of ") || [500].includes(error.response?.status)) {
        setServerError("Something wrong with server API...");
      }

      return Promise.reject(error);
    },
  );

  const navigateToLogin = () => {
    window.location.replace(`${Config.basename}/login`.replace(/\/{2,}/, "/"));
  };

  /**
   * checking for auth0 valid token
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on auth0Loading, isAuthenticated change
  useEffect(() => {
    if (online || isLogoutURI() || signingOut) {
      return;
    }

    if (!(online || auth0Loading) && isAuthenticated) {
      (async () => {
        const claims = await getIdTokenClaims();
        doLogin(claims.__raw, claims.nickname, claims.picture);
      })();
    } else if (!(online && (auth0Loading || isAuthenticated))) {
      navigateToLogin();
    }
  }, [auth0Loading, isAuthenticated]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on user update
  useEffect(() => {
    if (!(user.role && user.type)) {
      if (!window.location.pathname.endsWith("login")) {
        // This is a hack to clear the localStorage and redirect to login page
        // The reason is that this file is included on every page, otherwise we would be stuck in a loop
        localStorage.clear();
        navigateToLogin();
      }
    }
  }, [user]);

  return (
    <LocaleProvider>
      {isPendoEnabled && (
        <Pendo
          agency={agency}
          is_internal={isInternal(user)}
          organization={storage.getItem("organization")}
          t1_user_id={t1UserId}
        />
      )}
      <OnlineContext.Provider
        value={{
          agency,
          agencyPickerOpen,
          agencyTitle,
          currentUserIsAdmin,
          doLogout,
          internal: isInternal(user),
          isAdmin: isAdmin,
          isReporter: isReport,
          online,
          setAgencyPickerOpen,
          t1UserId,
          username: storage.getItem("username"),
        }}
      >
        <Modal
          className="auth0_modal"
          dimmer="blurring"
          open={criticalError}
        >
          <Modal.Header>API service temporary unavailable. Please try to refresh page...</Modal.Header>
        </Modal>
        {isNil(window.canRunAds) && <AdblockWarningModal />}
        <Transition
          animation="scale"
          duration={500}
          onComplete={() => setTimeout(resetError, 5000)}
          visible={!_.isNil(serverError)}
        >
          <Message
            error
            onClick={resetError}
            onDismiss={resetError}
            style={msgStyle}
          >
            {serverError}
          </Message>
        </Transition>
        <div className="main-container">
          <CustomRouter basename={Config.basename}>
            <Suspense fallback={RouteFallback}>
              <Switch>
                <Route
                  exact={true}
                  path="/logout"
                  render={() => {
                    doLogout();
                    return "";
                  }}
                />
                {routeMap.map((route) => {
                  return (
                    <PrivateRoute
                      exact={true}
                      key={route.path}
                      {...route}
                    />
                  );
                })}
                <PrivateRoute
                  component={UserProfilePage}
                  path="/profile"
                  title={["Edit Profile"]}
                />
                {isReport ? (
                  <Redirect
                    from="*"
                    title={["Reports"]}
                    to="/reports"
                  />
                ) : (
                  <Redirect
                    from="*"
                    title={["Campaigns"]}
                    to="/campaigns"
                  />
                )}
              </Switch>
            </Suspense>
          </CustomRouter>
        </div>
      </OnlineContext.Provider>
    </LocaleProvider>
  );
};

/**
 * Custom router that cancels all requests before the navigation.
 * @see https://github.com/ReactTraining/react-router/issues/6525
 */
class CustomRouter extends React.Component {
  constructor(props) {
    super();
    this.history = createBrowserHistory(props);
    this.unlisten = this.history.listen(() => {
      HttpConnect.cancelRequest();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return <Router history={this.history}>{this.props.children}</Router>;
  }
}

export default App;
