/* eslint-disable jsx-a11y/anchor-is-valid */
import { parse } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import React, { useCallback, useReducer, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
	Button,
	Checkbox,
	Divider,
	Grid,
	Header,
	Icon,
	List,
	Message,
	Modal,
	Pagination,
	Popup,
	Segment,
	Select,
	Table,
} from "semantic-ui-react";
import uuid from "uuid/v4";

import { NavLink } from "react-router-dom";
import { Config } from "../../../config/api";
import { LangContext } from "../../../context";
import {
	currencySymbol,
	formatMoney,
	getApiDate,
	getCampaignTypeBasedOnLD,
	isNil,
} from "../../../libs/common_utils";
import { onBannerDismiss } from "../../../libs/component_utils";
import Registry from "../../../libs/register_storage";
import Pager from "../../../models/pager";
import CommonService from "../../../services/common";
import ReportService from "../../../services/reports";
import StrategiesService from "../../../services/strategy";
import { graph_metrics } from "../../campaign/fixtures";
import ChartDiv from "../../common/chart";
import GridEmptyRow from "../../common/gridEmptyRow";
import StrategyGridItem from "../common/strategy_grid_item";
import StrategyGridContext from "./context";
import FilterControl from "./filter_control";
import Filter from "./models/filter";
import { strategiesActions as sA, strategiesGridReducer } from "./reducers";
import { StrategiesBulkEditModal } from "../edit/strategies_bulk_edit_modal";

const initialState = {
	strategies: [],
	campaign: {},
	pager: new Pager(),
	filter: new Filter(),
};

const default_metrics = {
	METRIC_1: "total_spend",
	METRIC_2: "impressions",
};

export const StrategiesPage = ({ match, history }) => {
	const campaign_id = match.params.campaign_id;

	const intl = useIntl();
	const [state, dispatch] = useReducer(strategiesGridReducer, initialState);
	const [commonData, setCommonData] = useState({
		currencies: [],
	});

	const [graph, setGraph] = React.useState({
		data: [],
		loading: false,
	});
	const [graph_filter, setGraphFilter] = React.useState({
		metric_1: default_metrics.METRIC_1,
		metric_2: default_metrics.METRIC_2,
	});

	const [gridLoading, setGridLoading] = useState(true);
	const [strategySelected, setStrategySelected] = React.useState({});
	const [strategyForDuplication, setStrategyForDuplication] =
		React.useState(null);
	const [duplicatedStrategies, setDuplicatedStrategies] = React.useState([]);
	const [duplicatedStrategiesFailures, setDuplicatedStrategiesFailures] =
		React.useState([]);
  const [selectedStrategies, setSelectedStrategies] = React.useState([]);
  const [isBulkEditModalOpen, setIsBulkEditModalOpen] = React.useState(false);
	const timestamp = React.useRef(0);
	const showSuccessMessage = !isNil(history.location.state);
  const [bulkEditResponseMessage, setBulkEditResponseMessage] = useState({successMessage: [], errorMessage: []});
  const [showBulkEditResponseMessage, setShowBulkEditResponseMessage] = useState(false);
	const isCampaignWasSaved =
		!isNil(history.location.state) &&
		history.location.state.type === "campaign";

	let _isMounted = React.useRef(false);
	const services = React.useRef(
		new Map([
			["strategies", new StrategiesService()],
			["common", new CommonService()],
			["report", new ReportService()],
		])
	);
	const campaign_type = React.useRef("");
	const {
		whitelabelSupplyPmpFlag,
		whitelabelSupplyAudienceFlag,
		whitelabelSupplyOpenFlag,
	} = useFlags();
	const lang_context = React.useContext(LangContext);

	const isStrategySelected = () => Object.keys(strategySelected).length > 0;

	/**
	 * initial load strategies
	 */
	React.useEffect(() => {
		state.filter.reset();
		state.pager.reset();

		_isMounted.current = true;

		(async () => {
			const [, currencies] = await Promise.all([
				getStrategies(state.filter, state.pager, true),
				services.current.get("common").getCurrencies(),
			]);

			setCommonData({
				...commonData,
				currencies: currencies.data,
			});
		})();

		// clear cache
		return () => {
			state.filter.reset();
			state.pager.reset();
			_isMounted.current = false;
		};
	}, [campaign_id]);

	/**
	 * get graph data for strategy being selected
	 */
	React.useEffect(() => {
		if (!strategySelected.id) {
			return;
		}

		window.scrollTo(0, 0);
		(async () => {
			try {
				setGraph({
					data: [],
					loading: true,
				});
				const params = Object.assign(
					{
						id: strategySelected.id,
						m1: graph_filter.metric_1,
						m2: graph_filter.metric_2,
					},
					state.filter.toGraphJson()
				);

				const r = await services.current.get("report").strategyGraph(params);

				/** @namespace {object} r.data **/
				let data2display = r.data.graph_data.map((x) => ({
					...x,
					date: parse(x.date, "yyyy-MM-dd", new Date()).valueOf(),
				}));
				data2display = data2display.sort((a, b) => a.date - b.date);
				timestamp.current = Date.now();
				setGraph({
					...graph,
					data: data2display,
					loading: false,
				});
			} catch (e) {
				console.error(e);
				setGraph({
					...graph,
					loading: false,
				});
			}
		})();
	}, [strategySelected, graph_filter, state.filter.start_date]);

	const sortStrategies = useCallback((sort_by, order) => {
		console.log(sort_by, order);
	}, []);

	/**
	 * open chart
	 */
	const openChartAccordion = () => {
		document.getElementById("chart_swap_id").setAttribute("open", "true");
	};

	/**
	 * get page
	 * @param e
	 * @param activePage
	 */
	const getPage = (e, { activePage }) => {
		state.pager.setPage(activePage);
		getStrategies(state.filter, state.pager).then(() => console.log);
	};

	/**
	 * load strategies from API
	 * @param {object} filter
	 * @param {object} pager
	 * @param {boolean} initial_load
	 * @return {Promise<void>}
	 */
	const getStrategies = async (filter, pager, initial_load = false) => {
		const strategies_service = services.current.get("strategies");
		try {
			setGridLoading(true);

			let params = pager.toJson();
			if (filter) {
				params = Object.assign(params, filter.toRequestJson());
			}

			const r = await strategies_service.list(campaign_id, params);
			let meta = r.meta;
			meta.page = pager.page;

			if (initial_load && Boolean(r.data.strategies.length)) {
				setStrategySelected(r.data.strategies[0]);
			}

			if (r.data["type"].toLowerCase() === "pg") {
				campaign_type.current = "Programmatic Guaranteed";
			} else {
				const non_pg_type = getCampaignTypeBasedOnLD(
					whitelabelSupplyPmpFlag,
					whitelabelSupplyAudienceFlag,
					whitelabelSupplyOpenFlag,
					lang_context
				);
				campaign_type.current = non_pg_type || r.data["type"];
			}

			dispatch({ type: sA.INIT, data: r.data, pager: meta });
		} catch (e) {
			// ignore error
			console.log(e);
		} finally {
			if (_isMounted.current) {
				setGridLoading(false);
			}
		}
	};

	const duplicateStrategy = async (item) => {
		const strategies_service = services.current.get("strategies");
		strategies_service
			.duplicate(item["id"])
			.then(() => {
				setDuplicatedStrategies([...duplicatedStrategies, item]);
				getStrategies(state.filter, state.pager);
			})
			.catch((e) => {
				setDuplicatedStrategiesFailures([...duplicatedStrategies, {...item, errorMessage: e.error?.message ? e.error?.message : "" }]);
			});
	};

	/**
	 * filter strategies
	 * @param {object} json
	 */
	const filterStrategies = (json) => {
		state.pager.reset();
		state.filter.fromJson(json);
		getStrategies(state.filter, state.pager).then(() => console.log);
	};

	/**
	 * navigate user to create campaign page
	 */
	const navigateToCreatePage = () => {
		history.push(`/campaign/${campaign_id}/strategies/create`);
	};

	/**
	 * navigate user to edit page
	 * @param {number} strategy_id
	 */
	const navigateToEditPage = (strategy_id) => {
		history.push(`/campaign/${campaign_id}/strategies/edit/${strategy_id}`, {});
	};

	/**
	 * get edit page URL by strategy id
	 */
	const getEditPageHref = useCallback(
		(strategy_id) => `./strategies/edit/${strategy_id}`,
		[]
	);

	/**
	 * Update filter metrics
	 * @param e
	 * @param {string} name
	 * @param {string} value
	 */
	const onChangeMetric = (e, { name, value }) => {
		setGraphFilter({
			...graph_filter,
			[name]: value,
		});
	};

  useEffect(() => {
    setShowBulkEditResponseMessage(bulkEditResponseMessage['successMessage'].length > 0 || bulkEditResponseMessage['errorMessage'].length > 0);
    const timer = setTimeout(handleBannerDismiss, 10000);
    return () => clearTimeout(timer);
  }, [bulkEditResponseMessage]);

  const handleBannerDismiss = useCallback(() => {
    setBulkEditResponseMessage({ successMessage: [], errorMessage: [] });
  }, [setBulkEditResponseMessage]);

	return (
		<Segment loading={gridLoading} basic style={{ padding: "0" }}>
			<StrategyGridContext.Provider
				value={{
					sorting: sortStrategies,
					campaign: state.campaign,
					getPage,
					filtering: filterStrategies,
					navigateToCreatePage,
					navigateToEditPage,
					getEditPageHref,
					currency: currencySymbol(
						state.campaign.currency_code,
						commonData.currencies
					),
					setStrategySelected,
					openChartAccordion,
					strategyForDuplication,
					setStrategyForDuplication,
					duplicateStrategy,
          selectedStrategies,
          setSelectedStrategies,
          isBulkEditModalOpen,
          setIsBulkEditModalOpen,
          dispatch,
          setBulkEditResponseMessage
				}}
			>
				{showSuccessMessage &&
					(isCampaignWasSaved ? (
						<Message
							success
							className="page-success-message"
							attached
							onDismiss={onBannerDismiss}
						>
							{intl.formatMessage({
								id: "MESSAGE_CAMPAIGN_CREATED",
								defaultMessage: "Campaign successfully created",
							})}
						</Message>
					) : (
						<StrategiesSuccessMessage details={history.location.state || {}} />
					))}
          {showBulkEditResponseMessage &&
            <>
              <Message
                  success
                  hidden={bulkEditResponseMessage['successMessage'].length === 0}
                  onDismiss={handleBannerDismiss}>
                    <div>
                      {intl.formatMessage({
                        id: "MESSAGE_STRATEGY_BULK_EDIT_UPDATED",
                        defaultMessage: "Strategies successfully updated"
                      })}
                      <ul>
                        {bulkEditResponseMessage['successMessage'].map((strategy) => (
                          <li key={strategy.id}>{strategy.name}</li>
                        ))}
                      </ul>
                    </div>
                </Message>
                <Message
                  error
                  hidden={bulkEditResponseMessage['errorMessage'].length === 0}
                  onDismiss={handleBannerDismiss}>
                  <div>
                    {intl.formatMessage({
                      id: "MESSAGE_STRATEGY_BULK_EDIT_NOT_UPDATED",
                      defaultMessage: "These strategies were not updated"
                    })}
                    <ul>
                      {bulkEditResponseMessage['errorMessage'].map((strategy) => (
                        <li key={uuid()}>{strategy.message}</li>
                      ))}
                    </ul>
                  </div>
                </Message>
            </>
          }
				{duplicatedStrategies.length > 0 && (
					<Message success onDismiss={() => setDuplicatedStrategies([])}>
						{intl.formatMessage(
							{
								id: "SUCCESSFUL_DUPLICATION_MSG",
								defaultMessage: `Successfully duplicated: ${duplicatedStrategies
									.map((item) => item.title)
									.join(", ")}`,
							},
							{
								title: duplicatedStrategies
									.map((item) => item.title)
									.join(", "),
							}
						)}
					</Message>
				)}
				{duplicatedStrategiesFailures.length > 0 && (
					<Message
						negative
						onDismiss={() => setDuplicatedStrategiesFailures([])}
					>
						{intl.formatMessage(
							{
								id: "FAILED_DUPLICATION_MSG",
								defaultMessage: `Failed duplicating: ${duplicatedStrategiesFailures
									.map((item) => item.title)
									.join(", ")}`,
							},
							{
								title: duplicatedStrategiesFailures
									.map((item) => item.title)
									.join(", "),
							}
						)}
						{duplicatedStrategiesFailures.map((item, index) => <span key={`message-${index}`} className="error-message-child">{`${intl.formatMessage(
							{
								id: "ERROR",
								defaultMessage: `Error`,
							}
						)}: ${item.errorMessage}`}</span>)}
					</Message>
				)}
				<h1>
					<NavLink
						to="/campaigns"
						title={intl.formatMessage({
							id: "HINT_BACK_CAMPAIGNS",
							defaultMessage: "Back to the Campaign grid",
						})}
						className="black"
					>
						<Icon name="arrow left" size="small" />{" "}
						{intl.formatMessage({
							id: "HEADING_STRATEGIES",
							defaultMessage: "Strategies",
						})}
					</NavLink>
				</h1>
				<Divider hidden />
				<StrategyCampaignInfo
					item={state.campaign}
					currencies={commonData.currencies}
					campaign_type={campaign_type.current}
				/>
				<Divider hidden />
				{isStrategySelected() && (
					<details id="chart_swap_id">
						<summary>
							<hr />{" "}
							<div>
								{intl.formatMessage({
									id: "BUTTON_SHOW_HIDE_CHART",
									defaultMessage: "Show / hide chart",
								})}
							</div>
						</summary>
						<Grid>
							<Grid.Column verticalAlign="middle" style={{ width: "auto" }}>
								<strong>{strategySelected.title}</strong>
							</Grid.Column>
							<Grid.Column width={2}>
								<Select
									selection
									fluid
									options={graph_metrics(intl).filter(
										(x) => x.value !== graph_filter.metric_2
									)}
									name="metric_1"
									disabled={graph.loading}
									onChange={onChangeMetric}
									value={graph_filter.metric_1}
								/>
							</Grid.Column>
							<Grid.Column
								style={{ width: "15px" }}
								verticalAlign="middle"
								textAlign="center"
							>
								{intl.formatMessage({
									id: "LABEL_CAMPAIGN_VS_METRIC",
									defaultMessage: "vs",
								})}
							</Grid.Column>
							<Grid.Column width={2}>
								<Select
									selection
									fluid
									options={graph_metrics(intl).filter(
										(x) => x.value !== graph_filter.metric_1
									)}
									name="metric_2"
									disabled={graph.loading}
									onChange={onChangeMetric}
									value={graph_filter.metric_2}
								/>
							</Grid.Column>
						</Grid>
						{graph.loading ? (
							<Segment loading />
						) : (
							<ChartDiv
								updated={timestamp.current}
								filter={graph_filter}
								data={graph.data}
							/>
						)}
					</details>
				)}
				<Divider hidden />
				<StrategiesGrid
					items={state.strategies}
					loading={gridLoading}
					controls={{ pager: state.pager, filter: state.filter }}
				/>
			</StrategyGridContext.Provider>
		</Segment>
	);
};

/**
 * show campaign info
 * @param item
 * @param currencies
 * @param {string} campaign_type
 * @return {*}
 * @constructor
 */
const StrategyCampaignInfo = ({ item, currencies, campaign_type }) => {
	const intl = useIntl();

	if (!Object.keys(item).length) {
		return <></>;
	}

	return (
		<Grid columns="4" className="campaign_info">
			<Grid.Column>
				<label>
					{intl.formatMessage({
						id: "LABEL_CAMPAIGN",
						defaultMessage: "Campaign",
					})}
				</label>
				<Popup
					size="tiny"
					content={item.title}
					inverted
					trigger={
						<Header as="h4" className="restrict_length">
							{item.title}
						</Header>
					}
				/>
			</Grid.Column>
			<Grid.Column>
				<label>
					{intl.formatMessage({
						id: "LABEL_CAMPAIGN_TYPE",
						defaultMessage: "Campaign Type",
					})}
				</label>
				<Header as="h4">{campaign_type}</Header>
			</Grid.Column>
			<Grid.Column>
				<label>
					{intl.formatMessage({
						id: "LABEL_CAMPAIGN_BUDGET",
						defaultMessage: "Campaign Budget",
					})}
				</label>
				<Header as="h4">
					{formatMoney(item.budget, item.currency_code, Config.defaultLocale, 2)}
				</Header>
			</Grid.Column>
			<Grid.Column>
				<label>
					{intl.formatMessage({
						id: "LABEL_CAMPAIGN_START_END",
						defaultMessage: "Campaign Start - End",
					})}
				</label>
				<Header as="h4">
					{getApiDate(item.start_date, Config.dateTimeFormat)} /
					{getApiDate(item.end_date, Config.dateTimeFormat)}
				</Header>
			</Grid.Column>
		</Grid>
	);
};
StrategyCampaignInfo.propTypes = {
	item: PropTypes.object.isRequired,
	campaign_type: PropTypes.string.isRequired,
};

/**
 * Generate success message
 * @param {object} details
 * @return {*}
 * @constructor
 */
const StrategiesSuccessMessage = ({ details }) => {
	const intl = useIntl();

	let timer;
	React.useLayoutEffect(() => {
		timer = setTimeout(onBannerDismiss, 10000);
		return () => {
			clearTimeout(timer);
		};
	}, []);

	if (details.action === "created") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
			>
				{intl.formatMessage({
					id: "MESSAGE_STRATEGY_CREATED",
					defaultMessage: "Strategy successfully created",
				})}
			</Message>
		);
	} else if (details.action === "updated") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
			>
				{intl.formatMessage(
					{
						id: "MESSAGE_STRATEGY_UPDATED",
						defaultMessage: "Strategy {name} updated",
					},
					{
						name: details.name,
					}
				)}
			</Message>
		);
	}

	return <></>;
};
StrategiesSuccessMessage.propTypes = {
	details: PropTypes.object.isRequired,
};

const strategyColumns = {
	status: true,
	start_date: true,
	end_date: true,
	strategy_budget: true,
	strategy_spend: true,
	strategy_cpm: true,
	strategy_ctr: true,
	strategy_clicks: true,
	strategy_impressions: true,
};
const columnNames = (intl) =>
	new Map([
		[
			"status",
			intl.formatMessage({
				id: "LABEL_STATUS",
				defaultMessage: "Status",
			}),
		],
		[
			"strategy_budget",
			intl.formatMessage({
				id: "LABEL_BUDGET",
				defaultMessage: "Budget",
			}),
		],
		[
			"strategy_spend",
			intl.formatMessage({
				id: "LABEL_SPEND",
				defaultMessage: "Spend",
			}),
		],
		[
			"strategy_cpm",
			intl.formatMessage({
				id: "LABEL_CPM",
				defaultMessage: "CPM",
			}),
		],
		[
			"strategy_ctr",
			intl.formatMessage({
				id: "LABEL_CTR",
				defaultMessage: "CTR",
			}),
		],
		[
			"strategy_clicks",
			intl.formatMessage({
				id: "LABEL_CLICKS",
				defaultMessage: "Clicks",
			}),
		],
		[
			"strategy_impressions",
			intl.formatMessage({
				id: "LABEL_IMPRESSIONS",
				defaultMessage: "Impressions",
			}),
		],
		[
			"start_date",
			intl.formatMessage({
				id: "LABEL_START_DATE",
				defaultMessage: "Start Date",
			}),
		],
		[
			"end_date",
			intl.formatMessage({
				id: "LABEL_END_DATE",
				defaultMessage: "End Date",
			}),
		],
	]);

// set storage for campaign columns information
const storage = new Registry(window.sessionStorage);
if (!storage.getItem("strategies_grid")) {
	storage.setItem("strategies_grid", strategyColumns);
}

/**
 * Render strategies grid
 * @return {JSX.Element}
 * @constructor
 */
export const StrategiesGrid = ({ items, loading, controls }) => {
	const intl = useIntl();
	const context = React.useContext(StrategyGridContext);
	const [gridData, setGridData] = React.useState(
		storage.getItem("strategies_grid")
	);

	/**
	 * check should we show a cell based on flag in the map
	 * @param cellName
	 * @return {string}
	 */
	const cellVisible = (cellName) => {
		return gridData.hasOwnProperty(cellName) && gridData[cellName]
			? ""
			: "invisible";
	};

	/**
	 * click on the button to hide a popup
	 */
	const listener = () => {
		document.getElementById("edit-column-button").click();
	};

	/**
	 * attach a listener for mouse leave on edit-column popup
	 */
	const attachListener = () => {
		document
			.getElementById("edit-column-id")
			.addEventListener("mouseleave", listener);
	};

	/**
	 * remove a listener for mouse leave on component unmount
	 */
	const removeListener = () => {
		document
			.getElementById("edit-column-id")
			.removeEventListener("mouseleave", listener);
	};

	/**
	 * Storing grid information data
	 */
	React.useEffect(() => {
		storage.setItem("strategies_grid", gridData);
	}, [gridData]);

	const {
		strategyForDuplication,
		setStrategyForDuplication,
		duplicateStrategy,
    selectedStrategies,
    setSelectedStrategies,
    setIsBulkEditModalOpen,
    isBulkEditModalOpen,
    dispatch,
    setBulkEditResponseMessage
	} = context;

  const areAllStrategiesChecked = selectedStrategies.length === items.length;

  const handleCheckboxChange = useCallback((e, { checked }) => {
    setSelectedStrategies(checked ? items.map(({ id }) => id) : []);
  }, [items, setSelectedStrategies]);

	return (
		<>
			<Modal
				open={strategyForDuplication}
				onClose={() => setStrategyForDuplication(null)}
				closeOnDimmerClick={false}
				closeOnDocumentClick={false}
			>
				<Modal.Header>
					{intl.formatMessage({
						id: "DUPLICATION_CONFIRMATION_HEADER",
						defaultMessage: "Confirm duplication",
					})}
				</Modal.Header>
				<Modal.Content>
					{strategyForDuplication &&
						intl.formatMessage(
							{
								id: "DUPLICATION_STRATEGY_CONFIRMATION_MSG",
								defaultMessage:
									"Are you sure you want to duplicate strategy - {title}?",
							},
							{ title: strategyForDuplication["title"] }
						)}
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={() => setStrategyForDuplication(null)}>
						{intl.formatMessage({
							id: "BTN_CANCEL",
							defaultMessage: "Cancel",
						})}
					</Button>
					<Button
						primary
						onClick={() => {
							duplicateStrategy({ ...strategyForDuplication });
							setStrategyForDuplication(null);
						}}
					>
						{intl.formatMessage({
							id: "BTN_OK",
							defaultMessage: "Ok",
						})}
					</Button>
				</Modal.Actions>
			</Modal>
			<Grid className="common_grid">
				<Grid.Row columns={2}>
					<Grid.Column width={10}>
						<FilterControl
							filter={controls.filter}
							onChange={context.filtering}
						/>
					</Grid.Column>
					<Grid.Column
						width={6}
						className="margin__top20"
						textAlign="right"
						verticalAlign="middle"
					>
						<div>
							<Popup
								trigger={
									<Button
										className="margin__right20 text__uppercase"
										id="edit-column-button"
										compact
									>
										{intl.formatMessage({
											id: "BTN_EDIT_COLUMNS",
											defaultMessage: "Edit Columns",
										})}
									</Button>
								}
								onMount={attachListener}
								onUnmount={removeListener}
								id="edit-column-id"
								content={
									<List>
										{Object.keys(gridData).map((column) => {
											return (
												<List.Item key={uuid()}>
													<Checkbox
														label={columnNames(intl).get(column)}
														name={column}
														defaultChecked={gridData[column]}
														onChange={(e, { name, checked }) => {
															setGridData({ ...gridData, [name]: checked });
														}}
													/>
												</List.Item>
											);
										})}
									</List>
								}
								on="click"
								position="bottom right"
							/>
              <Popup
                content={
                  intl.formatMessage({
                    id: "TOOLTIP_SELECT_STRATEGIES_TO_BULK_EDIT",
                    defaultMessage: "Select one or more Strategies to Bulk Edit"
                  })
                }
                size="tiny"
                inverted
                position="top center"
                disabled={selectedStrategies.length > 0}
                trigger={
                  <div style={{ display: 'inline-block' }}>
                    <Button
                      className="text__uppercase"
                      primary
                      compact
                      onClick={() => setIsBulkEditModalOpen(true)}
                      disabled={selectedStrategies.length <= 0}
                    >
                      {intl.formatMessage({
                        id: "BTN_EDIT_STRATEGIES",
                        defaultMessage: "Bulk Edit Strategies",
                      })}
                    </Button>
                  </div>
                }
              />
							<Button
								className="text__uppercase"
								primary
								compact
								onClick={() => context.navigateToCreatePage()}
							>
								{intl.formatMessage({
									id: "BTN_ADD_STRATEGY",
									defaultMessage: "Add Strategy",
								})}
							</Button>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Table className="custom-table">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell style={{width:"40px"}}>
              <Checkbox
                checked={areAllStrategiesChecked}
                onChange={handleCheckboxChange}
              />
						</Table.HeaderCell>
						<Table.HeaderCell>
							{intl.formatMessage({
								id: "LABEL_STRATEGY_NAME",
								defaultMessage: "Strategy Name",
							})}
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="left"
							className={cellVisible("status")}
						>
							{intl.formatMessage({
								id: "LABEL_STATUS",
								defaultMessage: "Status",
							})}
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="left"
							className={cellVisible("start_date")}
						>
							{intl.formatMessage({
								id: "LABEL_START_DATE",
								defaultMessage: "Start Date",
							})}
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="left"
							className={cellVisible("end_date")}
						>
							{intl.formatMessage({
								id: "LABEL_END_DATE",
								defaultMessage: "End Date",
							})}
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="left"
							className={cellVisible("strategy_budget")}
						>
							{intl.formatMessage({
								id: "LABEL_BUDGET",
								defaultMessage: "Budget",
							})}
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="left"
							className={cellVisible("strategy_spend")}
							width={1}
						>
							{intl.formatMessage({
								id: "LABEL_SPEND",
								defaultMessage: "Spend",
							})}
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="left"
							className={cellVisible("strategy_cpm")}
							width={1}
						>
							{intl.formatMessage({
								id: "LABEL_CPM",
								defaultMessage: "CPM",
							})}
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="left"
							className={cellVisible("strategy_ctr")}
							width={1}
						>
							{intl.formatMessage({
								id: "LABEL_CTR",
								defaultMessage: "CTR",
							})}
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="left"
							className={cellVisible("strategy_clicks")}
							width={1}
						>
							{intl.formatMessage({
								id: "LABEL_CLICKS",
								defaultMessage: "Clicks",
							})}
						</Table.HeaderCell>
						<Table.HeaderCell
							textAlign="left"
							className={cellVisible("strategy_impressions")}
							width={1}
						>
							{intl.formatMessage({
								id: "LABEL_IMPRESSIONS",
								defaultMessage: "Impressions",
							})}
						</Table.HeaderCell>
						<Table.HeaderCell textAlign="center">&nbsp;</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{items.map((item) => {
						return (
							<StrategyGridItem
								key={uuid()}
								{...item}
								cellVisible={cellVisible}
							/>
						);
					})}
					<GridEmptyRow
						length={items.length}
						loading={loading}
						handleAddButton={context.navigateToCreatePage}
						filterTouched={!controls.filter.isEmpty()}
						addButtonLabel={intl.formatMessage({
							id: "BTN_ADD_STRATEGY",
							defaultMessage: "Add Strategy",
						})}
						notFoundMessage={intl.formatMessage({
							id: "EMPTY_STRATEGIES",
							defaultMessage: "You don’t have any strategies yet",
						})}
					/>
				</Table.Body>
				<Table.Footer>
					<Table.Row>
						<Table.Cell colSpan="11" textAlign="right">
							{controls.pager.total_pages > 1 && (
								<Pagination
									size="mini"
									activePage={controls.pager.page}
									totalPages={controls.pager.total_pages}
									firstItem={null}
									lastItem={null}
									onPageChange={context.getPage}
								/>
							)}
						</Table.Cell>
					</Table.Row>
				</Table.Footer>
			</Table>
      <StrategiesBulkEditModal
        isBulkEditModalOpen={isBulkEditModalOpen}
        setIsBulkEditModalOpen={setIsBulkEditModalOpen}
        selectedStrategies={selectedStrategies}
        items={items}
        dispatch={dispatch}
        setBulkEditResponseMessage={setBulkEditResponseMessage}
      />
		</>
	);
};
