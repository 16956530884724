import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Divider, Dropdown, Form, Input, Loader, Radio } from "semantic-ui-react";
import { errorToMessage } from "../../../libs/common_utils";
import { useForm } from "../../../libs/component_utils";
import PasswordsService from "../../../services/passwords";
import OrganizationPicker from "../../common/organization-picker";
import { user_roles } from "../fixtures";

/**
 * User create page component
 * @return {*}
 * @constructor
 */
const UserForm = ({
  initialData,
  loading,
  onSubmit,
  onCancel,
  initialIndeterminateAgencies = [],
  formType = "create",
  submitText = "Upload Device ID",
}) => {
  /**
   * form submit handler
   * @return {boolean}
   */
  const UserForm = async () => {
    await onSubmit(values);
  };

  const intl = useIntl();
  const {
    values,
    updateValues,
    errors,
    onChange,
    onSubmit: handleSubmit,
    setErrors,
  } = useForm(UserForm, initialData, () => {
    const errors = {};

    if (formType !== "profile" && !values.agency_ids.length && !values.advertiser_ids.length) {
      errors.agency_ids = intl.formatMessage({
        id: "ERROR_EMPTY_ENTITIES",
        defaultMessage: "Please select at least one agency or advertiser.",
      });
    }

    return errors;
  });

  const services = useRef(new Map([["passwords", new PasswordsService()]]));
  const [passwordLoading, setPasswordLoading] = useState();
  const [passwordError, setPasswordError] = useState();
  const [passwordFinal, setPasswordFinal] = useState();
  const onResetPassword = async () => {
    setPasswordLoading(true);
    try {
      const response = await services.current.get("passwords").create({
        email: values.username,
      });
      if (response.meta.status === "Error") {
        throw response;
      }

      setPasswordFinal(
        intl.formatMessage({
          defaultMessage: "We've just sent you an email to reset your password.",
          id: "BODY_RESET_PWD_SENT",
        }),
      );
    } catch (e) {
      setPasswordError(`Error: ${e.message || e.error.message}`);
    } finally {
      setPasswordLoading(false);
    }
  };

  return (
    <Form
      autoComplete="off"
      error={!!Object.keys(errors).length}
      loading={loading}
      noValidate
      onSubmit={handleSubmit}
      size="small"
    >
      {formType !== "profile" && (
        <Form.Field inline>
          <label>
            {intl.formatMessage({
              defaultMessage: "Status",
              id: "LABEL_STATUS",
            })}
          </label>
          <Radio
            checked={!!values.status}
            label={intl.formatMessage({
              defaultMessage: "Active",
              id: "STATUS_ACTIVE",
            })}
            name="status"
            onChange={onChange}
            value={1}
          />
          <Radio
            checked={!values.status}
            label={intl.formatMessage({
              defaultMessage: "Inactive",
              id: "STATUS_INACTIVE",
            })}
            name="status"
            onChange={onChange}
            style={{ marginLeft: "15px" }}
            value={0}
          />
        </Form.Field>
      )}

      <Form.Field
        error={Object.hasOwn(errors, "first_name")}
        inline
        required
      >
        <label>
          {intl.formatMessage({
            defaultMessage: "First name",
            id: "LABEL_FIRST_NAME",
          })}
        </label>
        <Input
          defaultValue={values.first_name}
          maxLength={32}
          minLength={1}
          name="first_name"
          onBlur={onChange}
          required
        />
        <div className="custom-error">{errors.first_name}</div>
      </Form.Field>

      <Form.Field
        error={Object.hasOwn(errors, "last_name")}
        inline
        required
      >
        <label>
          {intl.formatMessage({
            id: "LABEL_LAST_NAME",
            defaultMessage: "Last name",
          })}
        </label>
        <Input
          defaultValue={values.last_name}
          maxLength={32}
          minLength={1}
          name="last_name"
          onBlur={onChange}
          required
        />
        <div className="custom-error">{errors.last_name}</div>
      </Form.Field>

      <Form.Field
        error={Object.hasOwn(errors, "title")}
        inline
        required
      >
        <label>
          {intl.formatMessage({
            defaultMessage: "Title",
            id: "LABEL_TITLE",
          })}
        </label>
        <Input
          defaultValue={values.title}
          maxLength={64}
          minLength={1}
          name="title"
          onBlur={onChange}
          required
        />
        <div className="custom-error">{errors.title}</div>
      </Form.Field>

      <Form.Field
        error={Object.hasOwn(errors, "phone")}
        inline
        required
      >
        <label>
          {intl.formatMessage({
            defaultMessage: "Phone",
            id: "LABEL_PHONE",
          })}
        </label>
        <Input
          defaultValue={values.phone}
          maxLength={24}
          minLength={8}
          name="phone"
          onBlur={onChange}
          pattern="^[\d ()+-]+$"
          required
          title={intl.formatMessage({
            defaultMessage: "Phone may only include digits, spaces, +, -, ()",
            id: "ERROR_PHONE_PATTERN",
          })}
          type="tel"
        />
        <div className="custom-error">{errors.phone}</div>
      </Form.Field>

      {formType === "create" ? (
        <Form.Field
          error={Object.hasOwn(errors, "username")}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              defaultMessage: "Email",
              id: "LABEL_EMAIL",
            })}
          </label>
          <Input
            defaultValue={values.username}
            maxLength={128}
            minLength={1}
            name="username"
            onBlur={onChange}
            required
            type="email"
          />
          <span style={{ marginLeft: 20, color: "rgba(0, 0, 0, .6)" }}>
            {intl.formatMessage({
              defaultMessage: "Email is the username",
              id: "HINT_EMAIL",
            })}
          </span>
          <div className="custom-error">{errors.username}</div>
        </Form.Field>
      ) : (
        <Form.Field
          inline
          required
        >
          <label>
            {intl.formatMessage({
              defaultMessage: "Email",
              id: "LABEL_EMAIL",
            })}
          </label>
          <span>{values.username}</span>
        </Form.Field>
      )}

      {formType !== "create" && (
        <Form.Field
          error={Boolean(passwordError)}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              defaultMessage: "Password",
              id: "LABEL_PASSWORD",
            })}
          </label>
          {passwordFinal ? (
            <span>{passwordFinal}</span>
          ) : (
            <>
              <button
                className="pseudo-link"
                disabled={passwordLoading}
                onClick={onResetPassword}
                type="button"
              >
                {intl.formatMessage({
                  defaultMessage: "Click to send password reset email",
                  id: "BTN_RESET_PWD_ADMIN",
                })}
              </button>
              <Loader
                active={passwordLoading}
                inline
                size="mini"
                style={{ marginLeft: 7, verticalAlign: "text-bottom" }}
              />
            </>
          )}
          <div className="custom-error">{passwordError}</div>
        </Form.Field>
      )}

      {formType !== "profile" && (
        <Form.Field
          error={Object.hasOwn(errors, "role")}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              defaultMessage: "User Role",
              id: "LABEL_USER_ROLE",
            })}
          </label>

          <Dropdown
            name="role"
            onChange={onChange}
            options={Object.entries(user_roles(intl)).map(([value, text]) => ({
              key: value,
              text,
              value,
            }))}
            // loading={pageData.agenciesLoading}
            placeholder={intl.formatMessage({
              id: "HINT_USER_ROLE",
              defaultMessage: "Select role",
            })}
            required
            selection
            value={values.role}
          />
          <div className="custom-error">{errors.role}</div>
        </Form.Field>
      )}

      {formType !== "profile" && (
        <Form.Field
          error={Boolean(errors.agency_ids || errors.advertiser_ids)}
          inline
          required
        >
          <label>
            {intl.formatMessage({
              defaultMessage: "Entities",
              id: "LABEL_ENTITIES",
            })}
          </label>

          <OrganizationPicker
            // these will go passed from backend on edit page, always empty on create page:
            initialIndeterminateAgencies={initialIndeterminateAgencies}
            onChange={updateValues}
            onError={(error) =>
              setErrors({
                ...errors,
                agency_ids: errorToMessage(error) || "Unknown error fetching data from server",
              })
            }
            refKeys={{
              agencyIds: "agency_ids",
              advertiserIds: "advertiser_ids",
            }}
            values={values}
          />
          <div className="custom-error">{errors.agency_ids || errors.advertiser_ids}</div>
        </Form.Field>
      )}

      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Form.Field align="right">
        <Button
          onClick={onCancel}
          size="tiny"
          type="button"
        >
          {intl.formatMessage({
            defaultMessage: "Cancel",
            id: "BTN_CANCEL",
          })}
        </Button>
        <Button
          color="green"
          size="tiny"
          type="submit"
        >
          {submitText}
        </Button>
      </Form.Field>
    </Form>
  );
};
UserForm.propTypes = {
  formType: PropTypes.string,
};

export default UserForm;
