// biome-ignore lint/correctness/noUnusedImports: <explanation>
import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, Form, Grid, Input, Message, Pagination, Select, Table } from "semantic-ui-react";

import InventoryPageContext from "../context";

const siteListStatuses = (intl) => [
  {
    text: intl.formatMessage({
      id: "STATUS_ALL",
      defaultMessage: "All",
    }),
    value: "all",
  },
  {
    text: intl.formatMessage({
      id: "STATUS_ACTIVE",
      defaultMessage: "Active",
    }),
    value: "active",
  },
  {
    text: intl.formatMessage({
      id: "STATUS_INACTIVE",
      defaultMessage: "Inactive",
    }),
    value: "inactive",
  },
];

function SiteLists() {
  const intl = useIntl();
  const history = useHistory();
  const { state } = useContext(InventoryPageContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    return () => {
      state.siteList.pager.reset();
    };
  }, []);

  return (
    <div>
      {state.error && (
        <Message
          style={{ marginTop: "10px" }}
          error
          hidden={!state.error}
          size="tiny"
          content={state.errorMessage}
        />
      )}
      <Grid className="common_grid margin__top20">
        <Grid.Row columns={2}>
          <Grid.Column width={12}>
            <Filters />
          </Grid.Column>
          <Grid.Column
            width={4}
            textAlign="right"
            verticalAlign="middle"
          >
            <Button
              className="text__uppercase"
              primary
              compact
              onClick={() => history.push("/inventory/site-list/create")}
            >
              {intl.formatMessage({
                id: "BTN_ADD_SITE_LIST",
                defaultMessage: "Add Site List",
              })}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SiteListTable />
    </div>
  );
}

function SiteListTable() {
  const intl = useIntl();
  const { state, dispatch, loadSiteLists } = useContext(InventoryPageContext);
  const items = state.siteList.index;

  return (
    <Table className="custom-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_SITE_LIST_NAME",
              defaultMessage: "Site List Name",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_SITE_LIST_STATUS",
              defaultMessage: "Status",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_SITE_LIST_ID",
              defaultMessage: "Site List ID",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_SITE_LIST_RESTRICTION_TYPE",
              defaultMessage: "Restriction Type",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_SITE_LIST_DOMAIN_COUNT",
              defaultMessage: "Domain Count",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_SITE_LIST_APP_COUNT",
              defaultMessage: "App Count",
            })}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.length === 0 && <GridEmptyRow intl={intl} />}
        {items.map((item) => (
          <SiteList
            key={item.id}
            item={item}
          />
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell
            colSpan="12"
            textAlign="right"
          >
            {state.siteList.pager.total_pages > 1 && (
              <Pagination
                size="mini"
                activePage={state.siteList.pager.page}
                totalPages={state.siteList.pager.total_pages}
                firstItem={null}
                lastItem={null}
                onPageChange={(_e, data) => {
                  loadSiteLists(state, dispatch, data.activePage);
                }}
              />
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

const GridEmptyRow = ({ intl }) => {
  return (
    <Table.Row>
      <Table.Cell
        colSpan="7"
        textAlign="center"
      >
        {intl.formatMessage({
          id: "EMPTY_SEARCH_RESULTS",
          defaultMessage: "No results found",
        })}
      </Table.Cell>
    </Table.Row>
  );
};

function SiteList({ item }) {
  return (
    <Table.Row>
      <Table.Cell>{item.title}</Table.Cell>
      <Table.Cell>{item.status ? "Active" : "Inactive"}</Table.Cell>
      <Table.Cell>{item.id}</Table.Cell>
      <Table.Cell>{item.restriction}</Table.Cell>
      <Table.Cell>{item.domain_count}</Table.Cell>
      <Table.Cell>{item.app_count}</Table.Cell>
    </Table.Row>
  );
}

function Filters() {
  const intl = useIntl();
  const { state, dispatch, loadSiteLists } = useContext(InventoryPageContext);
  const filter = state.siteList.filter;

  useEffect(() => {
    const el = document.getElementById("clear_site_list_index_search");
    if (el) {
      el.addEventListener(
        "click",
        () => {
          filter.setTitle("");
          dispatch({ type: "SITELIST_UPDATE_FILTERS", data: { title: "" } });
          loadSiteLists(state, dispatch, state.siteList.pager.page);
        },
        false,
      );
    }
  }, [state, filter, dispatch, loadSiteLists]);

  return (
    <Form
      autoComplete="off"
      noValidate
    >
      <Form.Group>
        <Form.Field>
          <label>
            {intl.formatMessage({
              id: "LABEL_SITE_LIST_STATUS",
              defaultMessage: "Status",
            })}
          </label>
          <Select
            selection
            options={siteListStatuses(intl)}
            value={filter.status}
            onChange={(_e, data) => {
              filter.setStatus(data.value);
              dispatch({
                type: "SITELIST_UPDATE_FILTERS",
                data: { status: data.value },
              });
              loadSiteLists(state, dispatch, state.siteList.pager.page);
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>
            {intl.formatMessage({
              id: "LABEL_SITE_LIST_NAME",
              defaultMessage: "Name",
            })}
          </label>
          <Input
            value={filter.title}
            onChange={(_e, data) => {
              filter.setTitle(data.value);
              dispatch({ type: "SITELIST_UPDATE_FILTERS", data: { title: data.value } });
              loadSiteLists(state, dispatch, state.siteList.pager.page);
            }}
            placeholder={intl.formatMessage({
              id: "HINT_SEARCH_SITE_LIST_NAME",
              defaultMessage: "Search site list by name",
            })}
            icon={
              filter.title
                ? {
                    name: "close",
                    link: true,
                    id: "clear_site_list_index_search",
                  }
                : null
            }
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}

export default SiteLists;
