import AbstractService from "../abstract.service";

export default class SiteListService extends AbstractService {
  static URI_STATES = new Map([
    ["site-lists.list", "/site-lists"],
    ["site-lists.post", "/site-list"],
  ]);

  list(params) {
    return this.api_server.get(SiteListService.URI_STATES.get("site-lists.list"), params);
  }

  /**
   * post creative
   * @param {object} params
   * @return {Promise<>}
   */
  async create(params) {
    return await this.api_server.post(SiteListService.URI_STATES.get("site-lists.post"), params);
  }
}
