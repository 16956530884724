import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Divider, Header, Message, Segment } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import { OnlineContext } from "../../../context/online-context";
import UsersService from "../../../services/users";
import UserForm from "../form";

/**
 * Audience edit page component
 * @param {object} history
 * @param {object} match
 * @param {object} match.params
 * @param {number} match.params.user_id
 * @return {*}
 * @constructor
 */
const UserEditPage = ({ history, match }) => {
  const backUrl = "/admin/users";
  const service = new UsersService();

  const intl = useIntl();

  const user_id = Number.parseInt(match.params.user_id, 10) || 0;
  const { t1UserId } = useContext(OnlineContext);

  const _isMounted = useRef(false);

  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);

  const [initialData, setInitialData] = useState(null);
  const [initialIndeterminateAgencies, setInitialIndeterminateAgencies] = useState([]);
  /**
   * navigate user back
   */
  const handleCancel = useCallback(() => {
    history.push(backUrl);
  }, [history]);

  /**
   * handle 404
   */
  const handle404 = () => {
    history.push(backUrl, { action: "error", msg: "User not found" });
  };

  /**
   * form submit handler
   * @return {boolean}
   */
  const handleSubmit = async (values) => {
    setLoading(true);

    const { agency_ids, advertiser_ids, ...rest } = values;
    const data = {
      ...rest,
      email: values.username,
      entities: [
        {
          entity_ids: agency_ids,
          entity_type: "agencies",
        },
        {
          entity_ids: advertiser_ids,
          entity_type: "advertisers",
        },
      ],
    };

    try {
      await service.update(user_id, data);
      history.push(backUrl, {
        action: "updated",
        username: values.username,
      });
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if (_isMounted.current) {
        setLoading(false);
      }
    }
  };

  if (user_id < 1) {
    handle404();
  }

  if (user_id === t1UserId) {
    history.replace("/profile");
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on "user_id" change
  useEffect(() => {
    _isMounted.current = true;
    (async () => {
      try {
        const r = await service.get(user_id);
        const newIndeterminateAgencies = r.data.entities.find(
          (kind) => kind.entity_type === "agencies" && kind.entity_status === "partial",
        ).entity_ids;
        setInitialIndeterminateAgencies(newIndeterminateAgencies);
        setInitialData({
          advertiser_ids: r.data.entities.find((kind) => kind.entity_type === "advertisers").entity_ids,
          agency_ids: r.data.entities.find((kind) => kind.entity_type === "agencies" && kind.entity_status === "full")
            .entity_ids,
          first_name: r.data.first_name,
          last_name: r.data.last_name,
          phone: r.data.phone,
          role: r.data.user_role,
          status: r.data.status,
          title: r.data.title,
          username: r.data.username,
        });
      } catch (_) {
        handle404();
      }
    })();

    return () => {
      _isMounted.current = false;
    };
  }, [user_id]);

  if (initialData === null) {
    return (
      <Segment
        className="loading"
        disabled
        tertiary
        textAlign="center"
      >
        &nbsp;
      </Segment>
    );
  }

  return (
    <Segment basic>
      <Header as="h2">
        {intl.formatMessage({
          defaultMessage: "Edit User",
          id: "HEADING_EDIT_USER",
        })}
      </Header>
      <Divider />
      <Message
        content={serverError}
        error
        hidden={!serverError}
        size="tiny"
        style={{ marginTop: "10px" }}
      />
      <UserForm
        formType="edit"
        initialData={initialData}
        initialIndeterminateAgencies={initialIndeterminateAgencies}
        loading={loading}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        submitText={intl.formatMessage({
          id: "BTN_SAVE_CHANGES",
          defaultMessage: "Save Changes",
        })}
      />
    </Segment>
  );
};

export default UserEditPage;
