import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Button, Table } from "semantic-ui-react";

const GridEmptyRow = ({ addButtonLabel, filterTouched, handleAddButton, length, loading, notFoundMessage }) => {
  const intl = useIntl();

  if (length > 0 || loading) {
    return null;
  }

  const emptyMessage = filterTouched
    ? intl.formatMessage({
        defaultMessage: "No results found",
        id: "EMPTY_SEARCH_RESULTS",
      })
    : notFoundMessage;

  if (!filterTouched) {
    return (
      <Table.Row>
        <Table.Cell
          className="no-border"
          colSpan="24"
          textAlign="center"
        >
          <div style={{ width: "100vw" }}>
            {emptyMessage}
            <br />
            <br />
            <Button
              className="text__uppercase"
              primary
              compact
              onClick={handleAddButton}
            >
              {addButtonLabel}
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Table.Row>
      <Table.Cell
        className="no-border"
        colSpan="24"
        textAlign="center"
      >
        <div style={{ width: "100vw" }}>{emptyMessage}</div>
      </Table.Cell>
    </Table.Row>
  );
};

GridEmptyRow.propTypes = {
  addButtonLabel: PropTypes.string.isRequired,
  filterTouched: PropTypes.bool.isRequired,
  handleAddButton: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  notFoundMessage: PropTypes.string.isRequired,
};

export default GridEmptyRow;
