import lightFormat from "date-fns/lightFormat";
import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useLayoutEffect, useReducer, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Form, Grid, Icon, Input, Message, Pagination, Segment, Table } from "semantic-ui-react";
import { Config } from "../../../config/api";
import { isNil, parseAPIDateTime } from "../../../libs/common_utils";
import {
  isNewTabClick,
  onBannerDismiss,
  search_query_becomes_empty,
  should_do_search,
  useMounted,
} from "../../../libs/component_utils";
import Pager from "../../../models/pager";
import UsersService from "../../../services/users";
import { user_roles } from "../fixtures";
import UsersGridContext from "./context";
import { userGridReducer, usersActions } from "./reducers";

const initialState = {
  users: [],
  pager: new Pager(),
  total_pages: 0,
};

export const UsersPage = ({ history }) => {
  const intl = useIntl();
  const [state, dispatch] = useReducer(userGridReducer, initialState);
  const [gridLoading, setGridLoading] = useState(true);
  const showSuccessMessage = !isNil(history.location.state);
  const isMounted = useMounted();
  const services = useRef(new Map([["users", new UsersService()]]));
  const query = useRef("");
  const timer = useRef(0);

  /**
   * initial load
   */
  useEffect(() => {
    getList(state.pager);
  }, [state.pager]);

  /**
   * get page
   * @param e
   * @param activePage
   */
  const getPage = (_, { activePage }) => {
    state.pager.setPage(activePage);
    getList(state.pager);
  };

  /**
   * get to create page
   */
  const navigateToCreatePage = () => {
    history.push("/admin/user/create");
  };

  /**
   * navigate user to edit page
   */
  const navigateToEditPage = useCallback(
    (id) => {
      history.push(
        `/admin/user/edit/${id}`,
        state.users.find((user) => user.id === id),
      );
    },
    [history, state],
  );

  /**
   * get edit page URL by campaign id
   */
  const getEditPageHref = useCallback((id) => `/admin/user/edit/${id}`, []);

  /**
   * load users from API
   * @param {object|null} pager
   * @return {Promise<void>}
   */
  const getList = async (pager = null) => {
    const users = services.current.get("users");
    try {
      setGridLoading(true);

      let params = {};
      if (pager) {
        params = Object.assign(params, pager.toJson());
      }

      if (query.current.length) {
        params.search = query.current;
      }

      const response = await users.list(params);
      /** @namespace response.data **/
      const meta = response.meta;

      meta.page = 1;

      dispatch({
        type: usersActions.INIT,
        data: response.data,
        pager: meta,
      });
    } catch (e) {
      // ignore error
      console.error(e);
    } finally {
      if (isMounted.current) {
        setGridLoading(false);
      }
    }
  };

  /**
   * do a search
   * @param e
   * @param {string} value
   */
  const handleSearch = async (_, { value: searchQuery }) => {
    const prevQuery = query.current || "";

    query.current = searchQuery;

    // clear time any time we hit the method
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (!should_do_search(searchQuery, prevQuery)) {
      return;
    }

    state.pager.reset();
    if (search_query_becomes_empty(searchQuery, prevQuery)) {
      await getList(state.pager);
      return;
    }

    timer.current = setTimeout(async () => {
      await getList(state.pager);
    }, Config.search_debounce_delay);
  };

  return (
    <Segment
      basic
      style={{ padding: "0" }}
    >
      <UsersGridContext.Provider
        value={{
          getEditPageHref,
          getPage,
          handleSearch,
          navigateToCreatePage,
          navigateToEditPage,
        }}
      >
        {showSuccessMessage && <UsersSuccessMessage details={history.location.state || {}} />}

        <h1>
          {intl.formatMessage({
            id: "HEADING_USERS",
            defaultMessage: "Users",
          })}
        </h1>
        <UsersGrid
          controls={{ pager: state.pager }}
          items={state.users}
          loading={gridLoading}
        />
      </UsersGridContext.Provider>
    </Segment>
  );
};

/**
 * Generate success message
 * @param {function} onDismiss
 * @param {object} details
 * @return {*}
 * @constructor
 */
const UsersSuccessMessage = ({ details, onDismiss }) => {
  const intl = useIntl();

  useEffect(() => {
    const timer = setTimeout(onBannerDismiss, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (details.action === "created") {
    return (
      <Message
        success
        className="page-success-message"
        attached
        onDismiss={onDismiss || onBannerDismiss}
      >
        {intl.formatMessage({
          id: "MESSAGE_USER_CREATED",
          defaultMessage: "User successfully created",
        })}
      </Message>
    );
  }

  if (details.action === "updated") {
    return (
      <Message
        attached
        className="page-success-message"
        onDismiss={onDismiss || onBannerDismiss}
        success
      >
        {intl.formatMessage(
          {
            defaultMessage: "{name} profile updated",
            id: "MESSAGE_USER_UPDATED",
          },
          {
            name: details.username,
          },
        )}
      </Message>
    );
  }

  return null;
};

UsersSuccessMessage.propTypes = {
  details: PropTypes.object.isRequired,
};

/**
 * Render grid
 * @param {array} items
 * @param {boolean} loading
 * @param {object} controls
 * @return {JSX.Element}
 * @constructor
 */
export const UsersGrid = ({ items, loading, controls }) => {
  const intl = useIntl();
  const context = useContext(UsersGridContext);
  const [searchQuery, setSearchQuery] = useState("");
  const isMounted = useMounted();

  /**
   * generate close icon
   * @returns {{onClick: *, name: string}}
   */
  const getCloseIcon = () => {
    return {
      id: "clear_search_input",
      link: true,
      name: "close",
    };
  };

  // set effect to on query change, added listener to reset
  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on "searchQuery" change
  useLayoutEffect(() => {
    const el = document.getElementById("clear_search_input");
    if (el && searchQuery.length > 0) {
      el.addEventListener("click", clearSearch, false);
    }

    if (isMounted.current) {
      context.handleSearch(null, { value: searchQuery });
    }

    return () => {
      if (el) {
        el.removeEventListener("click", clearSearch);
      }
    };
  }, [searchQuery]);

  /**
   * set search value and trigger search
   * @param e
   * @param value
   * @return {Promise<void>}
   */
  const handleSearch = (_, { value }) => {
    setSearchQuery(value);
  };

  /**
   * clear search query
   */
  const clearSearch = () => {
    setSearchQuery("");
  };

  return (
    <>
      <Grid className="common_grid">
        <Grid.Row>
          <Grid.Column width={12}>
            <Form
              autoComplete="off"
              noValidate
              size="tiny"
              style={{ marginTop: "15px" }}
            >
              <Form.Group>
                <Form.Field>
                  <label>
                    {intl.formatMessage({
                      defaultMessage: "Username",
                      id: "LABEL_USERNAME",
                    })}
                  </label>
                  <Input
                    disabled={loading}
                    icon={searchQuery.length ? getCloseIcon() : null}
                    name="username"
                    onChange={handleSearch}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Search by username",
                      id: "HINT_USERNAME",
                    })}
                    style={{ width: "250px" }}
                    value={searchQuery}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column
            style={{ marginBottom: "1.6em" }}
            textAlign="right"
            verticalAlign="bottom"
            width={4}
          >
            <Button
              className="text__uppercase"
              compact
              onClick={() => context.navigateToCreatePage()}
              primary
            >
              {intl.formatMessage({
                defaultMessage: "Create User",
                id: "BTN_CREATE_USER",
              })}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment
        basic
        loading={loading}
        style={{ padding: "0" }}
      >
        <Table className="custom-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {intl.formatMessage({
                  defaultMessage: "Username",
                  id: "LABEL_USERNAME",
                })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {intl.formatMessage({
                  defaultMessage: "First name",
                  id: "LABEL_FIRST_NAME",
                })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {intl.formatMessage({
                  defaultMessage: "Last name",
                  id: "LABEL_LAST_NAME",
                })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {intl.formatMessage({
                  defaultMessage: "Role",
                  id: "LABEL_ROLE",
                })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {intl.formatMessage({
                  defaultMessage: "Last login",
                  id: "LABEL_LAST_LOGIN",
                })}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ width: "70px" }}
                textAlign="center"
              >
                &nbsp;
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              return (
                <UserGridItem
                  key={item.id}
                  {...item}
                />
              );
            })}
            {!items.length && (
              <Table.Row>
                <Table.Cell
                  colSpan="5"
                  textAlign="center"
                >
                  {intl.formatMessage({
                    defaultMessage: "No results found",
                    id: "EMPTY_SEARCH_RESULTS",
                  })}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell
                colSpan="12"
                textAlign="right"
              >
                {controls.pager.total_pages > 1 && (
                  <Pagination
                    activePage={controls.pager.page}
                    firstItem={null}
                    lastItem={null}
                    onPageChange={context.getPage}
                    size="mini"
                    totalPages={controls.pager.total_pages}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
    </>
  );
};

/**
 * Generate grid item
 * @param {object} item
 * @return {*}
 * @constructor
 */
const UserGridItem = (item) => {
  const intl = useIntl();
  const context = useContext(UsersGridContext);

  return (
    <Table.Row className={item.status ? "" : "inactive"}>
      <Table.Cell className="grid-item-title">{item.username}</Table.Cell>
      <Table.Cell>{item.first_name}</Table.Cell>
      <Table.Cell>{item.last_name}</Table.Cell>
      <Table.Cell>{user_roles(intl)[item.user_role] || item.user_role}</Table.Cell>
      <Table.Cell>
        {item.last_login ? lightFormat(parseAPIDateTime(item.last_login), Config.fullDateTimeFormat) : "–"}
      </Table.Cell>
      <Table.Cell
        data-clickable="0"
        textAlign="center"
      >
        <a
          href={context.getEditPageHref(item.id)}
          onClick={(e) => {
            if (isNewTabClick(e)) return;
            e.preventDefault();
            e.stopPropagation();
            context.navigateToEditPage(item.id);
          }}
        >
          <Icon
            className="control"
            name="edit"
          />
        </a>
      </Table.Cell>
    </Table.Row>
  );
};
