import PropTypes from "prop-types";
import { Card, Checkbox, Input, List, Loader } from "semantic-ui-react";

import { classNames } from "../../../libs/component_utils";

import "./millerPicker.css";

export default function MillerPicker({ disabled, children, ...props }) {
  return (
    <div
      className={classNames("miller-picker", disabled && "disabled")}
      {...props}
    >
      {children}
    </div>
  );
}

const MillerPickerColumn = ({
  activeItem,
  indeterminate = new Set(),
  items = [],
  loading,
  onChange,
  onSearchChange,
  onSelect,
  search = false,
  searchPlaceholder = "Search",
  searchQuery = "",
  selection,
  title,
  value: columnValue,
  ...props
}) => (
  <Card
    className="miller-picker-column"
    {...props}
  >
    <Card.Content className="header-content">
      <Card.Header>
        <Checkbox
          checked={items && columnValue && items.length > 0 && items.every(({ value }) => columnValue.includes(value))}
          indeterminate={
            items &&
            columnValue &&
            items.some(({ value }) => columnValue.includes(value)) &&
            !items.every(({ value }) => columnValue.includes(value))
          }
          onChange={(e, { checked }) => {
            e.stopPropagation();
            onChange(checked ? items.map(({ value }) => value) : []);
          }}
        />
        {title}
      </Card.Header>
    </Card.Content>
    <Card.Content
      className="miller-picker-list"
      extra
    >
      {loading ? (
        <Loader active />
      ) : (
        <List selection={selection}>
          {items.map(({ value, text }) => (
            <List.Item
              active={value === activeItem}
              key={value}
              onClick={onSelect ? () => onSelect(value) : null}
            >
              <Checkbox
                checked={columnValue.includes(value)}
                indeterminate={indeterminate.has(value) && !columnValue.includes(value)}
                onChange={(e, { checked }) => {
                  e.stopPropagation();
                  onChange(checked ? [...columnValue, value] : columnValue.filter((v) => value !== v));
                }}
              />
              {text}
            </List.Item>
          ))}
        </List>
      )}
    </Card.Content>
    {search ? (
      <Card.Content
        className="miller-picker-search"
        extra
      >
        <Input
          autoComplete="off"
          fluid
          icon={
            searchQuery !== ""
              ? {
                  name: "x",
                  className: "input-clear-icon",
                  link: true,
                  onClick: () => onSearchChange(""),
                }
              : "search"
          }
          iconPosition={searchQuery !== "" ? null : "left"}
          onChange={(_, { value }) => onSearchChange(value)}
          placeholder={searchPlaceholder}
          transparent
          value={searchQuery}
        />
      </Card.Content>
    ) : null}
  </Card>
);

MillerPickerColumn.propTypes = {
  activeItem: PropTypes.any,
  indeterminate: PropTypes.instanceOf(Set),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func,
  onSelect: PropTypes.func,
  search: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  searchQuery: PropTypes.string,
  selection: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
};

MillerPicker.Column = MillerPickerColumn;
