import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Divider, Header, Segment } from "semantic-ui-react";

import SiteListForm from "./form";

const parentUrl = "/inventory/site-lists";
const initialData = {
  restriction: "allow",
  status: true,
  title: "",
};

/**
 * SiteList create page component
 * @param history
 * @return {*}
 * @constructor
 */
const SiteListCreatePage = ({ history }) => {
  const intl = useIntl();
  const handleCancel = useCallback(() => {
    history.push(parentUrl);
  }, [history]);

  /**
   * Handle create form submit
   * @returns {void}
   */
  const handleSubmit = useCallback(() => {
    history.push(parentUrl, { action: "created", type: "site-list" });
  }, [history]);

  return (
    <Segment basic>
      <Header as="h2">
        {intl.formatMessage({
          defaultMessage: "Create Site List",
          id: "HEADING_CREATE_SITE_LIST",
        })}
      </Header>
      <Divider />
      <SiteListForm
        initialData={initialData}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        submitButtonLabel={intl.formatMessage({
          id: "BTN_CREATE_SITE_LIST",
          defaultMessage: "Create Site List",
        })}
      />
    </Segment>
  );
};

export default SiteListCreatePage;
