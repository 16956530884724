import React, { useState, useCallback, useMemo } from "react";
import Pacing from "../../../models/object_value/pacing";
import Frequency from "../../../models/object_value/frequency";
import { strategiesActions as sA } from "../index/reducers";
import { useIntl } from "react-intl";
import StrategiesService from "../../../services/strategy";
import { useForm } from "../../../libs/component_utils";
import StrategiesBulkEditModel from "../../../models/strategies_bulk_edit";
import { BulkEditModalComponent } from "../../common/bulkEditModal";

const fields = ['status', 'bid', 'dailyPacing', 'frequencyCap'];

export const StrategiesBulkEditModal = ({isBulkEditModalOpen, setIsBulkEditModalOpen, selectedStrategies, items, dispatch, setBulkEditResponseMessage}) => {
  const [checkedFields, setCheckedFields] = useState([]);
	const [formSending, setFormSending] = useState(false);
	const [serverError, setServerError] = useState("");
	const service = new StrategiesService();
  const intl = useIntl();
  const { formatMessage } = intl;
  const initialData = {
    "ids": selectedStrategies,
    "status": null,
    "pacingType": null,
    "pacingInterval": null,
    "pacingAmount": null,
    "frequencyOptimization": null,
    "frequencyType": null,
    "frequencyAmount": null,
    "frequencyInterval": null,
    "minBid": null,
    "maxBid": null,
  }

  const handleResponse = ({ data = [], errors = [] }) => {
    const successMessage = data.map(({ id, name }) => ({ id, name }));
    const errorMessage = errors.map(({ message }) => ({ message }));

    setBulkEditResponseMessage({ successMessage, errorMessage });
  };

  const updateStateForStrategies = (response, model) => {
    const { ids, ...fieldsToUpdate } = model;
    const successfulIDs = response.data.map(strategy => strategy.id);

    const updatedItems = items.map(item => {
      if (successfulIDs.includes(item.id)) {
        return {
          ...item,
          ...fieldsToUpdate
        };
      }
      return item;
    });

    dispatch({ type: sA.UPDATE, data: updatedItems });
  };

  const editBulkStrategiesForm = async (params) => {
    try {
      setFormSending(true);
      clearMessages();
      const model = new StrategiesBulkEditModel(params);
      const response = await service.bulkEditStrategies(model);
      handleResponse(response);
      updateStateForStrategies(response, model);
      setFormSending(false);
      handleOnClose();
    } catch (e) {
      setServerError(e.error.message);
      setFormSending(false);
    };
  };

  const updateCheckboxActions = {
    status: {
      checked: () => updateValues({ status: 1 }),
      unchecked: () => updateValues({ status: null })
    },
    bid: {
      checked: () => updateValues({ minBid: null, maxBid: null }),
      unchecked: () => {
        updateValues({ minBid: null, maxBid: null });
        removeError('maxBid');
      }
    },
    dailyPacing: {
      checked: () => updateValues({ pacingType: 'asap', pacingInterval: 'day' }),
      unchecked: () => {
        updateValues({ pacingType: null, pacingInterval: null, pacingAmount: null });
        removeError('pacingAmount');
      }
    },
    frequencyCap: {
      checked: () => updateValues({
        frequencyOptimization: 1
      }),
      unchecked: () => {
        updateValues({
          frequencyOptimization: null,
          frequencyType: null,
          frequencyAmount: null,
          frequencyInterval: null
        });
        removeError('frequencyAmount');
      }
    }
  };

  const handleCheckboxChange = useCallback((_e, { name, checked }) => {
    if (checked) {
      setCheckedFields((prevFields) => [...prevFields, name]);
      updateCheckboxActions[name].checked();
    } else {
      setCheckedFields((prevFields) => prevFields.filter((field) => field !== name));
      updateCheckboxActions[name].unchecked();
    }
  }, [updateCheckboxActions]);

	const postValidation = (_e, flds) => {
    const errors = {};
    const { frequencyAmount, minBid, maxBid, pacingAmount } = flds;

		if(pacingAmount && !Pacing.isValid(pacingAmount)) {
			errors["pacingAmount"] = formatMessage({
				id: "ERROR_PACING_OUTSIDE_MIN_MAX",
				defaultMessage: "Pacing amount must be a positive number between {min} and {max}",
			}, {
				min: Pacing.MIN,
				max: Pacing.MAX,
			});
		}

		if(frequencyAmount && !Frequency.isValid(frequencyAmount)) {
			errors["frequencyAmount"] = formatMessage({
				id: "ERROR_FREQUENCY_AMOUNT_OUTSIDE_MIN_MAX",
				defaultMessage: "Frequency amount must be a positive number between {min} and {max}",
			}, {
				min: Frequency.MIN,
				max: Frequency.MAX,
			});
		}

		if (minBid && maxBid) {
			if (parseFloat(minBid) > parseFloat(maxBid)) {
				errors["maxBid"] = formatMessage({
					id: "ERROR_MIN_BID_GT_MAX_BID",
					defaultMessage: "Max Bid must be greater than or equal Min Bid",
				});
			}
		}

		return errors;
	};

  const clearMessages = () => {
    setServerError("");
  }

  const {
		values,
		errors,
    onSwitch,
		updateValues,
		onChange,
		onSubmit,
    removeError
	} = useForm(editBulkStrategiesForm, initialData, postValidation);

  const handleOnClose = useCallback(() => {
    fields.forEach((field) => {
      updateCheckboxActions[field].unchecked();
    });
    setCheckedFields([]);
    clearMessages();
    setIsBulkEditModalOpen(false);
  }, [fields, updateCheckboxActions]);

  const formActions = useMemo(() => ({
    onSwitch,
    onChange,
    onSubmit,
    handleOnClose,
    handleCheckboxChange,
    updateValues
  }), [onSwitch, onChange, onSubmit, handleOnClose, handleCheckboxChange, updateValues]);

  const formStates = useMemo(() => ({
    values,
    errors,
    formSending,
    selectedItems: selectedStrategies,
    checkedFields
  }), [values, errors, formSending, selectedStrategies, checkedFields]);

  return (
    <BulkEditModalComponent
      isBulkEditModalOpen={isBulkEditModalOpen}
      setIsBulkEditModalOpen={setIsBulkEditModalOpen}
      type={"strategy"}
      serverError={serverError}
      formActions={formActions}
      formStates={formStates}
      fields={fields}
    />
  );
};
