import { subYears } from "date-fns";
import subDays from "date-fns/subDays";
import PropTypes from "prop-types";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { Dropdown } from "semantic-ui-react";
import { date_ranges } from "../campaign/index/models/filter";

const DateRangeControl = ({ dataTestid, filter, onChange }) => {
  const intl = useIntl();
  const [reportDateRanges, setReportDateRanges] = useState({
    end_date: filter.end_date,
    start_date: filter.start_date,
  });

  /**
   * update reporting period
   * @param e
   * @param option
   */
  const daysLookup = {
    "7days": 7,
    "14days": 14,
    "30days": 30,
  };

  const handleReportingPeriod = (_e, option) => {
    const daysToSubtract = daysLookup[option.value] || 1;

    setReportDateRanges({
      end_date: new Date(),
      start_date: subDays(new Date(), daysToSubtract),
    });

    filter.setReportingPeriod(option.value);
  };

  /**
   * handler calendar on change statement
   */
  const onChangeDateRanges = (dates) => {
    const [start, end] = dates;
    setReportDateRanges({
      end_date: end,
      start_date: start,
    });
  };

  /**
   * handler calendar on close
   */
  const onCloseDateRanges = () => {
    const filterDate = { ...reportDateRanges };
    if (reportDateRanges.end_date == null) {
      setReportDateRanges({
        ...reportDateRanges,
        end_date: reportDateRanges.start_date,
      });
      filterDate.end_date = reportDateRanges.start_date;
    }

    filter.setStartDate(filterDate.start_date);
    filter.setEndDate(filterDate.end_date);
    onChange(filter.toJson());
  };

  const CustomInput = forwardRef((props, ref) => {
    return (
      <input
        {...props}
        data-testid={dataTestid ? dataTestid : "date_range_filter"}
        ref={ref}
        type="text"
      />
    );
  });

  return (
    <DatePicker
      customInput={<CustomInput />}
      endDate={reportDateRanges.end_date}
      maxDate={new Date()}
      minDate={subYears(new Date(), 1)}
      onCalendarClose={onCloseDateRanges}
      onChange={onChangeDateRanges}
      selected={reportDateRanges.start_date}
      selectsRange
      startDate={reportDateRanges.start_date}
    >
      <div>
        <Dropdown
          name="reporting_period"
          onChange={handleReportingPeriod}
          options={date_ranges(intl)}
          selection
          style={{ display: "flex", margin: "5px" }}
          value={filter.reporting_period}
        />
      </div>
    </DatePicker>
  );
};

DateRangeControl.propTypes = {
  dataTestid: PropTypes.string,
  filter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateRangeControl;
