import React, {useState} from "react";
import {Divider, Form, Header, Message, Segment, Select, Icon, Button, Popup} from "semantic-ui-react";
import {NavigationButtonDiv} from "./navigation_buttons";
import {Steps} from "../../../../models/enum/strategy";
import StrategyManageContext from "../context";
import StrategiesService from "../../../../services/strategy";
import * as uuid from "uuid";
import {dayparts_days, dayparts_end_hours, dayparts_start_hours} from "../../fixtures";
import PropTypes from "prop-types";
import {isNil} from "../../../../libs/common_utils";
import {useForm} from "../../../../libs/component_utils";
import StrategyDaypart from "../../../../models/strategy_daypart";
import {Days} from "../../../../models/enum/days";
import { useIntl } from "react-intl";

const DaypartContext = React.createContext();
export const DaypartsStep = () => {
	const [serverError, setServerError] = React.useState();
	const [loading, setLoading] = useState(true);
	const context = React.useContext(StrategyManageContext);
	const service_strategy = context.services.current.get("strategies");
	const strategy_id = parseInt(context.strategy_id || 0, 10);
	const is_t1_edit = (service_strategy instanceof StrategiesService);
	const data_exists_in_db = React.useRef(false);
	const [values, setValues] = React.useState([]);
	const [formSending, setFormSending] = useState(false);
	const intl = useIntl();

	let _isMounted = React.useRef(true);

	let get_continue = React.useRef(true);

	const dayparts_storage = React.useRef([]);

	/**
	 * update static storage
	 * @param {array} dayparts
	 */
	const updateDayparts = dayparts => {
		dayparts_storage.current = dayparts;
	};

	// load data when necessary
	React.useEffect(() => {
		(async () => {
			try {
				const r = await service_strategy.getDaypart(strategy_id);
				let dayparts = r.data.dayparts;
				dayparts = dayparts.map(n => ({...n, "key": uuid()}));

				data_exists_in_db.current = Boolean(dayparts.length);
				setValues(dayparts);
			} catch (e) {
				console.error(e);
				setServerError(e.error.message);
			} finally {
				if(_isMounted.current) {
					setLoading(false);
				}
			}
		})();

		return () => {
			_isMounted.current = false;
		}
	}, [strategy_id]);

	/**
	 * handle form submit
	 * @return
	 */
	const onSubmit = async () => {
		try {
			setServerError("");
			setFormSending(true);
			const params = {"dayparts": dayparts_storage.current.map(n => StrategyDaypart.fromJson(n).toJson())};

			// check for error exists
			if(params.dayparts.filter(x => x.start_hour > x.end_hour).length > 0) {
				return;
			}

			if (data_exists_in_db.current || is_t1_edit) {
				await service_strategy.updateDaypart(
					strategy_id,
					params
				);
			} else {
				await service_strategy.createDaypart(
					strategy_id,
					params
				);
			}

			if(get_continue.current) {
				context.stepNavigation.passDayparts(strategy_id);
			} else {
				context.strategySuccessfullyUpdated(context.strategy.strategy_name);
			}
		} catch (e) {
			console.error(e);
			setServerError(e.error.message);
		} finally {
			if (_isMounted.current) {
				setFormSending(false);
			}
		}
		return false;
	};

	/**
	 * Save local strategy
	 * @param {boolean} is_continue
	 */
	const setOperationType = (is_continue=true) => {
		get_continue.current = is_continue;
	};

	if(loading) {
		return (<Segment disabled tertiary textAlign="center" className="loading" style={{"height": "300px"}}>
			&nbsp;
		</Segment>);
	}

	return <Segment basic>
		<Message
			style={{ "marginTop": "10px" }}
			error
			hidden={!serverError}
			size="tiny"
			content={serverError}
		/>
		<Form id="daypart_form" onSubmit={onSubmit} loading={formSending}>
			<Header as="h3">
				{intl.formatMessage({
					id: "HEADING_DAYPART",
					defaultMessage: "Daypart",
				})}
			</Header>
			<div>
				{intl.formatMessage({
					id: "BODY_DAYPART",
					defaultMessage: "Your ads will only run during these times. Based on end user time zone.",
				})}
			</div>
			<Segment basic style={{"paddingLeft": 0}}>
				<DaypartManager selected={values}
								updateDayparts={updateDayparts} />
			</Segment>
			<Divider hidden />
			<Divider hidden />
			<Divider hidden />
			<NavigationButtonDiv
				loading={false}
				step={Steps.DAYPARTS}
				isPG={context.campaign.is_pg}
				onBackClick={() => context.stepNavigation.backToLocations(strategy_id)}
				onContinueClick={() => context.stepNavigation.passAudiences(strategy_id)}
				onSave={setOperationType}
				is_t1_edit={is_t1_edit}
				onCancelClick={context.getBack}
				skip_save_buttons={false}
			/>
		</Form>
	</Segment>;
};

const DaypartManager = React.memo(({selected, updateDayparts}) => {
	const intl = useIntl();

	/**
	 * generate default object
	 * @number {number} defaultID
	 * @return {object}
	 */
	const getDefaultData = defaultID => {
		return {
			"id": (!isNil(defaultID))? defaultID : (dayparts.length + 1),
			"key": uuid(),
			"days": Days.DEFAULT,
			"start_hour": 0,
			"end_hour": 23,
			"user_time": 1
		}
	};

	const [dayparts, setDayparts] = React.useState([getDefaultData(0)]);

	React.useEffect(() => {
		if(selected.length > 0) {
			setDayparts(selected);
		}
	}, [selected]);

	/**
	 * insert new block
	 */
	const insert = () => {
		setDayparts([
			...dayparts,
			getDefaultData()
		])
	};

	/**
	 * update existing block
	 * @param {object} values
	 */
	const update = values => {
		const i = dayparts.findIndex(n => n.key === values.key);
		dayparts[i] = Object.assign(dayparts[i], {
			...values,
			"start_hour": parseInt(values.start_hour, 10),
			"end_hour": parseInt(values.end_hour, 10)
		});
		updateDayparts(dayparts);
	};

	/**
	 * remove existing block
	 * @param {number} id
	 */
	const remove = id => {
		// verify if we have the only element ignore this method
		if(dayparts.length === 1) {
			return;
		}

		const i = dayparts.findIndex(n => n.id === id);
		if(!isNil(i)) {
			const tmp = [...dayparts];
			tmp.splice(i, 1);
			setDayparts(tmp);
			updateDayparts(tmp);
		}
	};

	/**
	 * reset whole component to default state
	 */
	const reset = () => {
		setDayparts([getDefaultData(0)]);
	};

	return <DaypartContext.Provider value={{
		insert, update, remove, "canBeRemoved": Boolean(dayparts.length > 1)
	}}>
		<Form.Group inline>
			<Form.Field style={{"width": "200px"}}>
				<label>
					{intl.formatMessage({
						id: "LABEL_DAYS",
						defaultMessage: "Days",
					})}
				</label>
			</Form.Field>
			<Form.Field style={{"width": "130px"}}>
				<label>
					{intl.formatMessage({
						id: "LABEL_START_HOUR",
						defaultMessage: "Start Hour",
					})}
				</label>
			</Form.Field>
			<Form.Field style={{"width": "130px"}}>
				<label>
					{intl.formatMessage({
						id: "LABEL_END_HOUR",
						defaultMessage: "End Hour",
					})}
				</label>
			</Form.Field>
		</Form.Group>
		{dayparts.map(n => <DaypartItem daypart={n} key={uuid()} />)}
		<Form.Field>
			<Button size="mini" primary type="button" onClick={insert}>
				{intl.formatMessage({
					id: "BTN_ADD",
					defaultMessage: "Add",
				})}
			</Button>
			<Button size="mini" type="button" onClick={reset}>
				{intl.formatMessage({
					id: "BTN_RESET_DAYPART",
					defaultMessage: "Run on all days",
				})}
			</Button>
			<Popup
				inverted
				content={intl.formatMessage({
					id: "HINT_RESET_DAYPART",
					defaultMessage: "Reset daypart to run this strategy 24/7",
				})}
				size="mini"
				trigger={<Icon name="info circle" className="cursor-help" />}
			/>
		</Form.Field>
	</DaypartContext.Provider>;
}, (prev, next) => {
	return JSON.stringify(prev.selected) === JSON.stringify(next.selected);
});
DaypartManager.propTypes = {
	"selected": PropTypes.array.isRequired,
	"updateDayparts": PropTypes.func.isRequired
};

const DaypartItem = ({daypart}) => {
	const intl = useIntl();
	const context = React.useContext(DaypartContext);
	const {values, onChange} = useForm(() => {},
		daypart,
		() => {});

	const [error, setError] = React.useState("");

	React.useEffect(() => {
		const start_hour = parseInt(values.start_hour, 10),
			end_hour = parseInt(values.end_hour, 10);

		if(start_hour > end_hour){
			setError(intl.formatMessage({
				id: "ERROR_START_HOUR_GT_END_HOUR",
				defaultMessage: "Start hour should be less than End hour",
			}));
		} else {
			setError("");
		}

		context.update(values);
	}, [values]);

	return <Form.Group inline>
		<Form.Field style={{"width": "200px"}}>
			<Select
				fluid
				name="days"
				options={dayparts_days(intl)}
				onChange={onChange}
				value={values.days}
			/>
		</Form.Field>
		<Form.Field style={{"width": "130px"}} error={!!error.length}>
			<Select
				fluid
				name="start_hour"
				options={dayparts_start_hours}
				onChange={onChange}
				value={values.start_hour.toString()}
			/>
		</Form.Field>
		<Form.Field style={{"width": "130px"}} error={!!error.length}>
			<Select
				fluid
				name="end_hour"
				options={dayparts_end_hours}
				onChange={onChange}
				value={values.end_hour.toString()}
			/>
		</Form.Field>
		<Form.Field className={!context.canBeRemoved? "invisible" : ""}>
			<Icon name="remove" className="cursor-pointer" onClick={() => context.remove(values.id)} />
		</Form.Field>
		<Form.Field className={values.user_time === 1? "invisible" : ""}>
			<Popup
				inverted
				content={intl.formatMessage({
					id: "HINT_DAYPART_TIMEZONE",
					defaultMessage: "This daypart is relative to US EST timezone",
				})}
				size="mini"
				trigger={<Icon name="info circle" className="cursor-help" />}
			/>
		</Form.Field>
		<Form.Field error className={!error.length? "invisible" : ""}>
			<Message color="red" size="mini" style={{"padding": "5px 10px"}}>{error}</Message>
		</Form.Field>
	</Form.Group>;
};
DaypartItem.propTypes = {
	"daypart": PropTypes.object.isRequired
};
