import React from "react";
import { Dropdown, Form, Input, Radio } from "semantic-ui-react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { frequency_intervals, frequency_options } from "../fixtures";
import {freqOptimizationEnabledByGoalType} from "../../../models/campaign";

const FrequencyCapComponent = ({onChange, errors, values}) => {
	const intl = useIntl();

	const amountIsDisabled =
		Boolean(values.frequency_optimization) ||
		values.frequency_type === "no-limit";

	const optimizedIsEnabled = freqOptimizationEnabledByGoalType(values.goal_type);

	return (
		<Form.Group inline>
			<Form.Field required>
				<label>
					{intl.formatMessage({
						id: "LABEL_FREQUENCY_CAP",
						defaultMessage: "Frequency Cap",
					})}
				</label>
			</Form.Field>
			<Form.Field>
				<Radio
					label={intl.formatMessage({
						id: "FREQUENCY_CAP_OPTIMIZED",
						defaultMessage: "Optimized",
					})}
					name="frequency_optimization"
					value={1}
					onClick={(e, n) => optimizedIsEnabled? onChange(e, n) : {}}
					disabled={!optimizedIsEnabled}
					checked={Boolean(values.frequency_optimization)}
				/>
				<Radio
					label={intl.formatMessage({
						id: "FREQUENCY_CAP_CUSTOM",
						defaultMessage: "Custom",
					})}
					name="frequency_optimization"
					value={0}
					onClick={onChange}
					checked={!Boolean(values.frequency_optimization)}
				/>
			</Form.Field>
			<Form.Field
				className="dropdown__125"
				disabled={Boolean(values.frequency_optimization)}
			>
				<Dropdown
					selection
					fluid
					name="frequency_type"
					options={frequency_options(intl)}
					onChange={onChange}
					value={values.frequency_type}
				/>
			</Form.Field>
			<Form.Field
				disabled={amountIsDisabled}
				error={errors.hasOwnProperty("frequency_amount")}
			>
				<Input
					type="number"
					name="frequency_amount"
					required={!amountIsDisabled}
					autoComplete="off"
					min={1}
					max={9_999_999}
					onBlur={onChange}
					defaultValue={values.frequency_amount}
				/>
				<div className="custom-error" style={{ "marginLeft": "0" }}>
					{errors["frequency_amount"]}
				</div>
			</Form.Field>
			<Form.Field className="dropdown__125" disabled={amountIsDisabled}>
				<Dropdown
					selection
					fluid
					name="frequency_interval"
					options={frequency_intervals(intl)}
					onChange={onChange}
					value={values.frequency_interval}
				/>
			</Form.Field>
		</Form.Group>
	);
};

FrequencyCapComponent.propTypes = {
	"values": PropTypes.object.isRequired,
	"onChange": PropTypes.func.isRequired,
	"errors": PropTypes.object.isRequired,
};

export default FrequencyCapComponent;
