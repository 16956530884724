import React from "react";
import StrategiesDraftService from "../../../../services/strategy/draft";
import {Steps} from "../../../../models/enum/strategy";
import {Icon, Modal} from "semantic-ui-react";
import * as uuid from "uuid";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";
import StrategiesService from "../../../../services/strategy";
import StrategyCreativesSelected from "../../../../models/strategy_creatives_selected";
import StrategyAudiencesSelected from "../../../../models/startegy_audiences";

export const StrategySaveComponent = ({save_state, strategy_id, navigation, resetModal, navigate_back}) => {
	const intl = useIntl();
	const [steps, setSteps] = React.useState(structuredClone(save_state));
	const service = React.useRef(new StrategiesDraftService());
	const service_t1 = React.useRef(new StrategiesService());

	/**
	 * reset steps and close modal
	 */
	const resetLastStep = () => {
		resetSteps();
		resetModal();
	};

	/**
	 * reset steps
	 */
	const resetSteps = () => {
		setSteps(structuredClone(save_state));
	};

	React.useEffect(() => {
		(async () => {
			let promises = [];
			const settings_response = await service.current.settings(strategy_id);
			const {t1_strategy_id, supplies_applied, creatives_applied,
				audiences_applied, targeting_applied, dayparts_applied,
				technology_applied} = settings_response["data"];

			for(let s of steps) {
				try {
					// eslint-disable-next-line default-case
					switch(s.step) {
						case(Steps.MARKETING_OBJECTIVES):
							s["link"] = () => navigation.backToMarketingObjective(strategy_id);

							let save_promise = undefined;
							if(t1_strategy_id) {
								save_promise = service.current.get(strategy_id).then(r => {
									service_t1.current.save(r["data"]);
								});
							} else {
								save_promise = service.current.createT1(strategy_id);
							}

							await save_promise.then(() => {
								s["status"] = "done";
							}).catch(e => {
								s["status"] = "error";
								s["error"] = e.error.message;
							}).finally(() => {
								setSteps([...steps])
							});
							break;

						case(Steps.SUPPLY):
							s["link"] = () => navigation.backToSupplies(strategy_id);

							let supplies_promise = undefined;
							if(supplies_applied) {
								supplies_promise = service.current.getSupplies(strategy_id).then(r => {
									return service_t1.current.updateSupplies(t1_strategy_id, r["data"]);
								});
							} else {
								supplies_promise = service.current.createT1Supply(strategy_id);
							}

							promises.push(supplies_promise.then(() => {
								s["status"] = "done";
							}).catch(e => {
								s["status"] = "error";
								s["error"] = e.error.message;
							}).finally(() => {
								setSteps([...steps])
							}));
							break;

						case(Steps.CREATIVES):
							s["link"] = () => navigation.backToCreatives(strategy_id);
							let creatives_promise = undefined;
							if(creatives_applied) {
								creatives_promise = service.current.getCreatives(strategy_id).then(r => {
									return service_t1.current.updateCreatives(t1_strategy_id,
										StrategyCreativesSelected.fromResponse(t1_strategy_id, r["data"]).toJson());
								});
							} else {
								creatives_promise = service.current.createT1Creatives(strategy_id);
							}
							promises.push(creatives_promise.then(() => {
								s["status"] = "done";
							}).catch(e => {
								s["status"] = "error";
								s["error"] = e.error.message;
							}).finally(() => {
								setSteps([...steps])
							}));
							break;

						case(Steps.AUDIENCES):
							s["link"] = () => navigation.backToAudiences(strategy_id);

							let audiences_promise = undefined;
							if(audiences_applied) {
								audiences_promise = service.current.getAudiences(strategy_id).then(r => {
									return service_t1.current.updateAudiences(t1_strategy_id,
										StrategyAudiencesSelected.fromResponse(r["data"]).toJson());
								});
							} else {
								audiences_promise = service.current.createT1Audiences(strategy_id);
							}

							promises.push(audiences_promise.then(() => {
								s["status"] = "done";
							}).catch(e => {
								s["status"] = "error";
								s["error"] = e.error.message;
							}).finally(() => {
								setSteps([...steps])
							}));
							break;

						case(Steps.LOCATIONS):
							s["link"] = () => navigation.backToLocations(strategy_id);

							let locations_promise = undefined;
							if(targeting_applied) {
								const location_response = await service.current.getTargets(strategy_id);
								locations_promise = service_t1.current.updateTargets(t1_strategy_id, location_response["data"]);
							} else {
								locations_promise = service.current.createT1Targets(strategy_id);
							}

							promises.push(locations_promise.then(() => {
								s["status"] = "done";
							}).catch(e => {
								s["status"] = "error";
								s["error"] = e.error.message;
							}).finally(() => {
								setSteps([...steps])
							}));
							break;

						case(Steps.DAYPARTS):
							s["link"] = () => navigation.backToDayparts(strategy_id);

							let daypart_promise = undefined;
							if(dayparts_applied) {
								daypart_promise = service.current.getDaypart(strategy_id).then(r => {
									return service_t1.current.updateDaypart(t1_strategy_id, r["data"]);
								});
							} else {
								daypart_promise = service.current.createT1Daypart(strategy_id);
							}

							promises.push(daypart_promise.then(() => {
								s["status"] = "done";
							}).catch(e => {
								s["status"] = "error";
								s["error"] = e.error.message;
							}).finally(() => {
								setSteps([...steps])
							}));
							break;

						case(Steps.TECHNOLOGY):
							s["link"] = resetLastStep;

							let technology_promise = undefined;
							if(technology_applied) {
								technology_promise = service.current.getTechnology(strategy_id).then(r => {
									return service_t1.current.updateTechnology(t1_strategy_id, r["data"]);
								});
							} else {
								technology_promise = service.current.createT1TargetsTechnologies(strategy_id);
							}

							promises.push(technology_promise.then(() => {
								s["status"] = "done";
							}).catch(e => {
								s["status"] = "error";
								s["error"] = e.error.message;
							}).finally(() => {
								setSteps([...steps])
							}));
							break;
					}
				} catch (e) {
					console.error(e);
				}
			}

			const results = await Promise.allSettled(promises);
			let success = !results.some(x => x.status === "rejected") && !steps.some(x => x.status === "error");

			if(success) {
				setTimeout(() => {
					resetSteps();
					navigate_back();
				}, 500);
			}
		})();
	}, [strategy_id]);

	// reset steps when user leave the page
	React.useEffect(() => {
		return () => {
			resetSteps();
		}
	}, [])

	return <Modal open={true} dimmer="blurring" className="auth0_modal">
		<Modal.Header>
			{intl.formatMessage({
				id: "HEADING_CREATING_STRATEGY",
				defaultMessage: "Create strategy",
			})}
		</Modal.Header>
		<Modal.Content>
			{steps.map(s => <StrategySaveStepComponent key={uuid()} node={s} />)}
		</Modal.Content>
	</Modal>;
};
StrategySaveComponent.propTypes = {
	"strategy_id": PropTypes.number.isRequired,
	"navigation": PropTypes.object.isRequired,
	"resetModal": PropTypes.func.isRequired,
	"campaign_id": PropTypes.number.isRequired,
	"navigate_back": PropTypes.func
};

const StrategySaveStepComponent = ({node}) => {
	const intl = useIntl();

	switch(node.status) {
		case("done"):
			return (
				<div>
					<Icon name="check" color="green" />{" "}
					{intl.formatMessage({ id: node.messageId })}
				</div>
			);

		case("error"):
			return (
				<div>
					<Icon name="times" color="red" /> {node.error} (
					<button type="button" className="pseudo-link" onClick={node.link}>
						{intl.formatMessage({
							id: "BTN_FIX_THIS",
							defaultMessage: "fix this",
						})}
					</button>
					)
				</div>
			);

		default:
			return (
				<div className="spinner">
					{intl.formatMessage({ id: node.messageId })}
				</div>
			);
	}
};
StrategySaveStepComponent.propTypes = {
	"node": PropTypes.object.isRequired
};
