import AbstractService from "../abstract.service";

export default class StrategiesDraftService extends AbstractService {
  static URI_STATES = new Map([
    ["strategies.get", "/build-strategy/{0}"],
    ["strategies.update", "/build-strategy/{0}"],
    ["strategies.save", "/build-strategy"],
    ["strategies.supplies", "/build-strategy/{0}/supplies"],
    ["strategies.creatives", "/build-strategy/{0}/creatives"],
    ["strategies.audiences", "/build-strategy/{0}/audiences"],
    ["strategies.targets", "/build-strategy/{0}/targeting"],
    ["strategies.daypart", "/build-strategy/{0}/daypart"],
    ["strategies.technology", "/build-strategy/{0}/technology"],

    ["strategies.settings", "/strategy_settings/{0}"],

    ["strategies.create_t1", "/create-strategy/{0}"],
    ["strategies.create_t1_supplies", "/create-strategy/{0}/supplies"],
    ["strategies.create_t1_creatives", "/create-strategy/{0}/creatives"],
    ["strategies.create_t1_audiences", "/create-strategy/{0}/audiences"],
    ["strategies.create_t1_targets", "/create-strategy/{0}/targeting"],
    ["strategies.create_t1_daypart", "/create-strategy/{0}/daypart"],
    ["strategies.create_t1_technology", "/create-strategy/{0}/technology"],
    ["strategies.create_t1_targets_technology", "/create-strategy/{0}/targeting-technology"],

    ["strategies.supply_rules", "/build-supply-rules/{0}"],
  ]);

  /**
   * Get supply rule for a workflow, which describes should be white list used or regular one
   * @param {number} strategyId
   * @param {string} workflow
   * @returns {Promise<void>}
   */
  async getSupplyRules(strategyId, workflow) {
    return await this.api_server.get(
      StrategiesDraftService.URI_STATES.get("strategies.supply_rules").format(strategyId),
      {
        workflow,
      },
    );
  }

  /**
   * Get strategy settings
   * @param {number} strategyId
   * @returns {Promise<any | never>}
   */
  async settings(strategyId) {
    return await this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.settings").format(strategyId));
  }

  /**
   * Get technology from local DB
   * @param {number} strategyId
   * @returns {Promise<any | never>}
   */
  async getTechnology(strategyId) {
    return await this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.technology").format(strategyId));
  }

  /**
   * Save technology into local DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async createTechnology(strategyId, params) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.technology").format(strategyId),
      params,
    );
  }

  /**
   * Save technology into local DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async updateTechnology(strategyId, params) {
    return await this.api_server.put(
      StrategiesDraftService.URI_STATES.get("strategies.technology").format(strategyId),
      params,
    );
  }

  /**
   * Get daypart from local DB
   * @param {number} strategyId
   * @returns {Promise<any | never>}
   */
  async getDaypart(strategyId) {
    return await this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.daypart").format(strategyId));
  }

  /**
   * Save daypart into local DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async createDaypart(strategyId, params) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.daypart").format(strategyId),
      params,
    );
  }

  /**
   * Save daypart into local DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async updateDaypart(strategyId, params) {
    return await this.api_server.put(
      StrategiesDraftService.URI_STATES.get("strategies.daypart").format(strategyId),
      params,
    );
  }

  /**
   * Get audiences from local DB
   * @param {number} strategyId
   * @returns {Promise<any | never>}
   */
  async getAudiences(strategyId) {
    return await this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.audiences").format(strategyId));
  }

  /**
   * Save audiences into local DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async createAudiences(strategyId, params) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.audiences").format(strategyId),
      params,
    );
  }

  /**
   * Save audiences into local DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async updateAudiences(strategyId, params) {
    return await this.api_server.put(
      StrategiesDraftService.URI_STATES.get("strategies.audiences").format(strategyId),
      params,
    );
  }

  /**
   * Get targets from local DB
   * @param {number} strategyId
   * @returns {Promise<any | never>}
   */
  async getTargets(strategyId) {
    return await this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.targets").format(strategyId));
  }

  /**
   * Save targets into local DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async createTargets(strategyId, params) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.targets").format(strategyId),
      params,
    );
  }

  /**
   * Save targets into local DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async updateTargets(strategyId, params) {
    return await this.api_server.put(
      StrategiesDraftService.URI_STATES.get("strategies.targets").format(strategyId),
      params,
    );
  }

  /**
   * Get draft supplies
   * @param {number} strategyId
   * @return {Promise<>}
   */
  async getSupplies(strategyId) {
    return await this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.supplies").format(strategyId));
  }

  /**
   * Create draft supplies
   * @param {number} strategyId
   * @param {object} params
   * @return {Promise<>}
   */
  async createSupplies(strategyId, params) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.supplies").format(strategyId),
      params,
    );
  }

  /**
   * Update existing draft supplies
   * @param {number} strategyId
   * @param {object} params
   * @return {Promise<>}
   */
  async updateSupplies(strategyId, params) {
    return await this.api_server.put(
      StrategiesDraftService.URI_STATES.get("strategies.supplies").format(strategyId),
      params,
    );
  }

  /**
   * Get draft creatives
   * @param {number} strategyId
   * @return {Promise<>}
   */
  async getCreatives(strategyId) {
    return await this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.creatives").format(strategyId));
  }

  /**
   * Create draft creatives
   * @param {number} strategyId
   * @param {object} params
   * @return {Promise<>}
   */
  async createCreatives(strategyId, params) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.creatives").format(strategyId),
      params,
    );
  }

  /**
   * Update existing draft creatives
   * @param {number} strategyId
   * @param {object} params
   * @return {Promise<>}
   */
  async updateCreatives(strategyId, params) {
    return await this.api_server.put(
      StrategiesDraftService.URI_STATES.get("strategies.creatives").format(strategyId),
      params,
    );
  }

  /**
   * Get draft version of strategy
   * @param {number} strategyId
   * @return {Promise<>}
   */
  async get(strategyId) {
    return await this.api_server.get(StrategiesDraftService.URI_STATES.get("strategies.get").format(strategyId));
  }

  /**
   * store local strategy
   * @param params
   * @return {Promise<>}
   */
  async save(params) {
    if (!params.id) {
      return await this.add(params);
    }

    return await this.update(params.id, params);
  }

  /**
   * add new local strategy
   * @param params
   * @return {Promise<>}
   */
  async add(params) {
    return await this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.save"), params);
  }

  /**
   * update existing strategy
   * @param id
   * @param params
   * @return {Promise<>}
   */
  async update(id, params) {
    return await this.api_server.put(StrategiesDraftService.URI_STATES.get("strategies.update").format(id), params);
  }

  /**
   * create strategy in t1
   * @param {number} id
   * @return {Promise}
   */
  async createT1(id) {
    return await this.api_server.post(StrategiesDraftService.URI_STATES.get("strategies.create_t1").format(id), {});
  }

  /**
   * create strategy supply in t1
   * @param {number} id
   * @return {Promise}
   */
  async createT1Supply(id) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.create_t1_supplies").format(id),
      {},
    );
  }

  /**
   * create strategy creatives in t1
   * @param {number} id
   * @return {Promise}
   */
  async createT1Creatives(id) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.create_t1_creatives").format(id),
      {},
    );
  }

  /**
   * create strategy audiences in t1
   * @param {number} id
   * @return {Promise}
   */
  async createT1Audiences(id) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.create_t1_audiences").format(id),
      {},
    );
  }

  /**
   * create strategy targets in t1
   * @param {number} id
   * @return {Promise}
   */
  async createT1Targets(id) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.create_t1_targets").format(id),
      {},
    );
  }

  /**
   * Save targets and technologies in one call
   * @param {number} if
   * @returns {Promise<any | never>}
   */
  async createT1TargetsTechnologies(id) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.create_t1_targets_technology").format(id),
      {},
    );
  }

  /**
   * create strategy daypart in t1
   * @param {number} id
   * @return {Promise}
   */
  async createT1Daypart(id) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.create_t1_daypart").format(id),
      {},
    );
  }

  /**
   * create strategy technology in t1
   * @param {number} id
   * @return {Promise}
   */
  async createT1Technology(id) {
    return await this.api_server.post(
      StrategiesDraftService.URI_STATES.get("strategies.create_t1_technology").format(id),
      {},
    );
  }
}
