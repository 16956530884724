import AbstractService from "../abstract.service";

export default class MarginsService extends AbstractService {
  static URI_STATES = new Map([
    ["margins.list", "/margin-controls"],
    ["margins.get", "/margin-control/{0}"],
    ["margins.put", "/margin-control/{0}"],
    ["margins.post", "/margin-control"],
    ["margins.delete", "/margin-control/{0}"],
  ]);

  /**
   * List available margin controls.
   * @param {number} agency
   * @param {object} params
   * @return {Promise<>}
   */
  async list(params) {
    return await this.api_server.get(MarginsService.URI_STATES.get("margins.list"), params);
  }

  /**
   * Create a margin control.
   * @param {object} params
   * @return {Promise<>}
   */
  async create(params) {
    return await this.api_server.post(MarginsService.URI_STATES.get("margins.post"), params);
  }

  /**
   * Get margin control by id.
   * @param {number} id
   */
  async get(id) {
    return await this.api_server.get(MarginsService.URI_STATES.get("margins.get").format(id));
  }

  /**
   * Update a margin control.
   * @param {number} id
   * @param {object} params
   * @return {Promise<>}
   */
  async update(id, params) {
    return await this.api_server.put(MarginsService.URI_STATES.get("margins.put").format(id), params);
  }

  /**
   * Delete a margin control.
   * @param {number} id
   * @return {Promise<>}
   */
  async delete(id) {
    return await this.api_server.delete(MarginsService.URI_STATES.get("margins.delete").format(id));
  }
}
