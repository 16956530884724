import { omit } from "lodash";
import { formatAPIDateTime } from "../../libs/common_utils";
import AbstractService from "../abstract.service";

export default class CampaignsService extends AbstractService {
  static URI_STATES = new Map([
    ["campaigns.duplicate", "/duplicate"],
    ["campaigns.get", "/campaign/{0}"],
    ["campaigns.data", "/campaigns-data2/{0}"],
    ["campaigns.by_advertiser", "/campaigns/{0}"],
    ["campaigns.create", "/campaign"],
    ["campaigns.update", "/campaign/{0}"],
    ["campaigns.bulk_edit_campaigns", "/bulk-update/campaigns"],
  ]);

  /**
   * Get campaign by id
   * @param {number} id
   * @return {Promise}
   */
  async get(id) {
    return await this.api_server.get(CampaignsService.URI_STATES.get("campaigns.get").format(id));
  }

  /**
   * Get campaigns by advertiser
   * @param {number} advertiser
   * @param {object} params
   * @return {Promise}
   */
  async getByAdvertiser(advertiser, params) {
    return await this.api_server.get(
      CampaignsService.URI_STATES.get("campaigns.by_advertiser").format(advertiser),
      params,
    );
  }

  /**
   * Gets campaigns via /campaigns-data-2
   * @param {number} agency_id
   * @param {object} params
   * @returns {Promise<object>}
   */
  async list(agencyId, params) {
    return await this.api_server.get(CampaignsService.URI_STATES.get("campaigns.data").format(agencyId), params);
  }

  /**
   * Create an agency
   * @param {object} values - values of the create campaign form
   * @returns {object} created campaign
   */
  async create(values) {
    const flights = this._getFlightsData(values);

    let data = {
      ...values,
      budget_flights: flights,
      status: values.status ? 1 : 0,
      spend_cap_automatic: values.spend_cap_automatic ? 1 : 0,
      goal_value: Number.parseFloat(values.goal_value || 0),
      spend_cap_amount: Number.parseFloat(values.spend_cap_amount || 0),
      margin_pct: values.margin_pct === null ? null : Number.parseFloat(values.margin_pct || 0),
      frequency_amount: Number.parseInt(values.frequency_amount || 0, 10),
    };

    if (data.site_list_ids.length === 1) {
      const id = data.site_list_ids[0];
      data.site_list_id = id;
    }

    data = this._removeUnnecessaryFields(data);

    return await this.api_server.post(CampaignsService.URI_STATES.get("campaigns.create"), data);
  }

  /**
   * Update campaign
   * @param {number} campaign_id
   * @param {object} params
   * @return {Promise}
   */
  async update(campaignId, params) {
    return await this.api_server.put(CampaignsService.URI_STATES.get("campaigns.update").format(campaignId), params);
  }

  async duplicate(campaignId) {
    return await this.api_server.post(CampaignsService.URI_STATES.get("campaigns.duplicate"), {
      type: "campaign",
      entity_id: campaignId,
    });
  }

  /**
   * Bulk edits campaigns
   * @param {object} params
   * @return {Promise<>}
   */
  async bulkEditCampaigns(params) {
    return await this.api_server.post(CampaignsService.URI_STATES.get("campaigns.bulk_edit_campaigns"), params);
  }

  _getFlightsData(values) {
    return Object.values(values.budget_flights).map((flight) => {
      const data = {
        total_budget: Number.parseFloat(flight.total_budget),
        start_date: formatAPIDateTime(flight.start_date),
        end_date: formatAPIDateTime(flight.end_date),
      };

      if (flight.total_impression_budget)
        data.total_impression_budget = Number.parseFloat(flight.total_impression_budget);

      return data;
    });
  }

  /**
   * Right now backend attempts to validate some values even if they aren't used, but does work alright if they aren't present at all, so we delete them as a temporary measure
   * @param {object} data - data that is to be sent to the API
   * @param {object} values - values of the create campaign form
   */
  _removeUnnecessaryFields(data) {
    const keysToOmit = [];

    if (data.margin_pct === null) {
      keysToOmit.push("margin_pct");
    }

    if (data.campaign_type.toLowerCase() === "pg") {
      keysToOmit.push(
        "spend_cap_type",
        "spend_cap_automatic",
        "spend_cap_amount",
        "frequency_optimization",
        "frequency_type",
        "frequency_amount",
        "frequency_interval",
        "site_list_id",
      );
    } else {
      if (data.spend_cap_type === "no-limit") {
        keysToOmit.push("spend_cap_automatic", "spend_cap_amount");
      } else if (data.spend_cap_automatic === 1) {
        keysToOmit.push("spend_cap_amount");
      }

      if (data.frequency_optimization) {
        keysToOmit.push("frequency_type", "frequency_amount", "frequency_interval");
      } else if (data.frequency_type === "no-limit") {
        keysToOmit.push("frequency_amount", "frequency_interval");
      }
    }

    keysToOmit.push("site_list_ids");

    return omit(data, keysToOmit);
  }
}
