export default class StrategyAudiencesSelected {
  constructor(included, excluded) {
    this.include = included || [];
    this.exclude = excluded || [];
  }

  /**
   * Parse data from API into a model instance
   * @param {object} json
   */
  static fromResponse({ include, exclude }) {
    const localIncluded = { ...include };
    const localExcluded = { ...exclude };

    if (!Object.hasOwn(localIncluded, "pixels")) {
      localIncluded.pixels = {};
    }

    if (!Object.hasOwn(localIncluded.pixels, "entities")) {
      localIncluded.pixels = {
        operator: "AND",
        entities: [],
      };
    }

    localIncluded.pixels.entities = localIncluded.pixels.entities.map((node) => {
      return node.id;
    });

    for (const node of localIncluded.custom_audiences) {
      node.entities = node.entities.map((entity) => {
        return entity.id;
      });
    }

    localExcluded.custom_audiences = localExcluded.custom_audiences.map((node) => {
      return node.id;
    });

    localExcluded.pixels = localExcluded.pixels.map((node) => {
      return node.id;
    });

    return new StrategyAudiencesSelected(localIncluded, localExcluded);
  }

  /**
   * Convert model instance into API format
   * @return {object}
   */
  toJson() {
    return {
      include: this.include,
      exclude: this.exclude,
    };
  }
}
