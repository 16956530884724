import { DimensionCodes } from "../../models/enum/dimension_codes";
import AbstractService from "../abstract.service";

export default class StrategiesService extends AbstractService {
  static URI_STATES = new Map([
    ["strategies.duplicate", "/duplicate"],
    ["strategies.data", "/strategies-data/{0}"],
    ["strategies.get", "/strategy/{0}"],
    ["strategies.open_supplies", "/strategy/open-supplies"],
    ["strategies.supplies_by_advertiser", "/strategy/supplies/{0}"],
    ["strategies.supplies.post", "/build-strategy/{0}/supplies"],
    ["strategy.supplies", "/strategy/{0}/supplies"],
    ["strategy.creatives", "/strategy/{0}/creatives"],
    ["strategy.targets", "/strategy/{0}/targeting"],
    ["strategy.audiences", "/strategy/{0}/audiences"],
    ["strategy.daypart", "/strategy/{0}/daypart"],

    ["strategy.technologies", "/strategy/{0}/technology"],
    ["strategy.browsers", "/strategy/browsers"],
    ["strategy.inventory", "/strategy/inventory"],
    ["strategy.devices", "/strategy/devices"],
    ["strategy.os", "/strategy/os"],
    ["strategy.models", "/strategy/models"],
    ["strategy.open_supplies", "/strategy/open_supplies"],

    ["strategies.supply_rules", "/supply-rules/{0}"],
    ["strategies.bulk_edit_strategies", "/bulk-update/strategies"],
  ]);

  /**
   * Get supply rule for a workflow, which describes should be white list used or regular one
   * @param {number} strategyId
   * @param {string} workflow
   * @returns {Promise<void>}
   */
  async getSupplyRules(strategyId, workflow) {
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategies.supply_rules").format(strategyId), {
      workflow,
    });
  }

  /**
   * get open supplies
   * @param {string} rtbType
   * @param {string} strategyType
   * @param {string} searchQuery
   * @return {Promise<any | never>}
   */
  async getOpenSupplies(rtbType, strategyType, searchQuery = "") {
    // biome-ignore lint/style/useNamingConvention: <server prop>
    const params = { rtb_type: rtbType, strategy_type: strategyType };
    if (searchQuery) {
      params.supply_name = searchQuery;
    }
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategies.open_supplies"), params);
  }

  /**
   * Save technology into t1
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async createTechnology(strategyId, params) {
    return await this.api_server.post(
      StrategiesService.URI_STATES.get("strategy.technologies").format(strategyId),
      params,
    );
  }

  /**
   * Save technology into t1
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async updateTechnology(strategyId, params) {
    return await this.api_server.put(
      StrategiesService.URI_STATES.get("strategy.technologies").format(strategyId),
      params,
    );
  }

  /**
   * Get technology from t1
   * @param {number} strategyId
   * @returns {Promise<any | never>}
   */
  async getTechnology(strategyId) {
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategy.technologies").format(strategyId));
  }

  /**
   * Get browsers from t1 DB
   * @param {string} search_query
   * @returns {Promise<any | never>}
   */
  async __getBrowsers(searchQuery = "") {
    const params = {};
    if (searchQuery) {
      params.technology_name = searchQuery;
    }
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategy.browsers"), params);
  }

  /**
   * Get inventory from t1 DB
   * @param {string} search_query
   * @returns {Promise<any | never>}
   */
  async __getInventory(searchQuery = "") {
    const params = {};
    if (searchQuery) {
      params.technology_name = searchQuery;
    }

    return await this.api_server.get(StrategiesService.URI_STATES.get("strategy.inventory"), params);
  }

  /**
   * Get devices from t1 DB
   * @param {number|string} parent_id
   * @param {string} search_query
   * @returns {Promise<any | never>}
   */
  async __getDevices(parentId = 0, searchQuery = "") {
    const params = {};
    // checks parentId if its number, parentId can be a UUID string which shouldnt be passed to API
    if (parentId && !Number.isNaN(Number(parentId))) {
      params.parent_id = parentId;
    }

    if (searchQuery) {
      params.technology_name = searchQuery;
    }
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategy.devices"), params);
  }

  /**
   * Get os from t1 DB
   * @param {number|string} parent_id
   * @param {string} search_query
   * @returns {Promise<any | never>}
   */
  async __getOS(parentId = 0, searchQuery = "") {
    const params = {};
    // checks parentId if its a number, parentId can be a UUID string which shouldnt be passed to API
    if (parentId && !Number.isNaN(Number(parentId))) {
      params.parent_id = parentId;
    }

    if (searchQuery) {
      params.technology_name = searchQuery;
    }
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategy.os"), params);
  }

  /**
   * Get models from t1 DB
   * @param {number|string} parent_id
   * @param {string} search_query
   * @returns {Promise<any | never>}
   */
  async __getModels(parentId = 0, searchQuery = "") {
    const params = {};
    // checks parentId if its a number, parentId can be a UUID string which shouldnt be passed to API
    if (parentId && !Number.isNaN(Number(parentId))) {
      params.parent_id = parentId;
    }

    if (searchQuery) {
      params.technology_name = searchQuery;
    }
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategy.models"), params);
  }

  /**
   * Facade to get data by its dimension code
   * @param {string} code
   * @param {number} parent_id
   * @param {string} search_query
   * @return {Promise}
   */
  async getDataByDimensionCode(code, parentId = 0, searchQuery = "") {
    switch (code) {
      case DimensionCodes.DVCE:
        return await this.__getDevices(parentId, searchQuery);

      case DimensionCodes.NMDM:
        return await this.__getModels(parentId, searchQuery);

      case DimensionCodes.INVT:
        return await this.__getInventory(searchQuery);

      case DimensionCodes.BSER:
        return await this.__getBrowsers(searchQuery);

      case DimensionCodes.NMOS:
        return await this.__getOS(parentId, searchQuery);

      default:
        throw new Error("No dimension code supported");
    }
  }

  /**
   * Get daypart from t1 DB
   * @param {number} strategyId
   * @returns {Promise<any | never>}
   */
  async getDaypart(strategyId) {
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategy.daypart").format(strategyId));
  }

  /**
   * Save daypart into t1 DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async updateDaypart(strategyId, params) {
    return await this.api_server.put(StrategiesService.URI_STATES.get("strategy.daypart").format(strategyId), params);
  }

  /**
   * Save daypart into t1 DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async createDaypart(strategyId, params) {
    return await this.updateDaypart(strategyId, params);
  }

  /**
   * Get targets from t1 DB
   * @param {number} strategyId
   * @returns {Promise<any | never>}
   */
  async getTargets(strategyId) {
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategy.targets").format(strategyId));
  }

  /**
   * Save targets into t1 DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async updateTargets(strategyId, params) {
    return await this.api_server.put(StrategiesService.URI_STATES.get("strategy.targets").format(strategyId), params);
  }

  /**
   * Save targets into t1 DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async createTargets(strategyId, params) {
    return await this.updateTargets(strategyId, params);
  }

  /**
   * Save audiences into t1 DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async createAudiences(strategyId, params) {
    return await this.updateAudiences(strategyId, params);
  }

  /**
   * Save audiences into t1 DB
   * @param {number} strategyId
   * @param {array} params
   * @returns {Promise<any | never>}
   */
  async updateAudiences(strategyId, params) {
    return await this.api_server.put(StrategiesService.URI_STATES.get("strategy.audiences").format(strategyId), params);
  }

  /**
   * Get audiences from t1 DB
   * @param {number} strategyId
   * @returns {Promise<any | never>}
   */
  async getAudiences(strategyId) {
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategy.audiences").format(strategyId));
  }

  /**
   * Save draft supplies
   * @param {number} strategyId
   * @param {object} params
   * @return {Promise<>}
   */
  async saveSupplies(strategyId, params) {
    return await this.api_server.post(
      StrategiesService.URI_STATES.get("strategies.supplies.post").format(strategyId),
      params,
    );
  }

  /**
   * Load list of strategies by campaign id
   * @param {number} campaignId
   * @param {object} params
   * @return {Promise<>}
   */
  async list(campaignId, params) {
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategies.data").format(campaignId), params);
  }

  /**
   * Get strategy by id
   * @param {number} strategyId
   * @return {Promise<>}
   */
  async get(strategyId) {
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategies.get").format(strategyId));
  }

  /**
   * Get list of supplies by advertiser
   * @param {number} advertiserId
   * @param {object} params
   * @return {Promise<>}
   */
  async suppliesByAdvertiser(advertiserId, params) {
    return await this.api_server.get(
      StrategiesService.URI_STATES.get("strategies.supplies_by_advertiser").format(advertiserId),
      params,
    );
  }

  /**
   * store t1 strategy
   * @param {object} params
   * @return {Promise<>}
   */
  async save(params) {
    return await this.api_server.put(StrategiesService.URI_STATES.get("strategies.get").format(params.t1_id), params);
  }

  /**
   * Get t1 supplies
   * @param {number} strategyId
   * @return {Promise<>}
   */
  async getSupplies(strategyId) {
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategy.supplies").format(strategyId));
  }

  /**
   * Update existing t1 supplies
   * @param {number} strategyId
   * @param {object} params
   * @return {Promise<>}
   */
  async updateSupplies(strategyId, params) {
    return await this.api_server.put(StrategiesService.URI_STATES.get("strategy.supplies").format(strategyId), params);
  }

  /**
   * Update existing t1 supplies
   * @param {number} strategyId
   * @param {object} params
   * @return {Promise<>}
   */
  async createSupplies(strategyId, params) {
    return await this.updateSupplies(strategyId, params);
  }

  /**
   * Get existing t1 creatives
   * @param {number} strategyId
   * @return {Promise<>}
   */
  async getCreatives(strategyId) {
    return await this.api_server.get(StrategiesService.URI_STATES.get("strategy.creatives").format(strategyId));
  }

  /**
   * Get existing t1 creatives
   * @param {number} strategyId
   * @param {object} params
   * @return {Promise<>}
   */
  async updateCreatives(strategyId, params) {
    return await this.api_server.put(StrategiesService.URI_STATES.get("strategy.creatives").format(strategyId), params);
  }

  /**
   * Create draft creatives
   * @param {number} strategyId
   * @param {object} params
   * @return {Promise<>}
   */
  async createCreatives(strategyId, params) {
    return await this.updateCreatives(strategyId, params);
  }

  async duplicate(id) {
    return await this.api_server.post(StrategiesService.URI_STATES.get("strategies.duplicate"), {
      type: "strategy",
      entity_id: id,
    });
  }

  /**
   * Bulk edits strategies
   * @param {object} params
   * @return {Promise<>}
   */
  async bulkEditStrategies(params) {
    return await this.api_server.post(StrategiesService.URI_STATES.get("strategies.bulk_edit_strategies"), params);
  }
}
