import AudienceCreatePage from "../components/audience/create";
import AudienceEditPage from "../components/audience/edit";
import { AudiencesPage } from "../components/audience/index";
import CampaignCreatePage from "../components/campaign/create";
import CampaignEditPage from "../components/campaign/edit";
import CampaignsPage from "../components/campaign/index";
import InventoryPage from "../components/inventory";
import DealsCreatePage from "../components/inventory/deals/DealsCreatePage";
import DealsEditPage from "../components/inventory/deals/DealsEditPage";
import SiteListCreatePage from "../components/inventory/site-list/create";
import StrategyCreatePage from "../components/strategy/create";
import StrategyEditPage from "../components/strategy/edit";
import { StrategiesPage } from "../components/strategy/index";

export const Routes = [
  {
    component: CampaignsPage,
    path: "/campaigns",
    title: ["Campaigns"],
  },
  {
    component: CampaignCreatePage,
    path: "/campaign/create",
    title: ["Campaigns", "Create"],
  },
  {
    component: CampaignEditPage,
    path: "/campaign/edit/:id",
    title: ["Campaigns", "Edit"],
  },
  {
    component: StrategiesPage,
    path: "/campaign/:campaign_id/strategies",
    title: ["Strategies"],
  },
  {
    component: StrategyCreatePage,
    path: "/campaign/:campaign_id/strategies/create",
    step: "0",
    title: ["Strategies", "Create", "Marketing Objectives"],
  },
  {
    component: StrategyCreatePage,
    path: "/campaign/:campaign_id/strategies/create/:strategy_id",
    step: "0",
    title: ["Strategies", "Create", "Marketing Objectives"],
  },
  {
    component: StrategyCreatePage,
    path: "/campaign/:campaign_id/strategies/create/:strategy_id/supplies",
    step: "1",
    title: ["Strategies", "Create", "Supplies"],
  },
  {
    component: StrategyCreatePage,
    path: "/campaign/:campaign_id/strategies/create/:strategy_id/creatives",
    step: "2",
    title: ["Strategies", "Create", "Creatives"],
  },
  {
    component: StrategyCreatePage,
    path: "/campaign/:campaign_id/strategies/create/:strategy_id/audiences",
    step: "3",
    title: ["Strategies", "Create", "Audiences"],
  },
  {
    component: StrategyCreatePage,
    path: "/campaign/:campaign_id/strategies/create/:strategy_id/locations",
    step: "4",
    title: ["Strategies", "Create", "Locations"],
  },
  {
    component: StrategyCreatePage,
    path: "/campaign/:campaign_id/strategies/create/:strategy_id/dayparts",
    step: "5",
    title: ["Strategies", "Create", "Daypart"],
  },
  {
    component: StrategyCreatePage,
    path: "/campaign/:campaign_id/strategies/create/:strategy_id/technology",
    step: "6",
    title: ["Strategies", "Create", "Technology"],
  },
  {
    component: StrategyEditPage,
    path: "/campaign/:campaign_id/strategies/edit/:strategy_id",
    step: "0",
    title: ["Strategies", "Edit", "Marketing Objectives"],
  },
  {
    component: StrategyEditPage,
    path: "/campaign/:campaign_id/strategies/edit/:strategy_id/supplies",
    step: "1",
    title: ["Strategies", "Edit", "Supplies"],
  },
  {
    component: StrategyEditPage,
    path: "/campaign/:campaign_id/strategies/edit/:strategy_id/creatives",
    step: "2",
    title: ["Strategies", "Edit", "Creatives"],
  },
  {
    component: StrategyEditPage,
    path: "/campaign/:campaign_id/strategies/edit/:strategy_id/audiences",
    step: "3",
    title: ["Strategies", "Edit", "Audiences"],
  },
  {
    component: StrategyEditPage,
    path: "/campaign/:campaign_id/strategies/edit/:strategy_id/locations",
    step: "4",
    title: ["Strategies", "Edit", "Locations"],
  },
  {
    component: StrategyEditPage,
    path: "/campaign/:campaign_id/strategies/edit/:strategy_id/dayparts",
    step: "5",
    title: ["Strategies", "Edit", "Daypart"],
  },
  {
    component: StrategyEditPage,
    path: "/campaign/:campaign_id/strategies/edit/:strategy_id/technology",
    step: "6",
    title: ["Strategies", "Edit", "Technology"],
  },
  {
    component: AudiencesPage,
    path: "/deviceid",
    title: ["Audiences"],
  },
  {
    component: AudienceCreatePage,
    path: "/deviceid/create",
    title: ["Audiences", "Create"],
  },
  {
    component: AudienceEditPage,
    path: "/deviceid/edit/:audience_id",
    title: ["Audiences", "Edit"],
  },
  {
    component: InventoryPage,
    exact: true,
    path: "/inventory",
    title: ["Inventory"],
  },
  {
    component: InventoryPage,
    path: "/inventory/deals",
    title: ["Deals", "Inventory"],
  },
  {
    component: DealsCreatePage,
    path: "/inventory/deals/create",
    title: ["Deals", "Create", "Inventory"],
  },
  {
    component: DealsEditPage,
    path: "/inventory/deals/edit/:deal_id",
    title: ["Deals", "Edit", "Inventory"],
  },
  {
    component: InventoryPage,
    path: "/inventory/site-list",
    title: ["Site Lists", "Inventory"],
  },
  {
    component: SiteListCreatePage,
    path: "/inventory/site-list/create",
    title: ["Site List", "Create"],
  },
];
