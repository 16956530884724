import PropTypes from "prop-types";
import { useState } from "react";
import { useIntl } from "react-intl";

export const FileInputComponent = ({ accept = null, placeholder = "", required = false, name = "" }) => {
  const intl = useIntl();
  const [fileName, setFileName] = useState(null);

  /**
   * handle onChange event
   * @param e
   */
  const onChange = (e) => {
    let fileName = e.target?.files[0]?.name;
    if (fileName && fileName.length > 40) {
      const chunks = [fileName.substring(0, 15), "...", fileName.substring(fileName.length - 15)];
      fileName = chunks.join("");
    }
    setFileName(fileName);
  };

  return (
    // When used in a Form.Field, Semantic's CSS would apply to our label and break its
    // appearance. We prevent it by wrapping everything in another element:
    <div style={{ display: "inline" }}>
      <label className="ui action input">
        <div className="ui-custom-file-div">{fileName || placeholder}</div>
        <span className="ui button primary">
          {intl.formatMessage({
            id: "BTN_BROWSE",
            defaultMessage: "Browse",
          })}
        </span>
        <input
          type="file"
          accept={accept}
          onChange={onChange}
          required={required}
          name={name}
          style={{ display: "none" }}
        />
      </label>
    </div>
  );
};

FileInputComponent.propTypes = {
  placeholder: PropTypes.string,
};
