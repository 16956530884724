import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Divider, Header, Message, Segment } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";

import AudiencesService from "../../../services/audiences";
import AudienceForm from "../form";

/**
 * Audience create page component
 * @param history
 * @return {*}
 * @constructor
 */
const AudienceCreatePage = ({ history }) => {
  const backUrl = "/audiences";

  const intl = useIntl();
  const service = new AudiencesService();
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  const _isMounted = React.useRef(true);

  /**
   * navigate user back
   */
  const handleCancel = useCallback(() => {
    history.push(backUrl);
  }, [history]);

  /**
   * form submit handler
   * @param {object} values
   * @param {File} file
   */
  const handleSubmit = async (values, file) => {
    setLoading(true);
    const form = new FormData();
    for (const k of Object.keys(values)) {
      form.append(k, values[k]);
    }
    form.set("status", values.status ? "1" : "0");
    form.append("segment_file", file);

    try {
      await service.create(form);
      history.push(backUrl, { action: "created" });
    } catch (e) {
      setServerError(e.error.message);
    } finally {
      if (_isMounted.current) {
        setLoading(false);
      }
    }
  };

  const initialData = {
    status: true,
    title: "",
    advertiser_id: null,
  };

  return (
    <Segment basic>
      <Header as="h2">
        {intl.formatMessage({
          id: "HEADING_UPLOAD_DEVICE_ID",
          defaultMessage: "Upload Device ID",
        })}
      </Header>
      <Divider />
      <Message
        style={{ marginTop: "10px" }}
        error
        hidden={!serverError}
        size="tiny"
        content={serverError}
      />
      <AudienceForm
        loading={loading}
        initialData={initialData}
        submitText={intl.formatMessage({
          id: "BTN_UPLOAD_DEVICE_ID",
          defaultMessage: "Upload Device ID",
        })}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Segment>
  );
};

export default AudienceCreatePage;
