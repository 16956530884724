import PropTypes from "prop-types";
import { Input, Ref } from "semantic-ui-react";

/**
 * Like `<Input type="number" />`, but will always show N decimal places.
 * Should only be used as uncontrolled component (i. e. use defaultValue, not value).
 */
const DecimalInput = ({ places, defaultValue, onChange, ...restProps }) => {
  return (
    <Ref
      innerRef={(input) => {
        // We need to manipulate DOM directly here because React seems to convert
        // value (but not defaultValue?) to float, losing trailing zeroes.

        if (input) {
          let workingElement = input;
          if (input.tagName.toLowerCase() !== "input") {
            workingElement = input.querySelector("input");
          }

          workingElement.addEventListener("change", (ev) => {
            ev.target.value = Number.parseFloat(ev.target.value).toFixed(places);
            if (onChange) {
              onChange(ev, ev.target);
            }
          });
        }
      }}
    >
      <Input
        type="number"
        defaultValue={typeof defaultValue === "number" ? defaultValue.toFixed(places) : defaultValue}
        {...restProps}
      />
    </Ref>
  );
};
DecimalInput.propTypes = {
  ...Input.propTypes,
  laces: PropTypes.number,
};

export default DecimalInput;
