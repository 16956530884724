import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { Divider, Header, Segment } from "semantic-ui-react";
import { parseAPIDateTimeToLocalTZDateObj } from "../../../libs/common_utils";
import { checkNever } from "./utils";
import "react-datepicker/dist/react-datepicker.css";
import DealsService from "../../../services/inventory/deals";
import DealForm from "./DealForm";
const backUrl = "/inventory/deals";
const service = new DealsService();

/**
 * Deals edit page component
 * @param {object} history
 * @param {object} match
 * @param {object} match.params
 * @param {number} match.params.deal_id
 * @return {*}
 * @constructor
 */
const DealsEditPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const { deal_id: dealIdParam = 0 } = useParams();
  const dealId = Number.parseInt(dealIdParam, 10);
  const [notFound, setNotFound] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [initialIndeterminateAgencies, setInitialIndeterminateAgencies] = useState([]);

  /**
   * form submit handler
   * @return {boolean}
   */
  const handleFormSubmit = useCallback(() => {
    history.push(backUrl, { action: "edited", type: "deals" });
  }, [history]);

  /**
   * navigate user back
   */
  const handleCancel = useCallback(() => {
    history.push(backUrl);
  }, [history]);

  /**
   * handle 404
   */
  const handle404 = () => {
    history.push(backUrl, { action: "error", type: "deals", msg: "No deal found" });
  };

  if (dealId < 1 || Number.isNaN(dealId) || notFound) {
    handle404();
  }

  useEffect(() => {
    if (!dealId) return;

    (async () => {
      try {
        const { data } = await service.get(dealId);
        const nextFormValues = {
          agencyIds: data.permissions.agency_ids ?? [],
          advertiserIds: data.permissions.advertiser_ids ?? [],
          channel: data.channels.map((channel) => channel.id),
          currency: data.currency,
          dealId: data.deal_id,
          dealName: data.title ?? "",
          dealDescription: data.description ?? "",
          dealPrice: Number(data.price),
          dealType: data.deal_type !== "PG" ? "PMP" : "PG",
          endDate: parseAPIDateTimeToLocalTZDateObj(data.end_date),
          exchange: data.supply_source_id,
          id: data.id,
          neverEnds: checkNever(parseAPIDateTimeToLocalTZDateObj(data.end_date)) === "NEVER",
          priceType: data.price_type,
          publisher: data.publisher_id ?? null,
          startDate: parseAPIDateTimeToLocalTZDateObj(data.start_date),
          status: Boolean(data.status),
          permissions: data.permissions,
        };
        setInitialIndeterminateAgencies(data.permissions.agency_ids ?? []);
        setInitialData(nextFormValues);
      } catch {
        setNotFound(true);
      }
    })();
  }, [dealId]);

  if (initialData === null) {
    return (
      <Segment
        className="loading"
        disabled
        tertiary
        textAlign="center"
      >
        &nbsp;
      </Segment>
    );
  }

  return (
    <Segment basic>
      <Header as="h2">
        {intl.formatMessage({
          id: "HEADING_EDIT_DEAL",
          defaultMessage: "Edit Deal",
        })}
      </Header>
      <Divider />
      <DealForm
        formType="edit"
        initialData={initialData}
        initialIndeterminateAgencies={initialIndeterminateAgencies}
        onCancel={handleCancel}
        onSubmit={handleFormSubmit}
        submitButtonLabel={intl.formatMessage({
          id: "HEADING_EDIT_DEAL",
          defaultMessage: "Save Deal",
        })}
        submitText={intl.formatMessage({
          id: "BTN_SAVE_CHANGES",
          defaultMessage: "Save Changes",
        })}
      />
    </Segment>
  );
};

export default DealsEditPage;
