import AbstractService from "../abstract.service";

export default class PixelsService extends AbstractService {
  static URI_STATES = new Map([
    ["pixels.list_by_agency", "/pixels/{0}"],
    ["pixels.get", "/pixel/{0}"],
    ["pixels.put", "/pixel/{0}"],
    ["pixels.post", "/pixel"],
    ["pixels.post_roi", "/pixel-roi"],
    ["pixels.put_roi", "/pixel-roi/{0}"],
    ["pixels.get_roi", "/pixel-roi/{0}"],
    ["pixels.brands", "/brands"],
  ]);

  /**
   * get brands by name
   * @param {string} query
   * @returns {Promise<object>}
   */
  async getBrands(query) {
    return await this.api_server.get(PixelsService.URI_STATES.get("pixels.brands"), { brand_name: query });
  }

  /**
   * Get active pixels by agency
   * @param {number} agency
   * @param {object} params
   * @param {number} params.advertiser_id
   * @param {string} params.query
   * @return {Promise<object>}
   */
  async searchActiveListByAgency(agency, params) {
    return await this.api_server.get(
      PixelsService.URI_STATES.get("pixels.list_by_agency").format(agency),
      Object.assign({ pixel_status: "active" }, params),
    );
  }

  /**
   * Get available pixels
   * @param {object} params
   */
  async listByAgency(agency, params) {
    return await this.api_server.get(PixelsService.URI_STATES.get("pixels.list_by_agency").format(agency), params);
  }

  /**
   * Get active pixels only
   * @param {number} agency
   * @param {number} advertiser
   * @returns {Promise<{}>}
   */
  async activeByAdvertiser(agency, advertiser) {
    return await this.api_server.get(PixelsService.URI_STATES.get("pixels.list_by_agency").format(agency), {
      advertiser_id: advertiser,
      fetch_all: 1,
      pixel_status: "active",
    });
  }

  /**
   * post pixel
   * @param {object} params
   * @return {Promise<>}
   */
  async create(params) {
    return await this.api_server.post(PixelsService.URI_STATES.get("pixels.post"), params);
  }

  /**
   * get ROI pixel
   * @param {number} pixel_id
   * @return {Promise<>}
   */
  async getROI(pixelId) {
    return await this.api_server.get(PixelsService.URI_STATES.get("pixels.get_roi").format(pixelId));
  }

  /**
   * post ROI pixel
   * @param {object} params
   * @return {Promise<>}
   */
  async createROI(params) {
    return await this.api_server.post(PixelsService.URI_STATES.get("pixels.post_roi"), params);
  }

  /**
   * put pixel
   * @param {number} id
   * @param {object} params
   * @return {Promise<>}
   */
  async update(id, params) {
    return await this.api_server.put(PixelsService.URI_STATES.get("pixels.put").format(id), params);
  }

  /**
   * put pixel
   * @param {number} id
   * @param {object} params
   * @return {Promise<>}
   */
  async updateROI(id, params) {
    return await this.api_server.put(PixelsService.URI_STATES.get("pixels.put_roi").format(id), params);
  }

  /**
   * Get pixel by id
   * @param {number} id
   */
  async get(id) {
    return await this.api_server.get(PixelsService.URI_STATES.get("pixels.get").format(id), {});
  }
}
