import AbstractService from "../abstract.service";
export default class UsersService extends AbstractService {
  static URI_STATES = new Map([
    ["users.list", "/users"],
    ["users.get", "/user/{0}"],
    ["users.put", "/user/{0}"],
    ["users.post", "/user"],
    ["users.delete", "/user/{0}"],
  ]);

  /**
   * List available users.
   * @param {number} agency
   * @param {object} params
   * @return {Promise<>}
   */
  async list(params) {
    return await this.api_server.get(UsersService.URI_STATES.get("users.list"), params);
  }

  /**
   * Create a user.
   * @param {object} params
   * @return {Promise<>}
   */
  async create(params) {
    return await this.api_server.post(UsersService.URI_STATES.get("users.post"), params);
  }

  /**
   * Get user by id.
   * @param {number} id
   */
  async get(id) {
    return await this.api_server.get(UsersService.URI_STATES.get("users.get").format(id));
  }

  /**
   * Update a user.
   * @param {number} id
   * @param {object} params
   * @return {Promise<>}
   */
  async update(id, params) {
    return await this.api_server.put(UsersService.URI_STATES.get("users.put").format(id), params);
  }
}
