import AdminCreativeApprovalEditPage from "../../components/creative/edit/creative_approval";
import { AdminCreativesApprovalsPage } from "../../components/creative/index/creative_approval/index_admin";
import MarginCreatePage from "../../components/margin/create";
import MarginEditPage from "../../components/margin/edit";
import { MarginsPage } from "../../components/margin/index";
import UserCreatePage from "../../components/user/create";
import UserEditPage from "../../components/user/edit";
import { UsersPage } from "../../components/user/index";
import { Routes as creativeRoutes } from "../creatives";
import { Routes as commonRoutes } from "../index";
import { Routes as pixelRoutes } from "../pixels";
import { Routes as reportRoutes } from "../report";

export const Routes = (flags = {}) => {
  const routes = [
    {
      component: MarginsPage,
      path: "/admin/margins",
      title: ["Margin Controls"],
    },
    {
      component: MarginCreatePage,
      path: "/admin/margin/create",
      title: ["Margin Controls", "Create"],
    },
    {
      component: MarginEditPage,
      path: "/admin/margin/edit/:margin_id",
      title: ["Margin Controls", "Edit"],
    },
    {
      component: UsersPage,
      path: "/admin/users",
      title: ["Users"],
    },
    {
      component: UserCreatePage,
      path: "/admin/user/create",
      title: ["Users", "Create"],
    },
    {
      component: UserEditPage,
      path: "/admin/user/edit/:user_id",
      title: ["Users", "Edit"],
    },
    {
      component: AdminCreativesApprovalsPage,
      path: "/admin/onsite-creatives",
      title: ["On-Site Creatives"],
    },
    {
      component: AdminCreativeApprovalEditPage,
      path: "/admin/onsite-creatives/edit/:creative_id",
      title: ["On-Site Creatives", "Edit"],
    },
  ];

  return routes.concat(creativeRoutes(flags), pixelRoutes(flags), reportRoutes, commonRoutes);
};
